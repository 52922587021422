import React, { useImperativeHandle } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import CompletedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import TransferIcon from "@material-ui/icons/LayersOutlined";
import BackIcon from "@material-ui/icons/ArrowBackOutlined";
import PauseIcon from "@material-ui/icons/PauseCircleOutlineOutlined";
import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoing";
import CheckList from "./CheckList";
import TextareaAutosize from "react-textarea-autosize";

import { connect } from "react-redux";

import {
  parkToken,
  handleReturn,
  handleTransferDisplay,
  addMissed,
  serviceDone,
  onSelectCounter,
} from "../../axiosLib/tokenCounterActions";

import "../../assets/css/StartServing.css";
import "../../assets/css/AutosizeText.css";
import { Button } from "@material-ui/core";

import socketIOClient from "socket.io-client";
import { serveruri } from "../../axiosLib/config";

let socket = socketIOClient(serveruri);

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#38DAF8",
    borderRadius: 0,
    marginTop: 16,
  },
  root: {
    textAlign: "start",
    display: "flex",
    flexDirection: "row",
    padding: 12,
    "&:last-child": {
      paddingBottom: 16,
    },
  },
  name: {
    fontSize: "1rem",
    fontFamily: "Poppins",
    color: "#000000",
  },
  title: {
    fontSize: 13,
    fontFamily: "Poppins",
  },
  pos: {
    marginBottom: 5,
    fontSize: 13,
    fontFamily: "Poppins",
    color: "#000000",
  },
  pos2: {
    marginBottom: 5,
    fontSize: 13,
    fontFamily: "Poppins",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "92%",
    borderTop: "0.5px solid #EAE5E5",
  },
  cssLabels: {
    color: "#EAE5E5",
  },
  underline: {
    "&:after": {
      borderBottom: "2px solid #62BC74",
    },
  },
}));

let CustomCard = (props) => {
  const classes = useStyles();
  let { current } = props;
  return current ? (
    <Card className={classes.card}>
      <CardContent className={classes.root}>
        <div style={{ width: "90%" }}>
          <div>
            <Typography className={classes.name}>{current.qId}</Typography>
            <Typography className={classes.pos}>{current.name}</Typography>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {current.service}
            </Typography>
          </div>
        </div>
        {/* <div style={{ marginTop: '1.5%' }}>
          <Typography className={classes.pos2} color='textSecondary'>
            01:39
          </Typography>
        </div> */}
      </CardContent>
    </Card>
  ) : null;
};

let userToken = sessionStorage.getItem("userToken");

const NowServing = (props) => {
  const classes = useStyles();
  let {
    parkToken,
    handleReturn,
    handleTransferDisplay,
    addMissed,
    serviceDone,
    tokenCounter,
    onSelectCounter,
  } = props;

  let activeList = tokenCounter ? tokenCounter.activeList : null;
  let activeCard = activeList
    ? activeList.cards
      ? activeList.cards[0]
      : null
    : null;
  let callTime = tokenCounter ? tokenCounter.callTime : new Date();
  let checkLists = tokenCounter
    ? tokenCounter.checkLists
      ? tokenCounter.checkLists
      : []
    : [];
  let activeCounter = tokenCounter
    ? tokenCounter.activeCounter
      ? tokenCounter.activeCounter
      : ""
    : "";
  let allData = tokenCounter
    ? tokenCounter.allData
      ? tokenCounter.allData
      : null
    : null;
  let checkPriority = activeCard ? activeCard.checkPriority : false;

  let [note, setNote] = React.useState(activeCard ? activeCard.note : "");
  let [more, setMore] = React.useState([]);
  let [disable, setDisable] = React.useState(checkPriority);

  let handleChange = (event) => {
    setNote(event.target.value);
  };

  let onReturn = () => {
    handleReturn();
    if (activeCounter) {
      if (activeCounter.length) {
        if (allData) {
          onSelectCounter(activeCounter, allData);
        }
      }
    }
  };

  let handleCheckList = (obj) => {
    let cnt = obj.findIndex((item) => item.status == true);

    if (cnt > -1) setDisable(false);
    else setDisable(true);
    setMore(obj);
  };

  let sendPause = () => {
    if (activeCard) {
      let data = {
        tokenId: activeCard.id,
        callTime: callTime,
        note: note,
      };
      parkToken(data)
        .then((res) => {
          socket.emit("parkedToken", userToken);
        })
        .catch((err) => {
          console.log("Error from parktoken", err);
        });
    }
  };

  let sendMissed = () => {
    console.log("missed token clicked", activeCard);
    if (activeCard) {
      let data = {
        tokenId: activeCard.id,
        callTime: callTime,
        note: note,
      };
      addMissed(data)
        .then((res) => {
          socket.emit("reQueue", userToken);
        })
        .catch((err) => {
          console.log("Error from service done", err);
        });
    }
  };

  let onSubmit = () => {
    let check = more.filter((item) => item.status == true);
    if (activeCard) {
      let data = {
        tokenId: activeCard.id,
        callTime: callTime,
        checkList: check,
      };
      serviceDone(data)
        .then((res) => {
          socket.emit("doneList", userToken);
        })
        .catch((err) => {
          console.log("Error from service done", err);
        });
    }
  };

  return (
    <Card
      className="start-serving"
      style={{ height: window.innerHeight - 118 }}
    >
      <div>
        <header style={{ backgroundColor: "#EAE5E5", height: 68 }}>
          <div className="back-button" onClick={handleReturn}>
            <BackIcon />
          </div>
          <Typography
            style={{
              paddingTop: 20,
              textAlign: "left",
              color: "#000000",
              fontFamily: "Poppins",
              fontSize: 18,
            }}
          >
            Now Serving
          </Typography>
        </header>
        <div>
          <div
            className="now-container"
            style={{ height: window.innerHeight - 252 }}
          >
            <CustomCard current={activeCard} />
            <div className="sidebarDescriptionWrapper">
              <TextareaAutosize
                placeholder="Note"
                onChange={handleChange}
                value={note}
                minRows={4}
                style={{
                  backgroundColor: "#fff",
                  width: "92%",
                  fontFamily: "Poppins",
                  fontSize: 14,
                  color: "#585858",
                  marginLeft: 16,
                  marginRight: 16,
                }}
              />
            </div>
            <div
              className="sidebarSubtaskWrapper"
              style={{ margin: "0 1rem", overflow: "auto" }}
            >
              <CheckList onCheck={handleCheckList} list={checkLists} />
            </div>
            <div className="pause-option editHoverBGColor" onClick={sendPause}>
              <Button>
                <PauseIcon style={{ color: "#585858" }} />
              </Button>
              <Typography
                style={{
                  marginTop: 5,
                  fontFamily: "Poppins",
                  fontSize: 14,
                }}
              >
                Pause service
              </Typography>
            </div>
            <div className="pause-option editHoverBGColor" onClick={sendMissed}>
              <Button>
                <CallMissedOutgoingIcon style={{ color: "#585858" }} />
              </Button>
              <Typography
                style={{
                  marginTop: 5,
                  fontFamily: "Poppins",
                  fontSize: 14,
                }}
              >
                Missed Queue
              </Typography>
            </div>
          </div>
          <div className="action-btn">
            <div
              className="button"
              style={{
                backgroundColor: "#EAE5E5",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
              onClick={handleTransferDisplay}
            >
              <TransferIcon />
              <p style={{ marginTop: "2.3px", marginLeft: "4px" }}>TRANSFER</p>
            </div>
            <div
              className="button"
              style={{
                backgroundColor: "#38F8C4",
                textAlign: "center",
                fontFamily: "Poppins",
              }}
              onClick={
                !disable
                  ? () => onSubmit()
                  : () => alert("Minimum one checkbox must be checked")
              }
            >
              <CompletedIcon />
              <p style={{ marginTop: "2.3px", marginLeft: "4px" }}>COMPLETED</p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null,
  };
}

export default connect(mapStateToProps, {
  parkToken,
  handleReturn,
  handleTransferDisplay,
  addMissed,
  serviceDone,
  onSelectCounter,
})(NowServing);
