import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Kiosk from "./Kiosk";
import OldToken from "./OldToken";
import CheckBarcode from "./CheckBarcode";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import Shadow from "../../assets/image/leadership.png";
import logoShadow from "../../assets/image/demo-logo.png";

import "../../assets/css/token.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    height: "100vh",
  },
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="body">
        <div className="top-text">
          {/* <img className="avatar" src={logoShadow} /> */}
          {/* <Link
            to='/signup'
            style={{ textDecoration: 'none' }}
          >
            <div className='sign-text pointer'>Sign up <ArrowRightAltIcon /> </div>
          </Link> */}
        </div>
        <Card className="cardview">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Create A Token" {...a11yProps(0)} />
            <Tab label="Scan Token" {...a11yProps(1)} />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Kiosk />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <CheckBarcode />
            </TabPanel>
          </SwipeableViews>
        </Card>
        <div style={{ marginBottom: 5 }} />
        <img
          src={Shadow}
          alt="Welcome to QQ App"
          variant="square"
          className="images"
        />
      </div>
    </Container>
  );
}
