import React from 'react'
import './App.css'
import MainTab from './Token/MainTabScreen'
import RegDone from './Token/RegDone'

import { connect } from 'react-redux'

const MainBoard = props => {
  let { token } = props
  let showReg = token ? token.showReg ? token.showReg : null : null
  let showEntry = token ? token.showEntry ? token.showEntry : null : null
  return (
    <div>
      {showReg == true ? <RegDone /> : null}
      {showEntry == true ? (
        <div>
          <MainTab />
        </div>
      ) : null}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    token: state.token ? state.token : null
  }
}

export default connect(
  mapStateToProps
)(MainBoard)
