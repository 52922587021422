import React, { useEffect } from 'react'
import './App.css'
import Router from '../Router/index'

const App = props => {

  return (
    <Router />
  )
}

export default App
