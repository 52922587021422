import {
  SET_ALL_SERVICES,
  ADD_NEW_SERVICE,
  SET_SERVICE_ERROR,
  UPDATE_SERVICE
} from '../constants'

let initialState = {}

let serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_SERVICE:
      return {
        ...state,
        newEntry: action.data
      }
    case UPDATE_SERVICE:
      return {
        ...state,
        updated: action.data
      }
    case SET_ALL_SERVICES:
      return {
        ...state,
        services: action.data
      }
    case SET_SERVICE_ERROR:
      return {
        ...state,
        error: action.data
      }
    default:
      return state
  }
}

export default serviceReducer