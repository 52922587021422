import React from 'react'
import './App.css'
import MainBoard from './MainBoard'

const TokenMain = props => {
  return (
    <div className='App'>
      <MainBoard />
    </div>
  )
}

export default TokenMain
