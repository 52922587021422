import React from 'react'
import ServicePicker from './ServicePicker'
import DatePicker from './DatePicker'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import { Link, withRouter } from 'react-router-dom'

import { connect } from 'react-redux'

import {
  createToken,
  clearData,
  addServiceTime,
  sendSMS
} from '../../axiosLib/tokenActions'

import '../../assets/css/Kiosk.css'
import '../../assets/css/token.css'
import { Typography } from '@material-ui/core'

import socketIOClient from 'socket.io-client'
import { serveruri, branchId } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

let getFinal = (date, time) => {
  if (time[0] !== '0') {
    return date + "T" + time + ":00+06:00"
  } else {
    let newTime = time.split(':')
    let t = parseInt(newTime[0])
    let n = t + 12
    let i = String(n) + ":" + newTime[1]

    return date + "T" + i + ":00+06:00"
  }
}

const EntryBoard = props => {
  let {
    createToken,
    phoneKey,
    clearData,
    resetPhone,
    token,
    services,
    counter,
    addServiceTime,
    remoteBranch,
    remoteService,
    sendSMS
  } = props

  let user = token ? token.user ? token.user : {} : {}
  let serviceList = services ? services.services ? services.services : [] : []
  let counterList = counter ? counter.counterList ? counter.counterList : [] : []
  let pushName = user.name ? user.name : ''

  let [name, setName] = React.useState(pushName)
  let pushUserName = user.userName ? user.userName : ''
  let [userName, setUserName] = React.useState(pushUserName)
  let [service, setService] = React.useState(remoteService ? remoteService : '')
  let [date, setDate] = React.useState(new Date())
  let [time, setTime] = React.useState('')
  let [flag, setFlag] = React.useState(false)
  let [msg, setMsg] = React.useState(false)

  let path = props.match.path

  let handleName = event => {
    setName(event.target.value)
  }
  let handleUserName = event => {
    setUserName(event.target.value)
  }
  let handleService = value => {
    setService(value)
    setMsg('')
  }
  let handleDate = value => {
    setDate(value)
  }
  let handleTime = value => {
    setTime(value)
  }
  let handleFlag = (value) => {
    setFlag(value)
  }

  let serviceDetails = serviceList.length ?
    serviceList.find(service => service._id === service) : null
  let serviceName = serviceDetails ? serviceDetails.serviceName : ''

  let clear = () => {
    resetPhone('')
    clearData()
  }
  let onSubmit = () => {
    if (service.length) {
      let planeDate = moment(date).format()
      let formattedDate = planeDate.slice(0, 10)
      let planeTime = time.slice(0, 5)

      let final = flag ? getFinal(formattedDate, planeTime) : new Date()

      let pushData = {
        name: name,
        phone: phoneKey,
        userName: userName,
        tokenService: service,
        appointedDate: final,
        tokenEmergency: false,
        prebook: flag,
        branchId: remoteBranch ? remoteBranch : branchId
      }
      createToken(pushData)
        .then(res => {
          socket.emit('reQueue', '')
          let sendData = {
            serviceId: service,
            dateString: formattedDate,
            serviceTime: planeTime,
            branchId: branchId
          }
          if (res && flag) {
            addServiceTime(sendData)
          }
          if (res) {
            let counterId = res.data.counterId
            let counter = counterList.length ?
              counterList.find(counter => counter._id === counterId) : null
            let counterName = counter ? counter.counterTitle : ''
            let date = moment(res.data.appointedDate).format('Do MMM YYYY')
            let smsData = {
              phone: '+88' + phoneKey,
              sms: `Your Token is ${res.data.token} assigned to ${counterName}. Date: ${date}`
            }
            sendSMS(smsData)
          }
        })
        .catch(err => {
          console.log('Error', err)
        })
    } else {
      setMsg('Please select a service')
    }
  }
  return (
    <form className='container' noValidate autoComplete='off'>
      <div style={{ marginBottom: 5 }} />
      <TextField
        label='Phone'
        value={phoneKey}
        InputProps={{
          readOnly: true
        }}
        className='text_field'
        style={{ minWidth: 300 }}
        // onChange={() => handlePhone()}
        variant='outlined'
        margin='normal'
        required
        fullWidth
      />
      <ServicePicker
        remoteService={service}
        handleChange={handleService}
        branchId={branchId}
      />
      <TextField
        label='Name (optional)'
        value={name}
        // InputProps={
        //   pushName
        //     ? {
        //       readOnly: true
        //     }
        //     : {}
        // }
        className='text_field'
        style={{ minWidth: 300 }}
        onChange={handleName}
        variant='outlined'
        margin='normal'
        fullWidth
      />
      <TextField
        label='User Name (optional)'
        value={userName}
        InputProps={{
          readOnly: true
        }}
        className='text_field'
        style={{ minWidth: 300, marginTop: 8 }}
        onChange={handleUserName}
        variant='outlined'
        margin='normal'
        fullWidth
      />

      {service.length ? flag ?
        <>
          <DatePicker
            service={service}
            handleDate={handleDate}
            handleTime={handleTime}
          />
          <Button style={{ marginTop: 8 }} color='primary' onClick={() => handleFlag(false)}>
            Cancel
          </Button>
        </> :
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            style={{
              marginTop: 8,
              color: '#000000',
              fontSize: 14,
              fontWeight: 300,
              fontFamily: 'Poppins'
            }}>
            Do you want to prebook Date Time?{' '}
          </Typography>
          <div style={{ marginRight: 6 }} />
          <Button style={{ marginRight: 6 }} color='primary' onClick={() => handleFlag(true)}>
            Yes
          </Button>
        </div> : ''
      }
      {/* <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox checked={flag} onChange={handleFlag} value='flag' />
          }
          label='Emergency'
        />
      </FormGroup> */}
      <Typography
        style={{
          marginTop: 4,
          color: '#FF0000',
          fontSize: 14,
          fontWeight: 300,
          fontFamily: 'Poppins'
        }}>
        {msg}
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
        <Button
          fullWidth
          variant='contained'
          color='primary'
          value='Submit'
          style={{ marginTop: 18, color: '#ffffff' }}
          onClick={onSubmit}
        >
          Submit
        </Button>
        <div style={{ marginRight: 6 }} />
        <Link to={`${path}`} style={{ textDecoration: 'none' }}>
          <Button
            variant='outlined'
            color='primary'
            onClick={clear}
            style={{ marginTop: 18, marginLeft: 8 }}
          >
            Clear
          </Button>
        </Link>
      </div>
    </form>
  )
}

function mapStateToProps(state) {
  return {
    token: state.token ? state.token : null,
    counter: state.counter ? state.counter : null,
    services: state.service ? state.service : null
  }
}

export default withRouter(connect(
  mapStateToProps,
  {
    createToken,
    clearData,
    addServiceTime,
    sendSMS
  }
)(EntryBoard))
