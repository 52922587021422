import React from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert,
  FormInput,
  FormGroup,
  Button
} from 'shards-react'

import { connect } from 'react-redux'
import { addNewCounter, getAllCounters } from '../../axiosLib/counterActions'
import CounterTable from './CounterTable'
import BranchDropDown from '../Branch/BranchDropDown'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const CounterCreate = props => {
  let { counter, addNewCounter, getAllCounters } = props

  let [counterTitle, setName] = React.useState('')
  let [branchId, setBranch] = React.useState('')

  let handleCounterTitle = event => {
    setName(event.target.value)
  }
  let handleBranch = value => {
    setBranch(value)
  }

  let onSubmit = () => {
    let data = {
      counterTitle: counterTitle,
      branchId: branchId
    }
    addNewCounter(data)
      .then(res => {
        getAllCounters()
        socket.emit('notify', 'New counter added')
        setName('')
      })
  }
  return (
    <>
      <Row>
        <Col lg='8' className='mb-4'>
          <Card small className='mb=4'>
            <CardHeader className='border-bottom'>
              <h6 className='m-0'>Add New Counter</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className='p-3'>
                <Row>
                  <Col>
                    <Form>
                      <FormGroup>
                        <label>Counter Title</label>
                        <FormInput
                          value={counterTitle}
                          onChange={handleCounterTitle}
                          placeholder='Counter Title'
                        />
                      </FormGroup>
                      <BranchDropDown handleChange={handleBranch} />
                      <Button onClick={onSubmit}>Create</Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Row>
        <CounterTable />
      </Row>
    </>
  )
}

function mapStatetoProps(state) {
  return {
    counter: state.counter ? state.counter : null
  }
}

export default connect(
  mapStatetoProps,
  { addNewCounter, getAllCounters }
)(CounterCreate)
