import {
  GET_ALL_BRANCHES,
  SET_BRANCH_ERROR
} from '../constants'

import axios from 'axios'

import { serveruri } from './config'

export const getAllBranches = () => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      let allBranches = await axios.get(`${serveruri}/api/get-all-branch`)

      if (allBranches.status === 200) {
        response.status = 200
        response.data = allBranches.data
        dispatch(setAllBranch(allBranches.data))
        response.message = 'All Branches fetched'
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setBranchError(err.response.data.msg))
        return
      }
    }
  }
}

export const addNewBranch = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let addNew = await axios.post(`${serveruri}/api/add-new-branch`, inputData)

      if (addNew.status === 200) {
        let allBranches = await axios.get(`${serveruri}/api/get-all-branch`)

        if (allBranches.status === 200) {
          response.status = 200
          response.data = allBranches.data
          dispatch(setAllBranch(allBranches.data))
          response.message = 'All Branches fetched'
        }
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setBranchError(err.response.data.msg))
        return
      }
    }
  }
}

export const updateBranch = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let update = await axios.post(`${serveruri}/api/update-branch-list`, inputData)

      if (update.status === 200) {
        let allBranches = await axios.get(`${serveruri}/api/get-all-branch`)

        if (allBranches.status === 200) {
          response.status = 200
          response.data = allBranches.data
          dispatch(setAllBranch(allBranches.data))
          response.message = 'All Branches fetched'
        }
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setBranchError(err.response.data.msg))
        return
      }
    }
  }
}

export const setAllBranch = data => {
  return {
    type: GET_ALL_BRANCHES,
    data: data
  }
}

export const setBranchError = data => {
  return {
    type: SET_BRANCH_ERROR,
    data: data
  }
}