import React, { useState } from "react";
import { Col, Card, CardHeader, CardBody } from "shards-react";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import "../../../assets/css/ServiceList.css";

let getBranchName = (id, list) => {
  if (!list.length) {
    return "-";
  } else {
    let branch = list.find((item) => item._id === id);

    if (branch) {
      return branch.branchName;
    } else {
      return "-";
    }
  }
};

const UserTable = (props) => {
  let { userList } = props;

  return (
    <Col lg="5" className="mb-4">
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">User List</h6>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th
                  scope="col"
                  style={{ textAlign: "center" }}
                  className="border-0"
                >
                  User
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "center" }}
                  className="border-0"
                >
                  User Name
                </th>
                <th
                  scope="col"
                  style={{ textAlign: "center" }}
                  className="border-0"
                >
                  Phone
                </th>
              </tr>
            </thead>
            <tbody>
              {userList.map((item, key) => (
                <tr key={key}>
                  <td style={{ textAlign: "center" }}>{item.name}</td>
                  <td style={{ textAlign: "center" }}>{item.userName}</td>
                  <td style={{ textAlign: "center" }}>{item.phone}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserTable;
