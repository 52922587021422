import axios from 'axios'
import {
  SET_ALL_COUNTERS,
  SET_COUNTER_ERROR,
  ADD_NEW_COUNTER,
  UPDATE_COUNTER
} from '../constants'

import { serveruri } from './config'

// Counter All Axios link
export const getAllCounters = () => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let allCounters = await axios.get(`${serveruri}/api/all-counters`)

      if (allCounters.status === 200) {
        response.status = 200
        response.token = token
        response.data = allCounters.data
        dispatch(setAllCounters(allCounters.data))
        response.message = 'All Counters fetched'
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setCounterError(err.response.data.msg))
        return
      }
    }
  }
}

// Counter Add Axios link
export const addNewCounter = (counterData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let addCounter = await axios.post(`${serveruri}/api/add-new-counter`, counterData)

      if (addCounter.status === 200) {
        response.status = 200
        response.token = token
        response.data = addCounter.data
        dispatch(setNewCounter(addCounter.data))
        response.message = 'Counter added successfully'
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setCounterError(err.response.data.msg))
        return
      }
    }
  }
}

export const updateStatus = (updateData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let update = await axios.post(`${serveruri}/api/update-counter-status`, updateData)

      if (update.status === 200) {
        response.status = 200
        response.token = token
        response.data = update.data
        dispatch(setUpdatedCounter(update.data))
        response.message = 'Counter updated'
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setCounterError(err.response.data.msg))
        return
      }
    }
  }
}

export const setNewCounter = (newEntry) => {
  return {
    type: ADD_NEW_COUNTER,
    data: newEntry
  }
}

export const setAllCounters = (counterData) => {
  return {
    type: SET_ALL_COUNTERS,
    data: counterData
  }
}

export const setUpdatedCounter = (updated) => {
  return {
    type: UPDATE_COUNTER,
    data: updated
  }
}

export const setCounterError = (data) => {
  return {
    type: SET_COUNTER_ERROR,
    data: data
  }
}
