import React from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert,
  FormInput,
  FormGroup,
  Button
} from 'shards-react'

import { connect } from 'react-redux'
import { addCheckListToService, getAllServices } from '../../axiosLib/serviceActions'
import ServiceList from '../CounterService/ServiceList'

const ServiceCheckList = props => {
  let { addCheckListToService, getAllServices } = props
  let [service, setService] = React.useState('')
  let [checkbox, setCheckbox] = React.useState('')

  let handleCheckBox = event => {
    setCheckbox(event.target.value)
  }

  let handleService = value => {
    setService(value)
  }

  let onSubmit = () => {
    let inputData = {
      serviceId: service,
      title: checkbox
    }
    addCheckListToService(inputData)
      .then(res => {
        getAllServices()
        setCheckbox('')
      })
  }

  return (
    <Col lg='6' className='mb-4'>
      <Card small className='mb-4'>
        <CardHeader className='border-bottom'>
          <h6 className='m-0'>Add New Checkbox to Service</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className='p-3'>
            <Row>
              <Col>
                <Form>
                  <FormGroup>
                    <label>Service Name</label>
                    <ServiceList handleChange={handleService} />
                  </FormGroup>
                  <FormGroup>
                    <label>Check Box</label>
                    <FormInput
                      value={checkbox}
                      onChange={handleCheckBox}
                      placeholder='Add checkbox title...'
                    />
                  </FormGroup>
                  <Button onClick={onSubmit}>Add</Button>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Col>
  )
}

export default connect(
  null,
  { addCheckListToService, getAllServices }
)(ServiceCheckList)