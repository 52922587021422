import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { List, ListItem, Checkbox } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import '../../assets/css/AutosizeText.css'

const GreenCheckbox = withStyles({
  root: {
    color: '#A0A0A0',
    '&$checked': {
        color: '#38F8C4',
    },
  },
  checked: {}
})(props => <Checkbox color='default' {...props} />)

export default function CheckboxLabels(props) {
  let { onCheck, list } = props
  const [state, setState] = React.useState([])

  const handleChange = (id) => {
    const nextState = list.map(a => {
      if (a._id == id) a.status = !a.status
      return a
    })
    if (!state.length) {
      setState([...nextState])
      onCheck(nextState)
    } else {
      let actual = state.findIndex(item => item._id == id)
      let next = nextState.findIndex(item => item._id == id)
      state[actual] = nextState[next]
      setState(state)
      onCheck(state)
    }
  }

  return (
    <div className="subTaskWrapper">
      <List>
        {
          list.map((item, idx) =>{
            return(
              <ListItem key={`${item}-${idx}`}>
                <GreenCheckbox
                  key={idx}
                  className="subtaskCheckbox"
                  checked={state.checkedG}
                  onChange={() => handleChange(item._id)}
                  value='status'
                  style={{ backgroundColor: 'transparent' }}
                />
                {
                  state.checkedG ? <div  className="subtaskInputChecked" >{item.title}</div>  : <div  className="subtaskInput" >{item.title}</div> 
                }
                 
                 <DeleteOutlineIcon
                    className="subtaskRemove"
                  />
              </ListItem>
            )
          }
          )
        }
      </List>
    </div>
  )
}
