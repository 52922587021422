import React from "react";
import { connect } from "react-redux";
import SearchCounter from "./SearchCounters";
import CounterTable from "./CounterTable";
import moment from "moment";
import Chart from "./Chart";

const setData = (data) => {
  let servingTime = data.map((item) => {
    let endTime = moment.utc(item.departureTime).unix();
    let startTime = moment.utc(item.callTime).unix();
    return endTime - startTime;
  });

  let totalServingTime = data.length ? servingTime.reduce((a, b) => a + b) : 0;

  let avgServiceTime = data.length ? totalServingTime / data.length / 60 : 0;

  let waitingTime = data.map((item) => {
    let endTime = moment.utc(item.callTime).unix();
    let startTime = moment.utc(item.assignTime).unix();
    return endTime - startTime;
  });

  let totalWaitingTime = data.length ? waitingTime.reduce((a, b) => a + b) : 0;

  let avgWaitingTime = data.length ? totalWaitingTime / data.length / 60 : 0;

  return {
    length: data.length,
    serviceTime: avgServiceTime > 0 ? avgServiceTime.toFixed(3) : 0,
    waitingTime: avgWaitingTime > 0 ? avgWaitingTime.toFixed(3) : 0,
  };
};

const ServiceMain = (props) => {
  let { tokenCounter, counter, branch } = props;

  let branchList = branch ? (branch.allBranches ? branch.allBranches : []) : [];
  let counterDropDown = counter
    ? counter.counterList
      ? counter.counterList
      : []
    : [];
  let counterReport = tokenCounter
    ? tokenCounter.counterReport
      ? tokenCounter.counterReport
      : []
    : [];
  let reportStart = tokenCounter
    ? tokenCounter.reportStart
      ? tokenCounter.reportStart
      : new Date()
    : new Date();
  let reportEnd = tokenCounter
    ? tokenCounter.reportEnd
      ? tokenCounter.reportEnd
      : new Date()
    : new Date();

  let pushResult = counterReport.length
    ? counterDropDown.map((counter) => {
        let filteredList = counterReport.filter(
          (item) => item.counterId === counter._id
        );
        let getData = setData(filteredList);
        let title = counter.counterTitle;
        let branchDet = branchList.find(
          (branch) => branch._id === counter.branchId
        );
        let branchName = branchDet
          ? branchDet.branchName
            ? branchDet.branchName
            : ""
          : "";
        let res = {
          title: title,
          branchName: branchName,
          served: getData.length,
          serviceTime: getData.serviceTime,
          waitingTime: getData.waitingTime,
        };

        return res;
      })
    : [];

  let startDay = moment(reportStart);
  let endDay = moment(reportEnd);

  let dif = endDay.diff(startDay);
  let diff = moment.duration(dif);
  let days = diff.days();

  let chartData = [];

  for (let i = 0; i < days + 1; i++) {
    let cur = moment(startDay).add(i, "d");
    let current = moment(cur._d).format("YYYY-MM-DD");
    let dateFiltered = counterReport.filter((item) => {
      let time = moment(item.departureTime).format("YYYY-MM-DD");
      if (time === current) return item;
    });
    //console.log('current', current)
    //console.log('DateFiltered', dateFiltered)
    let data = counterDropDown.map((item) => {
      let branchDet = branchList.find((branch) => branch._id === item.branchId);
      //console.log('branchDet', branchDet)
      let branchName = branchDet
        ? branchDet.branchName
          ? branchDet.branchName
          : ""
        : "";
      let countArr = dateFiltered.filter((f) => f.counterId === item._id);
      return {
        counter: item.counterTitle,
        count: countArr.length,
        branchName: branchName,
      };
    });
    let retData = { name: current };
    let len = data.length;
    for (let j = 0; j < len; j++) {
      retData[`${data[j].counter}(${data[j].branchName})`] = data[j].count;
    }
    //console.log('ReData', retData)
    chartData.push(retData);
  }

  // console.log("Counter ChartData", chartData);

  return (
    <div>
      <SearchCounter />
      <div style={{ height: 20 }} />
      <Chart data={chartData} type="counter" />
      <CounterTable
        data={pushResult}
        reportStart={reportStart}
        reportEnd={reportEnd}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null,
    counter: state.counter ? state.counter : null,
    branch: state.branch ? state.branch : null,
  };
}

export default connect(mapStateToProps)(ServiceMain);
