import {
  SET_ALL_COUNTERS,
  SET_COUNTER_ERROR,
  ADD_NEW_COUNTER,
  UPDATE_COUNTER
} from '../constants'

let initialState = {}

let counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_COUNTERS:
      return {
        ...state,
        counterList: action.data
      }
    case ADD_NEW_COUNTER:
      return {
        ...state,
        newEntry: action.data
      }
    case SET_COUNTER_ERROR:
      return {
        ...state,
        error: action.data
      }
    case UPDATE_COUNTER:
      return {
        ...state,
        updated: action.data
      }

    default:
      return state
  }
}

export default counterReducer