import React, { useEffect } from 'react'
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormInput,
  FormGroup,
  FormCheckbox,
  Button
} from 'shards-react'

import { connect } from 'react-redux'
import { updateBranch, getAllBranches } from '../../axiosLib/branchActions'

import '../../assets/css/ServiceList.css'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const BranchList = props => {
  let { branch, updateBranch, getAllBranches } = props

  let allBranches = branch ? branch.allBranches ? branch.allBranches : [] : []

  let [branchList, setBranchList] = React.useState(allBranches)

  useEffect(() => {
    getAllBranches()
      .then(res => {
        if (res) {
          setBranchList(res.data)
        }
      })
    socket.on('addBranch', data => {
      getAllBranches()
        .then(res => {
          if (res) {
            setBranchList(data)
          }
        })
    })
  }, [])

  let handleName = (event, id) => {
    let nextState = branchList.map((a, idx) => {
      if (idx === id) a.branchName = event.target.value
      return a
    })
    setBranchList(nextState)
  }

  let handleAddress = (event, id) => {
    let nextState = branchList.map((a, idx) => {
      if (idx === id) a.address = event.target.value
      return a
    })
    setBranchList(nextState)
  }

  let handleLatt = (event, id) => {
    let nextState = branchList.map((a, idx) => {
      if (idx === id) a.lat = event.target.value
      return a
    })
    setBranchList(nextState)
  }

  let handleLng = (event, id) => {
    let nextState = branchList.map((a, idx) => {
      if (idx === id) a.lng = event.target.value
      return a
    })
    setBranchList(nextState)
  }

  let handleActive = (id) => {
    let nextState = branchList.map((a, idx) => {
      if (idx === id) a.status = !a.status
      return a
    })
    setBranchList(nextState)
  }

  let onSubmit = (item) => {
    let data = {
      branchId: item._id,
      branchName: item.branchName.length ? item.branchName : null,
      address: item.address.length ? item.address : null,
      lat: item.lat ? item.lat : null,
      lng: item.lng ? item.lng : null,
      status: item.status
    }
    updateBranch(data)
      .then(res => {
        if (res) {
          socket.emit('notify', 'Branch Data updated')
        }
      })
  }

  return (
    <Col lg='10' className='mb-4'>
      <Card small className='mb-4'>
        <CardHeader className='border-bottom'>
          <h6 className='m-0'>Branch List</h6>
        </CardHeader>
        <CardBody className='p-0 pb-3'>
          <table className='table mb-0'>
            <thead className='bg-light'>
              <tr>
                <th scope='col' className='border-0'>
                  Branch Name
                </th>
                <th scope='col' className='border-0'>
                  Address
                </th>
                <th scope='col' className='border-0'>
                  Lattitude
                </th>
                <th scope='col' className='border-0'>
                  Longitude
                </th>
                <th scope='col' className='border-0'>
                  Active
                </th>
                <th scope='col' className='border-0'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {branchList.map((item, key) => {
                return (
                  <tr key={key}>
                    <td >
                      <Form>
                        <FormGroup>
                          <FormInput
                            type='text'
                            value={item.branchName}
                            onChange={(e) => handleName(e, key)}
                            style={{ width: 90 }}
                          />
                        </FormGroup>
                      </Form>
                    </td>
                    <td >
                      <Form>
                        <FormGroup>
                          <FormInput
                            type='text'
                            value={item.address}
                            onChange={(e) => handleAddress(e, key)}
                            style={{ width: 200 }}
                          />
                        </FormGroup>
                      </Form>
                    </td>
                    <td >
                      <Form>
                        <FormGroup>
                          <FormInput
                            type='text'
                            value={item.lat}
                            onChange={(e) => handleLatt(e, key)}
                            style={{ width: 100 }}
                          />
                        </FormGroup>
                      </Form>
                    </td>
                    <td >
                      <Form>
                        <FormGroup>
                          <FormInput
                            type='text'
                            value={item.lng}
                            onChange={(e) => handleLng(e, key)}
                            style={{ width: 100 }}
                          />
                        </FormGroup>
                      </Form>
                    </td>
                    <td >
                      <FormCheckbox
                        onChange={() => handleActive(key)}
                        checked={item.status}
                        style={{ width: 15, height: 15, marginTop: 5 }}
                      />
                    </td>
                    <td >
                      <Button
                        style={{ height: 35 }}
                        onClick={() => onSubmit(item)}
                      >
                        Submit
                    </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Col>
  )
}

function mapStateToProps(state) {
  return {
    branch: state.branch ? state.branch : null
  }
}

export default connect(
  mapStateToProps,
  { updateBranch, getAllBranches }
)(BranchList)
