import React from 'react'
import CounterBoard from './CounterBoard'
import AdminPanel from './AdminBoard'

const CountersMain = props => {
  let { onLogout, user, activeTab, handleActiveTab, loading } = props
  return (
    <AdminPanel
      logOut={onLogout}
      user={user}
      activeTab={activeTab}
      handleActiveTab={handleActiveTab}
      loading={loading}
    />
  )
}

export default CountersMain
