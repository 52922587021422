import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'

import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const TransferDropDown = props => {
  const classes = useStyles()
  let { services, onChange } = props
  let [service, setService] = React.useState('')

  let serviceList = services ? services.services ? services.services : [] : []

  // console.log('counterList: ', counterList)

  const handleChange = event => {
    setService(event.target.value)
    onChange(event.target.value)
  }
  return (
    <div
      className='customSingleSelect'
    >
      <FormControl className={classes.formControl}>
        <Select
          labelid='demo-simple-select-label'
          id='demo-simple-select'
          value={service}
          onChange={handleChange}
          disableUnderline
        >
          {Array.isArray(serviceList) &&
            serviceList.map(item => (
              <MenuItem value={item._id}
               style={{ color: 'grey', fontSize: 14, fontWeight: 300, fontFamily: 'Poppins' }}
              >{item.serviceName}</MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    services: state.service ? state.service : null
  }
}

export default connect(
  mapStateToProps,
)(TransferDropDown)
