import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Card from '@material-ui/core/Card';
import Shadow from '../../assets/image/leadership.png'
import logoShadow from '../../assets/image/demo-logo.png'
import { connect } from 'react-redux'

import ServicePicker from '../Token/ServicePicker'
import BranchPicker from './BranchPicker'
import RemoteKiosk from './RemoteKiosk'

import '../../assets/css/token.css'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    height: '100vh'
  }
}))

function RemoteMain(props) {
  let {
    tokenCounter,
    serviceCounter,
    branch,
    token
  } = props

  const classes = useStyles()

  let allLanes = tokenCounter ?
    tokenCounter.counterList ?
      tokenCounter.counterList.lanes ? tokenCounter.counterList.lanes : []
      : []
    : []

  let serviceCounters = serviceCounter ?
    serviceCounter.serviceCounters ? serviceCounter.serviceCounters : []
    : []

  let branchList = branch ? branch.allBranches ? branch.allBranches : [] : []

  let [service, setService] = useState('')
  let [activeBranch, setActive] = useState('')
  let [searchBranches, setSearch] = useState([])

  let handleBranch = value => {
    setActive(value)
  }

  let handleService = value => {
    setService(value)

    let searchCounters = value.length ?
      serviceCounters.filter(item => item.serviceId === value) : []

    let counterMap = searchCounters.length ?
      searchCounters.map(item => {
        let send = {
          counterId: item.counterId,
          branchId: item.branchId
        }
        return send
      }) : []

    let allCards = []

    let len = counterMap.length

    for (let i = 0; i < len; i++) {
      let found = allLanes.find(lane => lane.id === counterMap[i].counterId)
      if (found) {
        let cardTime = found.cards.length ?
          found.cards.length === 1 ? found.cards[0].duration :
            found.cards.reduce((a, b) => a + b.duration, 0) : 0
        let pushData = {
          counterId: counterMap[i].counterId,
          branchId: counterMap[i].branchId,
          cardTime: cardTime
        }
        allCards.push(pushData)
      }
    }

    let sorted = allCards.length ? allCards.sort((a, b) => a.cardTime < b.cardTime ? -1 : 1) : []

    let distinct = [...new Set(sorted.map(x => x.branchId))]

    let searchBranches = []

    let disLen = distinct.length

    for (let i = 0; i < disLen; i++) {
      let found = branchList.find(branch => branch._id === distinct[i])
      if (found) {
        let data = sorted.find(s => s.branchId === distinct[i])
        let send = {
          branchId: distinct[i],
          branchName: found.branchName,
          time: data.cardTime
        }
        searchBranches.push(send)
      }
    }

    let activeBranch = searchBranches.length ? searchBranches[0].branchId : ''

    setSearch(searchBranches)
    setActive(activeBranch)
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className='body'>
        <div className='top-text'>
          <img className='avatar' alt='Logo' src={logoShadow} />
        </div>
        <Card className='cardview'>
          <RemoteKiosk
            remoteService={service}
            searchBranches={searchBranches}
            activeBranch={activeBranch}
            handleService={handleService}
            handleBranch={handleBranch}
          />
          {/* {
            !textFields ? (
              <>
                <ServicePicker handleChange={handleService} />
                {searchBranches.length ?
                  <>
                    <BranchPicker
                      branches={searchBranches}
                      handleChange={handleBranch}
                    />
                  </> :
                  null
                }
              </>
            ) : null
          } */}
        </Card>
        <img
          src={Shadow} alt="Welcome to QQ App"
          variant="square" className='images'
        />
      </div>
    </Container>
  )
}

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null,
    serviceCounter: state.serviceCounter ? state.serviceCounter : null,
    branch: state.branch ? state.branch : null,
    token: state.token ? state.token : null
  }
}

export default connect(mapStateToProps)(RemoteMain)