import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import { Row, Col } from 'shards-react'

import { connect } from 'react-redux'
import moment from 'moment'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#E0FFFF',
    color: 'black'
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}))

function TablePaginationActions(props) {
  const classes = useStyles1()
  const theme = useTheme()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
            <KeyboardArrowLeft />
          )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
            <KeyboardArrowRight />
          )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

const useStyles2 = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  rowRoot: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  table: {
    minWidth: 480,
    maxWidth: '100%'
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  cell: {
    textAlign: 'center',
    maxWidth: 200,
    fontFamily: 'Poppins'
  }
}))

const getData = (item, data) => {
  if (data) {
    let clientId = data.tokens ? data.tokens.find(token => token._id == item.tokenId).clientId : null
    let token = data.tokens ? data.tokens.find(token => token._id == item.tokenId).token : null
    let clientName = data.users ? data.users.find(user => user._id == clientId).name : null
    let clientPhone = data.users ? data.users.find(user => user._id == clientId).phone : null
    let counter = data.counters ? data.counters.find(counter => counter._id == item.counterId)
      .counterTitle : null
    let service = data.services ? data.services.find(ser => ser._id == item.serviceId).serviceName : null

    let res = {
      qId: token,
      userId: clientId,
      name: clientName,
      phone: clientPhone,
      service: service,
      counter: counter,
      servedAt: item.departureTime,
      callTime: item.callTime ? item.callTime : null,
      assignedAt: item.assignTime
    }

    return res
  }
}

const DoneList = props => {
  let { tokenCounter } = props

  let allData = tokenCounter ?
    tokenCounter.allData ? tokenCounter.allData : null : null

  let doneList = tokenCounter ?
    tokenCounter.doneList ? tokenCounter.doneList : [] : []

  let classes = useStyles2()
  let [page, setPage] = React.useState(1)
  let [phone, setPhone] = React.useState('')
  let [result, setResult] = React.useState([])
  let [rowsPerPage, setRowsPerPage] = React.useState(10)

  let [open, setOpen] = React.useState(0)

  let handlePhone = (event) => {
    setPhone(event.target.value)
  }

  let disTinct = [...new Set(doneList.map(x => x.tokenId))]

  // console.log('distinct', disTinct)

  let newDoneList = []

  let len = disTinct.length

  for (let i = 0; i < len; i++) {
    let check = disTinct[i]
    let history = doneList.filter(i => i.tokenId == check)
    let pushHistory = { first: getData(history[0], allData), history: history }
    newDoneList.push(pushHistory)
  }

  // console.log('newDoneList', newDoneList)

  let handleSubmit = (event) => {
    event.preventDefault()

    let found = newDoneList.length ?
      newDoneList.filter(done => done.first.phone.toString() === phone.toString()) : null
    setResult(found)
  }

  const handleClick = value => {
    if (value == open) {
      setOpen(0)
    } else setOpen(value)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  return (
    <Col style={{ backgroundColor: 'white' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          marginTop: 10
        }}
      >
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            id="outlined-basic"
            label="Search User By Phone"
            variant="outlined"
            value={phone}
            onChange={handlePhone}
          />
        </form>

      </div>
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-label='custom pagination table'>
            <TableHead>
              <TableRow>
                <StyledTableCell className={classes.cell}>
                  Queue ID
                </StyledTableCell>
                <StyledTableCell className={classes.cell}>Name</StyledTableCell>
                <StyledTableCell className={classes.cell}>Phone</StyledTableCell>
                <StyledTableCell className={classes.cell}>
                  Services Taken
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? result &&
                result.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                : result
              ).map((row, key) => {
                return (
                  <>
                    <TableRow
                      button
                      key={key}
                      onClick={() => handleClick(key + 1)}
                      className={classes.rowRoot}
                    >
                      <TableCell className={classes.cell}>
                        {row.first.qId}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.first.name}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.first.phone}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {row.history.length}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse in={open == key + 1} timeout='auto' unmountOnExit>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ textAlign: 'center', maxWidth: 100, fontFamily: 'Poppins' }}>
                                  <b>Service</b>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center', maxWidth: 100, fontFamily: 'Poppins' }}>
                                  <b>Counter</b>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center', maxWidth: 100, fontFamily: 'Poppins' }}>
                                  <b>Assigned At</b>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center', maxWidth: 100, fontFamily: 'Poppins' }}>
                                  <b>Called At</b>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center', maxWidth: 100, fontFamily: 'Poppins' }}>
                                  <b>Left At</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.history.map((item, key) => {
                                let his = getData(item, allData)
                                return (
                                  <TableRow key={key}>
                                    <TableCell style={{ textAlign: 'center', maxWidth: 100, fontFamily: 'Poppins' }}>
                                      {his.service}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', maxWidth: 100, fontFamily: 'Poppins' }}>
                                      {his.counter}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', maxWidth: 100, fontFamily: 'Poppins' }}>
                                      {moment(his.assignedAt).format('DD/MM/YYYY, hh:mm a')}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', maxWidth: 100, fontFamily: 'Poppins' }}>
                                      {his.callTime ? moment(his.callTime).format('DD/MM/YYYY, hh:mm a') : ''}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'center', maxWidth: 100, fontFamily: 'Poppins' }}>
                                      {moment(his.servedAt).format('DD/MM/YYYY, hh:mm a')}
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                )
              })}
            </TableBody>
          </Table>
          <div className='pages'>
          <Pagination
                 style={{height: 48, paddingTop: 8}}
                 count={Math.ceil(result.length / 10)}
                 page={page}
                 SelectProps={{
                   inputProps: { 'aria-label': 'rows per page' },
                   native: true
                 }}
                 onChange={handleChangePage}
               />
          </div>
        </div>
      </Paper>
    </Col>
  )
}

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null
  }
}

export default connect(
  mapStateToProps
)(DoneList)
