import React from 'react'
import CounterBoard from '../components/TrelloBoard/CounterBoard'

const Board = props => {
  return (
    <CounterBoard />
  )
}

export default Board
