import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import Barcode from 'react-barcode'
import ReactToPrint from 'react-to-print'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import Shadow from '../../assets/image/leadership.png'
import logoShadow from '../../assets/image/demo-logo.png'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { connect } from 'react-redux'
import '../../assets/css/token.css'

import { clearData } from '../../axiosLib/tokenActions'

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontSize: 18
  },
  pos: {
    marginBottom: 14
  },
  showArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: 700,
    marginTop: 170,
    marginLeft: '20%'
  }
})

class PrintContent extends React.Component {
  render() {
    let { newToken, reToken, counterName } = this.props
    return (
      <div>
        <Typography
          color='textSecondary'
          gutterBottom
        >
          Your Queue ID is:
        </Typography>
        <Typography variant='h5' component='h2'>
          {newToken.token ? newToken.token : reToken}
        </Typography>
        <Typography variant='h5' component='h2'>
          {counterName}
        </Typography>
        <Barcode value={newToken.token} displayValue={false} />
        <Typography variant='body2' component='p'>
          Date:{' '}
          {moment(newToken.appointedDate).format('hh:mm a, Do MMMM YYYY')}
        </Typography>
      </div>
    )
  }
}

const DonePage = props => {
  const componentRef = useRef()
  const classes = useStyles()
  let { token, clearData, counters } = props

  let reToken = ''
  let newToken = token ? token.newToken ? token.newToken : {} : {}
  let counter = newToken.counterId ? counters.find(item => item._id === newToken.counterId) : null
  let counterName = counter ? counter.counterTitle : ''

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className='body'>
        <div className='top-text'>
          <img className='avatar' src={logoShadow} />
          {/* <Link
            to='/signup'
            style={{ textDecoration: 'none' }}
          >
            <div className='sign-text pointer'>Sign up <ArrowRightAltIcon /> </div>
          </Link> */}
        </div>
        <Card className='cardview'>
          <CardContent style={{ justifyContent: 'center' }}>
            <PrintContent
              ref={componentRef}
              newToken={newToken}
              reToken={reToken}
              counterName={counterName}
            />
          </CardContent>
          <CardActions>
            <Button style={{ fontFamily: 'Poppins' }} variant='outlined' color='primary' onClick={clearData}>
              Back To Main
          </Button>
            <ReactToPrint
              trigger={() => <Button style={{ fontFamily: 'Poppins' }} variant='outlined' color='primary'>Print this out!</Button>}
              content={() => componentRef.current}
            />
          </CardActions>
        </Card>
        <div style={{ marginBottom: 5 }} />
        <img
          src={Shadow} alt="Welcome to QQ App"
          variant="square" className='images'
        />
      </div>
    </Container>
  )
}

function mapStateToProps(state) {
  return {
    token: state.token ? state.token : null,
    counters: state.counter ?
      state.counter.counterList ? state.counter.counterList : [] : []
  }
}

export default connect(
  mapStateToProps,
  {
    clearData
  }
)(DonePage)
