import axios from 'axios'
import {
  SET_USER_TOKEN,
  SET_NEW_TOKEN,
  SET_TOKEN_ERRORS,
  SHOW_REGISTRATION,
  SHOW_TEXTFIELDS,
  SHOW_ENTRY,
  DROP_TOKEN,
  GET_EXISTING_TOKEN,
  DELETE_FIELD,
  SET_MSG,
  SET_CLICKED,
  SET_REQUEUE,
  SET_REQUEUE_ID,
  SET_FEEDBACK_ERROR,
  SET_FEEDBACK_SUCCESS,
  GET_TIME_LIST,
  GET_SERVICE_TIME
} from '../constants'
import { serveruri } from './config'
import {
  setServing,
  setTransfer,
  setNowServing,
  setCardActive,
} from './tokenCounterActions'

import moment from 'moment'

function isEmpty(obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false
    }
  }
  return true
}

export const createToken = (inputData) => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {

      dispatch(setClicked(true))

      let addToken = await axios.post(`${serveruri}/api/create-token`, inputData)

      if (addToken.status === 200) {
        response.status = 200
        response.data = addToken.data
        dispatch(setNewToken(addToken.data))
        dispatch(showReg(true))
        dispatch(showEntry(false))
        dispatch(showTextFields(false))
        dispatch(setClicked(false))
        response.message = 'Token created'
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenError(err.response.data.msg))
        dispatch(showTextFields(false))
        dispatch(setClicked(false))
        return
      }
    }
  }
}

export const reAddToken = (inputData) => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      let reAdd = await axios.post(`${serveruri}/api/re-add-token`, inputData)

      if (reAdd.status === 200) {
        response.status = 200
        response.data = reAdd.data
        dispatch(setNewToken(reAdd.data))
        dispatch(setTransfer(false))
        dispatch(setServing(false))
        dispatch(setNowServing({}))
        dispatch(setCardActive(false))
        if (typeof reAdd.data == 'object') {
          dispatch(showReg(true))
          dispatch(showEntry(false))
          dispatch(setTransfer(false))
          dispatch(showTextFields(false))
          dispatch(setClicked(false))
        } else {
          dispatch(deleteField(true))
          dispatch(setClicked(false))
          dispatch(setTransfer(false))
        }
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenError(err.response.data.msg))
        dispatch(setTransfer(false))
        dispatch(deleteField(true))
        dispatch(setClicked(false))
        dispatch(setNowServing({}))
        return
      }
    }
  }
}

export const checkUser = (inputData) => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      dispatch(setClicked(true))
      let getUser = await axios.post(`${serveruri}/api/fetch-user`, inputData)

      if (getUser.status === 200) {
        response.status = 200
        response.data = getUser.data
        if (!isEmpty(getUser.data) && !getUser.data.token) {
          dispatch(setUserToken(getUser.data))
          dispatch(showTextFields(true))
          dispatch(setClicked(false))
        } else {
          if (getUser.data.parkStatus) {
            dispatch(setNewToken(getUser.data))
            dispatch(setDropToken(getUser.data.token))
            dispatch(setClicked(false))
            dispatch(getExistingToken(
              { msg: `User is in Parked Queue. QId: ${getUser.data.token}` }
            ))
          } else if (getUser.data.missedStatus) {
            dispatch(setNewToken(getUser.data))
            dispatch(setDropToken(getUser.data.token))
            dispatch(setClicked(false))
            dispatch(getExistingToken(
              { msg: `User is in Missed Queue. QId: ${getUser.data.token}` }
            ))
            dispatch(setRequeue(true))
            dispatch(setRequeueId(getUser.data.tokenId))
          } else {
            let appointedTime = moment(getUser.data.appointedDate).format('Do MMM, YYYY hh:mm a')
            dispatch(setNewToken(getUser.data))
            dispatch(setUserToken(getUser.data))
            dispatch(setDropToken(getUser.data.token))
            dispatch(setClicked(false))
            dispatch(getExistingToken(
              {
                msg: `User already in Queue. QId: ${getUser.data.token}. 
                Appointed Date: ${appointedTime}`
              }
            ))
          }
        }
        response.message = 'User checked successfully'
      } else {
        dispatch(showTextFields(true))
        dispatch(setClicked(false))
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenError(err.response.data.msg))
        dispatch(showTextFields(true))
        dispatch(setClicked(false))
        return
      }
    }
  }
}

export const checkToken = (inputData) => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      let getUser = await axios.post(`${serveruri}/api/fetch-user-by-token`, inputData)

      if(getUser.status === 200) {
        response.status = 200
        response.data = getUser.data
        if (getUser.data.parkStatus) {
          dispatch(setNewToken(getUser.data))
          dispatch(setDropToken(getUser.data.token))
          dispatch(setClicked(false))
          dispatch(getExistingToken(
            { msg: `User is in Parked Queue. QId: ${getUser.data.token}` }
          ))
        } else if (getUser.data.missedStatus) {
          dispatch(setNewToken(getUser.data))
          dispatch(setDropToken(getUser.data.token))
          dispatch(setClicked(false))
          dispatch(getExistingToken(
            { msg: `User is in Missed Queue. QId: ${getUser.data.token}` }
          ))
          dispatch(setRequeue(true))
          dispatch(setRequeueId(getUser.data.tokenId))
        } else {
          let appointedTime = moment(getUser.data.appointedDate).format('Do MMM, YYYY hh:mm a')
          dispatch(setNewToken(getUser.data))
          dispatch(setUserToken(getUser.data))
          dispatch(setDropToken(getUser.data.token))
          dispatch(setClicked(false))
          dispatch(getExistingToken(
            {
              msg: `User already in Queue. QId: ${getUser.data.token}. 
              Appointed Date: ${appointedTime}`
            }
          ))
        }
        response.message = 'User checked successfully'
      } 
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenError(err.response.data.msg))
        dispatch(showTextFields(true))
        dispatch(setClicked(false))
        return
      }
    }
  }
}

export const registerUser = (inputData) => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      let addNewUser = await axios.post(`${serveruri}/api/add-new-user`, inputData)

      if (addNewUser.status === 200) {
        response.status = 200
        response.data = addNewUser.data
        dispatch(setUserToken(addNewUser.data))
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenError(err.response.data.msg))
        return
      }
    }
  }
}

export const dropToken = (inputData) => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      dispatch(setClicked(true))
      let drop = await axios.post(`${serveruri}/api/drop-token`, inputData)

      if (drop.status === 200) {
        response.status = 200
        response.data = drop.data
        dispatch(setMsg('Token Cancelled'))
        dispatch(setClicked(false))
      }
    } catch (err) {
      if (err.response) {
        dispatch(setTokenError(err.response.data.msg))
        dispatch(setClicked(false))
        return
      }
    }
  }
}

export const addFeedback = (inputData) => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      let feedback = await axios.post(`${serveruri}/api/add-feedback`, inputData)

      if (feedback.status === 200) {
        response.status = 200
        response.data = feedback.data
        dispatch(setFeedbackSuccess('Thanks for your valueable rating'))
        response.message = 'Feedback stored'
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setFeedbackError(err.response.data.msg))
        return
      }
    }
  }
}

export const getTimeList = () => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      let timeList = await axios.get(`${serveruri}/api/get-all-time`)

      if (timeList.status === 200) {
        response.status = 200
        response.data = timeList.data
        dispatch(setTimeList(timeList.data))
        response.message = 'Timelist fetched'
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setFeedbackError(err.response.data.msg))
        return
      }
    }
  }
}

export const addServiceTime = inputData => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {

      let addService = await axios.post(`${serveruri}/api/add-service-time`, inputData)

      if (addService.status === 200) {
        let timeList = await axios.get(`${serveruri}/api/get-service-time`)

        if (timeList.status === 200) {
          response.status = 200
          response.data = timeList.data
          dispatch(setServiceTime(timeList.data))
          response.message = 'Timelist fetched'
        }
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setFeedbackError(err.response.data.msg))
        return
      }
    }
  }
}

export const getServiceTimes = () => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      let timeList = await axios.get(`${serveruri}/api/get-service-time`)

      if (timeList.status === 200) {
        response.status = 200
        response.data = timeList.data
        dispatch(setServiceTime(timeList.data))
        response.message = 'Timelist fetched'
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setFeedbackError(err.response.data.msg))
        return
      }
    }
  }
}

export const sendSMS = (inputData) => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      let sms = await axios.post(`${serveruri}/api/send-sms`, inputData)

      if (sms.status === 200) {
        response.status = 200
        response.data = sms.data
        response.message = 'sms sent'
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setFeedbackError(err.response.data.msg))
        return
      }
    }
  }
}

export const printToken = () => {
  return dispatch => {
    dispatch(showReg(true))
    dispatch(showEntry(false))
  }
}

export const backToMain = () => {
  return dispatch => {
    dispatch(setTokenError({}))
    dispatch(getExistingToken(''))
    dispatch(setMsg(''))
    dispatch(showTextFields(false))
    dispatch(showEntry(true))
    dispatch(showReg(false))
  }
}

export const clearData = () => {
  return dispatch => {
    dispatch(showTextFields(false))
    dispatch(showReg(false))
    dispatch(showEntry(true))
    dispatch(setRequeue(false))
    dispatch(setRequeueId(''))
    dispatch(setUserToken({}))
    dispatch(setNewToken({}))
    dispatch(getExistingToken(''))
    dispatch(setMsg(''))
    dispatch(setDropToken(''))
    dispatch(setTokenError({}))
  }
}

export const createAnotherToken = () => {
  return dispatch => {
    dispatch(getExistingToken({}))
    dispatch(showTextFields(true))
    dispatch(setClicked(false))
  }
}

export const getActiveBranchList = ()=>{
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {

      dispatch(setClicked(true))

      let getBranchList = await axios.get(`${serveruri}/api/get-all-branch`)

      if (getBranchList.status === 200) {
        response.message = 'Branch List Fetched'
        response.data = getBranchList.data;
        response.status = getBranchList.status;
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenError(err.response.data.msg))
        dispatch(showTextFields(false))
        dispatch(setClicked(false))
        return
      }
    }
  }
}

export const getCurrentActiveTokenList = (branchId)=>{
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {

      dispatch(setClicked(true))

      let getTokenList = await axios.get(`${serveruri}/api/get-current-active-token-list?branchId=${branchId}`)

      if (getTokenList.status === 200) {
        response.message = 'Token List Fetched'
        response.data = getTokenList.data;
        response.status = getTokenList.status;
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenError(err.response.data.msg))
        dispatch(showTextFields(false))
        dispatch(setClicked(false))
        return
      }
    }
  }
}

export const setNewToken = tokenData => {
  return {
    type: SET_NEW_TOKEN,
    data: tokenData
  }
}

export const setUserToken = data => {
  return {
    type: SET_USER_TOKEN,
    data: data
  }
}

export const showTextFields = data => {
  return {
    type: SHOW_TEXTFIELDS,
    data: data
  }
}

export const showReg = data => {
  return {
    type: SHOW_REGISTRATION,
    data: data
  }
}

export const showEntry = data => {
  return {
    type: SHOW_ENTRY,
    data: data
  }
}

export const setClicked = data => {
  return {
    type: SET_CLICKED,
    data: data
  }
}

export const deleteField = data => {
  return {
    type: DELETE_FIELD,
    data: data
  }
}

export const setDropToken = data => {
  return {
    type: DROP_TOKEN,
    data: data
  }
}

export const setMsg = data => {
  return {
    type: SET_MSG,
    data: data
  }
}

export const setServiceTime = data => {
  return {
    type: GET_SERVICE_TIME,
    data: data
  }
}

export const getExistingToken = data => {
  return {
    type: GET_EXISTING_TOKEN,
    data: data
  }
}

export const setTimeList = data => {
  return {
    type: GET_TIME_LIST,
    data: data
  }
}

export const setRequeue = data => {
  return {
    type: SET_REQUEUE,
    data: data
  }
}

export const setRequeueId = data => {
  return {
    type: SET_REQUEUE_ID,
    data: data
  }
}

export const setFeedbackSuccess = data => {
  return {
    type: SET_FEEDBACK_SUCCESS,
    data: data
  }
}

export const setFeedbackError = data => {
  return {
    type: SET_FEEDBACK_ERROR,
    data: data
  }
}

export const setTokenError = (errData) => {
  return {
    type: SET_TOKEN_ERRORS,
    data: errData
  }
}

export const showEntryOnLoad = () => {
  return {
    type: SHOW_ENTRY,
    data: true
  }
}
