import React from 'react'

import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect
} from 'shards-react'

import { connect } from 'react-redux'

let getBranchName = (id, list) => {
  if (!list.length) {
    return ''
  } else {
    let branch = list.find(item => item._id === id)

    if (branch) {
      return branch.branchName
    } else {
      return ''
    }
  }
}

const CounterDropDown = props => {
  let { handleChange, counter, branch } = props

  let counterList = counter ? counter.counterList ? counter.counterList : [] : []
  let branchList = branch ? branch.allBranches ? branch.allBranches : [] : []

  let handleCounterChange = event => {
    let branch = counterList.find(item => item._id === event.target.value)
    handleChange(event.target.value, branch.branchId)
  }
  return (
    <div>
      <InputGroup className='mb-3'>
        <InputGroupAddon type='prepend'>
          <InputGroupText>Counters</InputGroupText>
        </InputGroupAddon>
        <FormSelect onChange={handleCounterChange}>
          <option>Select</option>
          {counterList.map((item, i) => (
            <option key={i} value={item._id} disabled={!item.counterStatus}>
              {item.counterTitle + ' - ' + getBranchName(item.branchId, branchList)}
            </option>
          ))}
        </FormSelect>
      </InputGroup>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    counter: state.counter ? state.counter : null,
    branch: state.branch ? state.branch : null
  }
}

export default connect(
  mapStateToProps
)(CounterDropDown)
