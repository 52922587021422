import React from 'react'
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect
} from 'shards-react'

const RoleDropDown = props => {
  let { handleRoleChange } = props
  let handleChange = event => {
    handleRoleChange(event.target.value)
  }
  // console.log(value, '====vaalue===')
  return (
    <div>
      <InputGroup className='mb-3'>
        <InputGroupAddon type='prepend'>
          <InputGroupText>Roles</InputGroupText>
        </InputGroupAddon>
        <FormSelect onChange={handleChange}>
          <option>Select</option>
          <option value={'executive'}>Executive</option>
          <option value={'admin'}>Admin</option>
          <option value={'operator'}>Operator</option>
        </FormSelect>
      </InputGroup>
    </div>
  )
}

export default RoleDropDown
