import React, { Component } from 'react'
import LaneTop from './LaneTop'
import CustomCard from './CustomCard'

import { connect } from 'react-redux'

import { transferToCounter, onSelectCounter } from '../../axiosLib/tokenCounterActions'

import '../../assets/css/CounterList.css'

import Board from 'react-trello'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

let userToken = sessionStorage.getItem('userToken')

const style = {
  maxHeight: window.innerHeight - 118,
  border: '1px solid #f7f7f7',
  background: '#f7f7f7',
  borderRadius: 0,
  fontFamily: 'Poppins',
  padding: '0 0 16px 0'
}

const activeStyle = {
  background: '#38DAF8',
  maxHeight: window.innerHeight - 118,
  border: '1px solid #f7f7f7',
  borderRadius: 0,
  fontFamily: 'Poppins',
  padding: '0 0 16px 0'
}

class CounterList extends Component {
  state = {
    draggedData: undefined
  }

  updateBoard = newData => {
    this.setState({ draggedData: newData }, () => {
      // console.log('dragged!', newData)
      // this.props.updateDB(newData)
      // this.props.updateCounters(newData)
    })
  }

  onDragEnd = (cardId, sourceLandId, targetLaneId) => {
    // console.log('card', cardId, targetLaneId)
    // console.log('dragged data', this.state.draggedData)
    let { draggedData } = this.state
    let laneIndex = draggedData.lanes.findIndex(
      lane => lane.id === sourceLandId
    )
    // let targetIndex = draggedData.lanes.findIndex(
    //   lane => lane.id == targetLaneId
    // )
    let card = draggedData.lanes[laneIndex].cards.find(i => i.id === cardId)
    // console.log('Card, targetLane', card, targetLaneId)
    let data = {
      tokenId: card.id,
      serviceId: card.serviceId,
      counterId: targetLaneId
    }
    // console.log('Data', data)
    this.props.transferToCounter(data)
      .then(res => {
        if (userToken) {
          socket.emit('transferCounter', userToken)
        }
      })
    // console.log('dragged card', draggedData)
  }

  render() {
    let {
      user,
      tokenCounter,
      userCounter
    } = this.props

    let userId = user ? user.user ? user.user.id : null : null
    let allUserCounter = userCounter ?
      userCounter.allUserCounter ? userCounter.allUserCounter : [] : []
    let allCounter = allUserCounter.length ?
      allUserCounter.filter(counter => counter.userId.toString() === userId.toString()) : []

    let counterLanes = []
    let list = tokenCounter ?
      tokenCounter.counterList ?
        tokenCounter.counterList ? tokenCounter.counterList.lanes : []
        : [] : []

    let len = list.length

    for (let i = 0; i < len; i++) {
      if (allCounter.length) {
        let found = allCounter.find(
          counter => counter.counterId === list[i].id
        )
        if (found) counterLanes.push(list[i])
      }
    }

    let counterList = { lanes: counterLanes }

    if (counterList.lanes[0]) {
      counterList.lanes[0].droppable = false
      counterList.lanes[0].style = activeStyle
    }

    return (
      counterList.lanes && counterList.lanes.length ?
        <Board
          style={{
            background: '#fff',
            height: window.innerHeight - 100,
            fontFamily: 'Poppins',
            width: '100%'
          }}
          tagStyle={{ fontSize: '80%', fontFamily: 'Poppins' }}
          data={counterList}
          onDataChange={this.updateBoard}
          handleDragEnd={this.onDragEnd}
          components={{ Card: CustomCard, LaneHeader: LaneTop }}
          laneStyle={style}
        /> : <div style={{ width: '100%' }} />
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : null,
    tokenCounter: state.tokenCounter ? state.tokenCounter : null,
    userCounter: state.userCounter ? state.userCounter : null
  }
}

export default connect(
  mapStateToProps,
  {
    transferToCounter,
    onSelectCounter
  }
)(CounterList)
