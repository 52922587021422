import React from 'react'
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect
} from 'shards-react'

const UserCounterDropDown = props => {
  let { handleCounterChange, operator, counterList, users } = props

  let found = users.length ? users.find(user => user.id === operator) : null

  let branchId = found ? found.branchId : ''

  let filteredCounterList = counterList.length ?
    counterList.filter(counter => counter.branchId === branchId) : []

  let handleChange = event => {
    handleCounterChange(event.target.value)
  }

  return (
    <div>
      <InputGroup className='mb-3'>
        <InputGroupAddon type='prepend'>
          <InputGroupText>Counters</InputGroupText>
        </InputGroupAddon>
        <FormSelect onChange={handleChange}>
          <option>Select</option>
          {
            filteredCounterList.map(counter =>
              <option value={counter._id}>{counter.counterTitle}</option>
            )
          }
        </FormSelect>
      </InputGroup>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    branch: state.branch ? state.branch : null
  }
}

export default UserCounterDropDown