import React from 'react'
import DropDown from './DropDownBranch'

import '../../assets/css/CounterSelect.css'

const SelectBranch = (props) => {
    return (
        <div className='main'>
            <h4>Select a branch: </h4>
            <DropDown branchList={props.branchList} onSelectBranch={props.onSelectBranch}/>
        </div>
    )
}

export default SelectBranch