import React from 'react'
import { Col, Card, CardHeader, CardBody, Button } from 'shards-react'

import { connect } from 'react-redux'
import { updateStatus, getAllCounters } from '../../axiosLib/counterActions'
import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

let getBranchName = (id, list) => {
  if (!list.length) {
    return '-'
  } else {
    let branch = list.find(item => item._id === id)

    if (branch) {
      return branch.branchName
    } else {
      return '-'
    }
  }
}

const CounterTable = props => {
  let {
    counter,
    updateStatus,
    getAllCounters,
    branch
  } = props

  let counterList = counter ? counter.counterList ? counter.counterList : [] : []
  let branchList = branch ? branch.allBranches ? branch.allBranches : [] : []

  let onSubmit = id => {
    let data = { counterId: id }
    updateStatus(data)
      .then(res => {
        getAllCounters()
        if (res) {
          socket.emit('notify', 'Counter Updated')
        }
      })
  }

  return (
    <Col lg='8' className='mb-4'>
      <Card small className='mb-4'>
        <CardHeader className='border-bottom'>
          <h6 className='m-0'>Counter List</h6>
        </CardHeader>
        <CardBody className='p-0 pb-3'>
          <table className='table mb-0'>
            <thead className='bg-light'>
              <tr>
                <th scope='col' className='border-0'>
                  Counter Name
                </th>
                <th scope='col' className='border-0'>
                  Branch Name
                </th>
                <th scope='col' className='border-0'>
                  Counter Status
                </th>
                <th scope='col' className='border-0'>
                  Change Status
                </th>
              </tr>
            </thead>
            <tbody>
              {counterList.map((item, key) => (
                <tr key={key}>
                  <td>{item.counterTitle}</td>
                  <td>
                    {getBranchName(item.branchId, branchList)}
                  </td>
                  <td>{item.counterStatus ? 'Active' : 'Inactive'}</td>
                  <td>
                    <Button onClick={() => onSubmit(item._id)}>Change</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Col>
  )
}

function mapStatetoProps(state) {
  return {
    counter: state.counter ? state.counter : null,
    branch: state.branch ? state.branch : null
  }
}

export default connect(
  mapStatetoProps,
  { updateStatus, getAllCounters }
)(CounterTable)