import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";

const useStyles = makeStyles({
  card: {
    marginBottom: 10,
    borderRadius: 0,
  },
  root: {
    textAlign: "start",
    padding: 16,
    "&:last-child": {
      paddingBottom: 1,
    },
  },
  name: {
    fontSize: "1rem",
    fontFamily: "Poppins",
  },
  title: {
    fontSize: 13,
    fontFamily: "Poppins",
  },
  pos: {
    marginBottom: 5,
    fontSize: 13,
    fontFamily: "Poppins",
  },
});

let CustomCard = (props) => {
  const classes = useStyles();
  let { tokenCounter } = props;

  let activeList = tokenCounter
    ? tokenCounter.activeList
      ? tokenCounter.activeList
      : {}
    : {};
  let cardActive = tokenCounter
    ? tokenCounter.cardActive
      ? tokenCounter.cardActive
      : null
    : null;

  let QId =
    activeList.cards && activeList.cards.length ? activeList.cards[0].qId : "";
  let msg =
    QId === props.qId && cardActive === true ? "In Progress" : undefined;

  return (
    <Card
      className={classes.card}
      style={QId == props.qId ? { width: 380 } : {}}
    >
      <CardContent className={classes.root}>
        <div style={{ display: "flex" }}>
          <div>
            <div className={classes.name}>
              {props.qId + " "}
              {msg ? (
                <Chip
                  label={msg}
                  style={{
                    background: "#38DAF8",
                    fontSize: 12,
                    fontFamily: "Poppins",
                  }}
                />
              ) : null}
            </div>
            <Typography className={classes.pos} color="textSecondary">
              {props.name}
            </Typography>
          </div>

          {/* <div className='timer-text'>
            <Typography className={classes.pos} color='textSecondary'>
              20:39
            </Typography>
            <Typography className={classes.pos} color='textSecondary'>
              3min 40s
            </Typography>
          </div> */}
        </div>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {props.service}
        </Typography>
      </CardContent>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null,
  };
}

export default connect(mapStateToProps)(CustomCard);
