import React from 'react'
import AccessibleOutlinedIcon from '@material-ui/icons/AccessibleOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AssistantOutlinedIcon from '@material-ui/icons/AssistantOutlined';
import TableIcon from '@material-ui/icons/TableChartOutlined'
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import ReportIcon from '@material-ui/icons/AssignmentOutlined'
import BranchIcon from '@material-ui/icons/AccountTreeOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';

export const sidebar = [
  {
    title: 'User Profile',
    icon: <PersonIcon />
  },
  {
    title: 'Branches Management',
    icon: <BranchIcon />
  },
  {
    title: 'Operators Management',
    icon: <EditIcon />
  },
  {
    title: 'Service',
    icon: <AccessibleOutlinedIcon />
  },
  {
    title: 'Counter',
    icon: <AssignmentTurnedInOutlinedIcon />
  },
  {
    title: 'Service Counter',
    icon: <AssistantOutlinedIcon />
  },
  {
    title: 'Token Details',
    icon: <TableIcon />
  },
  {
    title: 'Individual Reports of Service, Counter, User',
    icon: <ReportIcon />
  },
  {
    title: 'All Service and Counter Report Analysis',
    icon: <AssessmentOutlinedIcon />
  }
]
