import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardHeader
} from 'shards-react'

import { connect } from 'react-redux'

const UserDetails = (props) => {
  let { user } = props
  let userDetails = user ? user.user ? user.user : {} : {}
  return (
    <Card small className='mb-4 pt-3'>
      <CardHeader className='border-bottom text-center'>
        {/* <div className='mb-3 mx-auto'>
          <img className='rounded-circle' alt={user.name} width='110' />
        </div> */}
        <h4 className='mb-0'>{userDetails.name ? userDetails.name : ''}</h4>
        <span className='text-muted d-block mb-2'>{userDetails.role ? userDetails.role : ''}</span>
      </CardHeader>
      {/* <ListGroup flush>
        <ListGroupItem className='px-4'>
          <div className='progress-wrapper'>
            <strong className='text-muted d-block mb-2'>
              {userDetails.performanceReportTitle}
            </strong>
            <Progress
              className='progress-sm'
              value={userDetails.performanceReportValue}
            >
              <span className='progress-value'>
                {userDetails.performanceReportValue}%
              </span>
            </Progress>
          </div>
        </ListGroupItem>
        <ListGroupItem className='p-4'>
          <strong className='text-muted d-block mb-2'>
            {userDetails.metaTitle}
          </strong>
          <span>{userDetails.metaValue}</span>
        </ListGroupItem>
      </ListGroup> */}
    </Card>
  )
}

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : null
  }
}

export default connect(
  mapStateToProps
)(UserDetails)
