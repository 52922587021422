import React, { useImperativeHandle } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import TextareaAutosize from "react-textarea-autosize";
import CloseIcon from '@material-ui/icons/Close';
import CompletedIcon from '@material-ui/icons/AssignmentTurnedInOutlined'
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import TransferDropDown from './TransferDropDown'

import { connect } from 'react-redux'

import {
  dropToken,
  reAddToken
} from '../../axiosLib/tokenActions'
import {
  handleTransferToNow,
  onSelectCounter
} from '../../axiosLib/tokenCounterActions'

import '../../assets/css/StartServing.css'
import '../../assets/css/AutosizeText.css'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: '#38DAF8',
    borderRadius: 0,
    marginTop: 16
  },
  root: {
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'row',
    padding: 12,
    '&:last-child': {
      paddingBottom: 16
    }
  },
  name: {
    fontSize: '1rem',
    fontFamily: 'Poppins',
    color: '#000000',
  },
  title: {
    fontSize: 13,
    fontFamily: 'Poppins'
  },
  pos: {
    marginBottom: 5,
    fontSize: 13,
    fontFamily: 'Poppins',
    color: '#000000',
  },
  pos2: {
    marginBottom: 5,
    fontSize: 13,
    fontFamily: 'Poppins',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '92%',
    borderTop: '0.5px solid #EAE5E5'
  },
  cssLabels: {
    color: '#EAE5E5'
  }
}))

let CustomCard = props => {
  const classes = useStyles()
  let { current } = props
  return current ? (
    <Card className={classes.card}>
      <CardContent className={classes.root}>
        <div style={{ width: '90%' }}>
          <Typography className={classes.name}>
            {current.qId}
          </Typography>
          <Typography className={classes.pos}>
            {current.name}
          </Typography>
          <Typography
            className={classes.title}
            color='textSecondary'
            gutterBottom
          >
            {current.service}
          </Typography>
        </div>

        <div style={{ marginTop: '1.5%' }}>
          <Typography className={classes.pos2} color='textSecondary'>
            01:39
          </Typography>
        </div>
      </CardContent>
    </Card>
  ) : null
}

let userToken = sessionStorage.getItem('userToken')

const Transfer = props => {
  const classes = useStyles()
  let {
    tokenCounter,
    handleTransferToNow,
    dropToken,
    reAddToken,
    user,
    onSelectCounter
  } = props

  let activeList = tokenCounter ? tokenCounter.activeList : null
  let activeCard = activeList ? activeList.cards ? activeList.cards[0] : null : null
  let activeCounter = tokenCounter ? tokenCounter.activeCounter : ''
  let allData = tokenCounter ? tokenCounter.allData ? tokenCounter.allData : null : null
  let branchId = user ? user.branchId ? user.branchId : '' : ''

  let [transfer, onTransfer] = React.useState('')
  let [note, setNote] = React.useState(activeCard ? activeCard.note : '')

  let onTransferChange = value => {
    onTransfer(value)
  }
  let handleChange = event => {
    setNote(event.target.value)
  }

  let onSubmit = () => {
    if (activeCard) {
      let data = {
        token: activeCard.qId,
        weight: activeCard.weight,
        tokenService: transfer,
        branchId: branchId,
        note: note
      }
      dropToken(data)
        .then(res => {
          reAddToken(data).then(add => {
            socket.emit('reQueue', userToken)
          }).catch(err => {
            console.log('error from Readd token', err)
          })
        }).catch(err => {
          console.log('Error from dropToken', err)
        })
    }
  }

  let onCancel = () => {
    handleTransferToNow()
  }
  return (
    <Card className='start-serving' style={{ height: window.innerHeight - 118 }}>
      <div>
        <header style={{ backgroundColor: '#EAE5E5', height: 68 }}>
          <Typography
            style={{ paddingTop: 20, marginLeft: 16, textAlign: 'left', color: '#000000', fontFamily: 'Poppins', fontSize: 18 }}
          >
            Now Serving
        </Typography>
        </header>
        <div>
          <div className='now-container' style={{ height: window.innerHeight - 252 }}>
            <CustomCard current={activeCard} />
            <div className="sidebarDescriptionWrapper">
              <TextareaAutosize
                defaultValue=""
                placeholder='Note'
                onChange={handleChange}
                value={note}
                minRows={4}
                style={{
                  backgroundColor: '#fff',
                  width: '92%',
                  fontFamily: 'Poppins',
                  fontSize: 14,
                  color: '#585858',
                  marginLeft: 16,
                  marginRight: 16
                }}
              />
            </div>
            <div style={{ overflow: 'auto', display: 'flex' }}>
              <div className="sidebarControllerRow">
                <span className="sidebarIcon">
                  <InboxOutlinedIcon />
                </span>
                <span className="sidebarTitle">Transfer to</span>
              </div>
              <TransferDropDown onChange={onTransferChange} />
            </div>
          </div>
          <div className='action-btn'>
            <div
              className='button'
              style={{ backgroundColor: '#EAE5E5', textAlign: 'center', fontFamily: 'Poppins' }}
              onClick={onCancel}
            >
              <CloseIcon />
              <p style={{ marginTop: '2.3px', marginLeft: '4px' }}>CANCEL</p>
            </div>
            <div
              className='button'
              style={{ backgroundColor: '#38F8C4', textAlign: 'center', fontFamily: 'Poppins' }}
              onClick={onSubmit}
            >
              <CompletedIcon />
              <p style={{ marginTop: '2.3px', marginLeft: '4px' }}>CONFIRM</p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user.user ? state.user.user : null : null,
    tokenCounter: state.tokenCounter ? state.tokenCounter : null
  }
}

export default connect(
  mapStateToProps,
  {
    handleTransferToNow,
    dropToken,
    reAddToken,
    onSelectCounter
  }
)(Transfer)
