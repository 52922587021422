import React, { Component } from 'react'
import StartServing from './StartServing'
import NowServing from './NowServing'
import Transfer from './Transfer'
import '../../assets/css/Serving.css'

import { connect } from 'react-redux'

class ServingBoard extends Component {
  state = {
    note: ''
  }
  render() {
    let { tokenCounter } = this.props

    let serving = tokenCounter ? tokenCounter.serving : null
    let walkIn = tokenCounter ? tokenCounter.walkIn : null
    let transfer = tokenCounter ? tokenCounter.transfer : null

    return (
      <React.Fragment>
        {!serving && !walkIn && !transfer ? <StartServing /> : null}
        {serving ? <NowServing /> : null}
        {transfer ? <Transfer /> : null}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null
  }
}

export default connect(mapStateToProps)(ServingBoard)
