import React from 'react'
import ServiceCreate from './ServiceCreate'

const ServiceMain = props => {
  return (
    <ServiceCreate />
  )
}

export default ServiceMain
