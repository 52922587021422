import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1.5),
    minWidth: 300
  },
  cssFocused: {},
  cssLabel: {
    '&:after': {
      color: '#62BC74'
    }
  }
}))

const BranchPicker = props => {
  const classes = useStyles()
  let { branches, handleChange, branch } = props

  const handleBranchChange = event => {
    handleChange(event.target.value)
  }

  return (
    <div>
      <FormControl className='service-picker-remote'>
        <InputLabel id="demo-simple-select-outlined-label">

        </InputLabel>
        <Select
          style={{ marginTop: 8 }}
          labelid='demo-simple-select-label'
          id='demo-simple-select'
          value={branch}
          variant='outlined'
          fullWidth
          onChange={handleBranchChange}
        >
          {Array.isArray(branches) &&
            branches.map((item, i) => (
              <MenuItem
                key={i}
                value={item.branchId}
                style={{
                  color: 'grey',
                  fontSize: 14,
                  fontWeight: 300,
                  fontFamily: 'Poppins'
                }}
              >
                {item.branchName + ' - ' + item.time + ' mins waiting'}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default BranchPicker
