import React from 'react'
import CounterCreate from './CounterCreate'

const CounterMain = props => {
  return (
    <CounterCreate />
  )
}

export default CounterMain
