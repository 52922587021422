import React from 'react'
import BranchAdd from './BranchAdd'

const BranchMain = props => {
  return (
    <BranchAdd />
  )
}

export default BranchMain
