import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}))

const DropDown = props => {
    const classes = useStyles()
    let [counterId, setCounter] = React.useState({})
    let branches = props.branchList && props.branchList.length > 0 ? props.branchList : []

    const handleChange = event => {
        setCounter(event.target.value)
        props.onSelectBranch(event.target.value)
    }

    return (
        <div>
            <FormControl className={classes.formControl}>
                <Select
                    style={{ color: '#000000', fontSize: 14, fontWeight: 300, fontFamily: 'Poppins', marginLeft: 16 }}
                    labelid='demo-simple-select-label'
                    id='demo-simple-select'
                    value={counterId}
                    onChange={handleChange}
                    disableUnderline
                >
                    {branches && branches.length > 0 ?
                        branches.map((item, i) => {
                            return (
                                <MenuItem key={i}
                                          value={item?._id}
                                          style={{
                                              color: 'grey',
                                              fontSize: 14,
                                              fontWeight: 300,
                                              fontFamily: 'Poppins'
                                          }}>{item?.branchName}</MenuItem>
                            )
                        }) : null}
                </Select>
            </FormControl>
        </div>
    )
}

export default DropDown