import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";

import { onLogin, getAllOperators } from "../../axiosLib/authActions";
import { getAllServices } from "../../axiosLib/serviceActions";
import { getAllCounters } from "../../axiosLib/counterActions";
import {
  getAllTokenCounters,
  getParked,
  fetchDoneList,
} from "../../axiosLib/tokenCounterActions";
import { getAllServiceCounters } from "../../axiosLib/serviceCounterActions";
import { getAllUserCounter } from "../../axiosLib/userCounterAction";
import { getAllBranches } from "../../axiosLib/branchActions";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Shadow from "../../assets/image/leadership.png";
import logoShadow from "../../assets/image/demo-logo.png";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import "../../assets/css/login.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 576,
    minWidth: 275,
    padding: 48,
    left: 200,
    top: 200,
    position: "absolute",
    backgroundColor: "rgb(250,255,255)",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    left: 205,
    position: "absolute",
    height: 40,
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    height: 48,
    backgroundColor: "cornflowerblue",
    fontFamily: "Poppins",
  },
}));

function ForgetPass(props) {
  const classes = useStyles();

  let {
    user,
    onLogin,
    getAllCounters,
    getAllServiceCounters,
    getAllServices,
    getAllTokenCounters,
    getParked,
    fetchDoneList,
    getAllUserCounter,
    getAllOperators,
    getAllBranches,
  } = props;
  let [userName, setUserName] = React.useState("");

  let handleUserName = (event) => {
    setUserName(event.target.value);
  };

  let onSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="body">
        <div className="top-text">
          {/* <img className='avatar' src={logoShadow} /> */}
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className="sign-text pointer">
              Sign in <ArrowRightAltIcon />{" "}
            </div>
          </Link>
        </div>
        <Card className="cardview">
          <Typography component="h1" variant="h5">
            Forget Password
          </Typography>

          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="User Name/Email/Phone"
              value={userName}
              onChange={handleUserName}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              type="submit"
            >
              Send Code <ArrowRightAltIcon style={{ marginLeft: 18 }} />
            </Button>
            <div style={{ display: "flex" }}>
              {user ? (
                user.error ? (
                  user.error.length ? (
                    <Typography
                      // variant='h5'
                      style={{
                        color: "red",
                        fontSize: 16,
                        fontFamily: "Poppins",
                      }}
                    >
                      *{user.error}
                    </Typography>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </form>
        </Card>

        <img
          src={Shadow}
          alt="Welcome to QQ App"
          variant="square"
          className="images"
        />
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : null,
  };
}

export default connect(mapStateToProps, {
  onLogin,
  getAllCounters,
  getAllServiceCounters,
  getAllServices,
  getAllTokenCounters,
  getParked,
  fetchDoneList,
  getAllUserCounter,
  getAllOperators,
  getAllBranches,
})(ForgetPass);
