import React, { Component } from 'react'
import Button from '@material-ui/core/Button'

import { Link } from 'react-router-dom'

import CounterList from './CounterList'
import ServingBoard from './ServingBoard'
import ParkingNew from './Parked'
import Typography from '@material-ui/core/Typography'
import CircularStatic from './CircularStatic'
import ViewListIcon from '@material-ui/icons/ShowChart'
import DashboardIcon from '@material-ui/icons/Dashboard'
import Dialog from '@material-ui/core/Dialog'
import PauseIcon from '@material-ui/icons/AccessTimeOutlined'
import MissedIcon from '@material-ui/icons/CallMissedOutgoingOutlined';

import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import TimelineIcon from '@material-ui/icons/Timeline';

import CounterSelect from '../TrelloBoard/CounterSelect'
import DropDown from '../TrelloBoard/DropDown'
import CloseIcon from '@material-ui/icons/Close';
import MissedQueue from './MissQList'
import Marquee from 'react-double-marquee';

import { connect } from 'react-redux'

import '../../assets/css/CounterBoard.css'

import { onLogout } from '../../axiosLib/authActions'
import {
  handleDisplayMain,
  clearMsg,
  showMissed
} from '../../axiosLib/tokenCounterActions'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'
let socket = socketIOClient(serveruri)

function isEmpty(obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false
    }
  }
  return true
}

let userToken = sessionStorage.getItem('userToken')

const CounterBoard = props => {
  let {
    onLogout,
    handleDisplayMain,
    clearMsg,
    user,
    tokenCounter,
    showMissed,
    branch
  } = props

  let loading = tokenCounter ? tokenCounter.loading ? tokenCounter.loading : false : false
  let activeUser = user ? user.user : null
  let status = activeUser ? activeUser.status : null
  let activeCounter = tokenCounter ?
    tokenCounter.activeCounter ? tokenCounter.activeCounter : '' : ''
  let nowServing = tokenCounter ? tokenCounter.nowServing ? tokenCounter.nowServing : {} : {}
  let errors = tokenCounter ? tokenCounter.errors ? tokenCounter.errors : null : null
  let displayMissed = tokenCounter ?
    tokenCounter.displayMissed ? tokenCounter.displayMissed : null : null
  let msg = tokenCounter ? tokenCounter.msg ? tokenCounter.msg : '' : ''
  let branchId = user ?
    user.user ?
      user.user.branchId ? user.user.branchId : ''
      : ''
    : ''
  let branchList = branch ? branch.allBranches ? branch.allBranches : [] : []
  let activeBranch = branchList.length ?
    branchList.find(branch => branch._id === branchId) : null
  let branchName = activeBranch ? activeBranch.branchName : ''

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <span>
        <MenuItem onClick={handleMenuClose}>
          <Link style={{ color: 'grey', fontSize: 12, fontWeight: 300, fontFamily: 'Poppins' }}
            to="/userprofile">
            Profile
          </Link>
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <Link style={{ color: 'grey', fontSize: 12, fontWeight: 300, fontFamily: 'Poppins' }}
            to="/">Log Out</Link>
        </MenuItem>
      </span>

    </Menu>
  );

  return (
    <div className='board_main'>
      {status ? (
        <>
          <header className='main-header'>
            {activeCounter.length ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <DropDown />
                <Typography className='name' style={{ paddingTop: 5, marginLeft: 16 }}>
                  {branchName.length ? branchName + ' Branch' : ''}
                </Typography>
              </div>
            ) : (
                <Typography className='name' style={{ paddingTop: 5, marginLeft: 16 }}>
                  Loading Counter...
                </Typography>
              )}
            <div className='sign-out' style={{ float: 'right' }}>
              <Tooltip title='Profile' placement='top'>
                <Typography className='name'
                  onClick={handleProfileMenuOpen}
                >{activeUser ? activeUser.name : ''}</Typography>
              </Tooltip>
            </div>
          </header>
          {renderMenu}
          {activeCounter.length ? (
            <div className='secondary_nav'>
              {!isEmpty(nowServing) ? (
                <div
                  style={{
                    width: '400px',
                    whiteSpace: 'nowrap',
                    marginLeft: '100px',
                    color: '#000000',
                    marginTop: '0.5%',
                    fontFamily: 'Poppins',
                    fontSize: 18
                  }}
                >
                  <Marquee direction='left'>
                    Now Serving:- Counter: {nowServing.counter}, Token No: {nowServing.qId}
                  </Marquee>
                </div>
              ) : null}
              <div className='action-btn'>
                {msg.length ?
                  <div
                    style={{
                      width: '30%',
                      marginLeft: '50%',
                      marginRight: '5%',
                      zIndex: 100,
                      position: 'fixed'
                    }}
                  >
                    <Alert onClose={clearMsg} severity="success">{msg}</Alert>
                  </div>
                  : errors ?
                    <div
                      style={{
                        width: '30%',
                        marginLeft: '50%',
                        marginRight: '5%',
                        zIndex: 100,
                        position: 'fixed'
                      }}
                    >
                      <Alert onClose={clearMsg} severity="error">{errors}</Alert>
                    </div>
                    : ''
                }
                <Tooltip title='Show Board' placement='top'>
                  <IconButton onClick={handleDisplayMain}>
                    <DashboardIcon style={{ color: 'black' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Missed Queus'>
                  <IconButton onClick={showMissed}>
                    <TimelineIcon style={{ color: 'grey' }} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ) : ''}
          <div className='counter_dash'>
            {displayMissed ? (
              <div className='done_table'>
                <h3>Missed Queue table:</h3>
                <MissedQueue />
              </div>
            ) : activeCounter.length ? (
              <React.Fragment>
                <ServingBoard />
                <CounterList />
                <ParkingNew />
              </React.Fragment>
            ) : (
                  <CounterSelect />
                )}
          </div>
        </>
      ) : (
          <Typography>
            User not verified yet. Please contact to the Executive
          </Typography>
        )
      }
      {/* <Dialog open={loading} aria-labelledby='form-dialog-title'>
        Loading...
      </Dialog> */}
      {loading ? <CircularStatic /> : ''}
    </div >
  )
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : null,
    tokenCounter: state.tokenCounter ? state.tokenCounter : null,
    branch: state.branch ? state.branch : null
  }
}

export default connect(
  mapStateToProps,
  {
    onLogout,
    handleDisplayMain,
    clearMsg,
    showMissed
  }
)(CounterBoard)
