import React, { useEffect } from 'react'
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormInput,
  FormGroup,
  FormCheckbox,
  Button
} from 'shards-react'

import { connect } from 'react-redux'
import { serviceWeightUpdate, getAllServices } from '../../axiosLib/serviceActions'

import '../../assets/css/ServiceList.css'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const ServiceList = props => {
  let { services, serviceWeightUpdate, getAllServices } = props

  let allServices = services ? services.services ? services.services : [] : []

  let [serviceList, setServiceList] = React.useState(allServices)

  useEffect(() => {
    getAllServices()
      .then(res => {
        if (res) {
          setServiceList(res.data)
        }
      })
    socket.on('addService', data => {
      getAllServices()
        .then(res => {
          if (res) {
            setServiceList(data)
          }
        })
    })
  }, [])

  let handleWeight = (event, id) => {
    let nextState = serviceList.map((a, idx) => {
      if (idx === id) a.servicePriorityWeight = event.target.value
      return a
    })
    setServiceList(nextState)
  }

  let handleName = (event, id) => {
    let nextState = serviceList.map((a, idx) => {
      if (idx === id) a.serviceName = event.target.value
      return a
    })
    setServiceList(nextState)
  }

  let handlePrefix = (event, id) => {
    let nextState = serviceList.map((a, idx) => {
      if (idx === id) a.servicePrefix = event.target.value
      return a
    })
    setServiceList(nextState)
  }

  let handleDuration = (event, id) => {
    let nextState = serviceList.map((a, idx) => {
      if (idx === id) a.duration = event.target.value
      return a
    })
    setServiceList(nextState)
  }


  let handleCheckbox = (id) => {
    let nextState = serviceList.map((a, idx) => {
      if (idx === id) a.checkPriority = !a.checkPriority
      return a
    })
    setServiceList(nextState)
  }

  let submitWeight = (item) => {
    let data = {
      serviceId: item._id,
      servicePriorityWeight: item.servicePriorityWeight ? item.servicePriorityWeight : null,
      serviceName: item.serviceName.length ? item.serviceName : null,
      servicePrefix: item.servicePrefix.length ? item.servicePrefix : null,
      duration: item.duration ? item.duration : null,
      checkPriority: item.checkPriority
    }
    serviceWeightUpdate(data)
      .then(res => {
        getAllServices()
        socket.emit('notify', 'Service Updated')
        let nextState = serviceList.sort(
          (a, b) => a.servicePriorityWeight < b.servicePriorityWeight ? -1 : 1
        )
        setServiceList(nextState)
      })
  }

  return (
    <Col lg='10' className='mb-4'>
      <Card small className='mb-4'>
        <CardHeader className='border-bottom'>
          <h6 className='m-0'>Service List</h6>
        </CardHeader>
        <CardBody className='p-0 pb-3'>
          <table className='table mb-0'>
            <thead className='bg-light'>
              <tr>
                <th scope='col' className='border-0'>
                  Service Name
                </th>
                <th scope='col' className='border-0'>
                  Service Prefix
                </th>
                <th scope='col' className='border-0'>
                  Weight
                </th>
                <th scope='col' className='border-0'>
                  Service Duration (mins)
                </th>
                <th scope='col' className='border-0'>
                  Checkbox Priority
                </th>
                <th scope='col' className='border-0'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {serviceList.map((item, key) => {
                return (
                  <tr key={key}>
                    <td >
                      <Form>
                        <FormGroup>
                          <FormInput
                            type='text'
                            value={item.serviceName}
                            onChange={(e) => handleName(e, key)}
                            style={{ width: 150 }}
                          />
                        </FormGroup>
                      </Form>
                    </td>
                    <td >
                      <Form>
                        <FormGroup>
                          <FormInput
                            type='text'
                            value={item.servicePrefix}
                            onChange={(e) => handlePrefix(e, key)}
                            style={{ width: 75 }}
                          />
                        </FormGroup>
                      </Form>
                    </td>
                    <td >
                      <Form>
                        <FormGroup>
                          <FormInput
                            id='feInputZip'
                            type='number'
                            min='1'
                            value={item.servicePriorityWeight}
                            onChange={(e) => handleWeight(e, key)}
                            style={{ width: 75 }}
                          />
                        </FormGroup>
                      </Form>
                    </td>
                    <td >
                      <Form>
                        <FormGroup>
                          <FormInput
                            type='number'
                            min='1'
                            value={item.duration}
                            onChange={(e) => handleDuration(e, key)}
                            style={{ width: 75 }}
                          />
                        </FormGroup>
                      </Form>
                    </td>
                    <td >
                      <FormCheckbox
                        onChange={() => handleCheckbox(key)}
                        checked={item.checkPriority}
                        style={{ width: 15, height: 15, marginTop: 5 }}
                      />
                    </td>
                    <td >
                      <Button
                        style={{ height: 35 }}
                        onClick={() => submitWeight(item)}
                      >
                        Submit
                    </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Col>
  )
}

function mapStateToProps(state) {
  return {
    services: state.service ? state.service : null
  }
}

export default connect(
  mapStateToProps,
  { serviceWeightUpdate, getAllServices }
)(ServiceList)
