import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { connect } from 'react-redux'
import { onSelectCounter } from '../../axiosLib/tokenCounterActions'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const DropDown = props => {
  const classes = useStyles()
  let {
    counter,
    tokenCounter,
    onSelectCounter,
    userCounter,
    user
  } = props

  let activeCounter = tokenCounter ? tokenCounter.activeCounter : ''
  let allData = tokenCounter ? tokenCounter.allData : null
  let userId = user ? user.user ? user.user.id : null : null
  let branchId = user ? user.user ? user.user.branchId : null : null
  let allUserCounter = userCounter ?
    userCounter.allUserCounter ? userCounter.allUserCounter : [] : []
  let allCounter = allUserCounter.length ?
    allUserCounter.filter(counter => counter.userId.toString() === userId.toString()) : []
  let counterList = counter ? counter.counterList ? counter.counterList : [] : []
  let filteredCounterList = counterList.length ?
    counterList.filter(item => item.branchId === branchId) : []
  let counterDropDown = allCounter.length ? filteredCounterList.length ?
    allCounter.map(counter => {
      let found = filteredCounterList.find(item => item._id === counter.counterId)
      return found
    }) : [] : []

  let [counterId, setCounter] = React.useState(activeCounter)

  const handleChange = event => {
    setCounter(event.target.value)
    if (allData) {
      onSelectCounter(event.target.value, allData)
    }
  }
  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          style={{ color: '#000000', fontSize: 14, fontWeight: 300, fontFamily: 'Poppins', marginLeft: 16 }}
          labelid='demo-simple-select-label'
          id='demo-simple-select'
          value={counterId}
          onChange={handleChange}
          disableUnderline
        >
          {Array.isArray(counterDropDown) &&
            counterDropDown.map((item, i) => {
              if (item) {
                return (
                  <MenuItem key={i} value={item._id} style={{ color: 'grey', fontSize: 14, fontWeight: 300, fontFamily: 'Poppins' }}>
                    {item.counterTitle}
                  </MenuItem>
                )
              }
            })}
        </Select>
      </FormControl>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : null,
    counter: state.counter ? state.counter : null,
    tokenCounter: state.tokenCounter ? state.tokenCounter : null,
    userCounter: state.userCounter ? state.userCounter : null
  }
}

export default connect(
  mapStateToProps,
  {
    onSelectCounter
  }
)(DropDown)
