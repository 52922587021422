import axios from 'axios'
import {
  SET_COUNTER_LIST,
  GET_ALL_DATA,
  SET_ACTIVE_COUNTER,
  REMOVE_ACTIVE_COUNTER,
  SET_ACTIVE_LIST,
  SET_CALL_TIME,
  SET_CARD_ACTIVE,
  SET_UPDATED,
  SET_SELECTED,
  SET_SERVING,
  SET_TRANSFER,
  SET_WALKIN,
  SET_DISPLAY_DONE,
  SET_DISPLAY_PARKED,
  SET_WALKIN_CARD,
  SET_ALL_PARKED,
  GET_TOTAL_SERVED,
  GET_SERVICE_AVG_TIME,
  GET_WAITING_TIME,
  GET_COUNTER_TIME,
  SET_NEW_PARKED,
  SET_TOKEN_COUNTER_ERROR,
  SET_SERVICE_REPORT,
  SET_COUNTER_REPORT,
  SET_CHECKLISTS,
  SET_TOKEN_COUNTER_MSG,
  SET_NEW_MISSED,
  SET_ALL_MISSED,
  DISPLAY_MISSED,
  SET_REPORT_START,
  SET_REPORT_END,
  SET_NOW_SERVING,
  SET_LOADING,
  SET_DONE_LIST,
  DISPLAY_MAIN
} from '../constants'

import {
  setClicked,
  setUserToken,
  showEntry,
  showTextFields,
  setTokenError,
  getExistingToken
} from './tokenActions'
import { serveruri } from './config'
import showErr from './errAxios'
import TimerMixin from 'react-timer-mixin'
import { duration } from 'moment'

let counterId = sessionStorage.getItem('counter')

const counterGen = data => {
  let len = data.counters.length
  let lanes = data.counters.map(item => {
    let laneValue = {
      cards: [],
      title: item.counterTitle,
      id: item._id,
      createdAt: item.createdAt
    }
    return laneValue
  })
  for (let i = 0; i < len; i++) {
    let counterId = lanes[i].id
    let cardData = data.tokenCounters.filter(
      item => item.counterId == counterId
    )
    if (cardData.length > 0) {
      cardData = cardData.sort((a, b) => (a.weight < b.weight ? -1 : 1))
      let validCards = cardData.filter(
        item => item.status == true && item.parkStatus == false && item.missedStatus == false
      )
      lanes[i].cards = validCards.map(item => {
        let cardService = data.services.find(ser => ser._id == item.serviceId)
          .serviceName
        let cardDuration = data.services.find(ser => ser._id == item.serviceId)
          .duration
        let checkPriority = data.services.find(ser => ser._id == item.serviceId)
          .checkPriority
        let token = data.tokens.find(token => token._id == item.tokenId).token
        let weight = data.tokens.find(token => token._id == item.tokenId).weight
        let clientId = data.tokens.find(token => token._id == item.tokenId)
          .clientId
        let client = data.users.find(user => user._id == clientId)
        let clientName = client.name ? client.name : ''
        let clientPhone = client ? client.phone ? client.phone : null : null

        let finalCard = {
          id: item.tokenId,
          name: clientName,
          service: cardService,
          serviceId: item.serviceId,
          qId: token,
          laneId: counterId,
          weight: weight,
          note: item.note ? item.note : '',
          checkPriority: checkPriority,
          duration: cardDuration,
          phone: clientPhone
        }
        return finalCard
      })
    }
  }
  return lanes
}

let onSelect = (id, data) => {
  let pushArr = []
  data = data.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
  let first = data.find(item => item.id == id)
  pushArr.push(first)
  let restArr = data.filter(item => item.id !== id)
  let final = pushArr.concat(restArr)

  return final
}

export const getAllTokenCounters = (bool) => {
  let token = sessionStorage.getItem('userToken')
  let counter = sessionStorage.getItem('counter')
  return async dispatch => {

    if (bool) {
      dispatch(setLoading(true))
    }
    axios
      .all([
        axios.get(`${serveruri}/api/all-token-counters`),
        axios.get(`${serveruri}/api/all-counters`),
        axios.get(`${serveruri}/api/all-users`),
        axios.get(`${serveruri}/api/all-services`),
        axios.get(`${serveruri}/api/all-tokens`)
      ])
      .then(
        axios.spread(
          (tokenCounters, counters, users, services, tokens) => {

            let result = {
              tokenCounters: tokenCounters.data,
              counters: counters.data,
              users: users.data,
              services: services.data,
              tokens: tokens.data
            }
            dispatch(setAllData(result))
            let lanes = counterGen(result)
            if (counter) {
              let final = onSelect(counter, lanes)
              dispatch(setCounterList(final))
              dispatch(setActiveList(final[0]))
              dispatch(setActiveCounter(counter))
              dispatch(setLoading(false))
            } else {
              dispatch(setCounterList(lanes))
              dispatch(setActiveList(lanes[0]))
              dispatch(setLoading(false))
            }
          }
        )
      )
      .catch(err => {
        console.log('Error from main', err)
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          dispatch(setLoading(false))
        }
      })

    if (token) {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let allParked = await axios.get(`${serveruri}/api/show-parked`)

      if (allParked.status === 200) {
        let filteredData = allParked.data.length && counter ? allParked.data.filter(
          item => item.counterId.toString() === counter.toString()
        ) : []
        dispatch(setAllParked(filteredData))
      }
    } else {
      dispatch(setAllParked([]))
    }
  }
}

export const transferToCounter = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        dispatch(setLoading(true))

        let transfer = await axios.post(`${serveruri}/api/transfer-counter`, inputData)

        if (transfer.status === 200) {
          response.status = 200
          response.data = transfer.data
          dispatch(setUpdated(transfer.data))
          dispatch(setLoading(false))
          dispatch(setTokenCounterMsg('Token Transferred'))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterMsg(''))
            dispatch(setNowServing(null))
          }, 5000)
        }
        return response
      } catch (err) {
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          dispatch(setLoading(false))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterError(""))
          }, 2000)
          return
        }
      }
    }
  }
}

export const clearMsg = () => {
  return dispatch => {
    dispatch(setTokenCounterMsg(''))
    dispatch(setTokenCounterError(""))
  }
}

export const callNext = () => {
  return dispatch => {
    dispatch(setServing(true))
    dispatch(setCardActive(true))
    dispatch(setCallTime(new Date()))
  }
}

export const serviceDone = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let done = await axios.post(`${serveruri}/api/service-done`, inputData)

      if (done.status === 200) {
        response.status = 200
        response.token = token
        response.data = done.data
        dispatch(setServing(false))
        dispatch(setCardActive(false))
        dispatch(setCallTime(null))
        dispatch(setLoading(false))
        dispatch(setNowServing(null))
        response.message = 'Service marked done'
        //console.log('Service Marked Done')
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenCounterError(err.response.data.msg))
        dispatch(setLoading(false))
        TimerMixin.setTimeout(() => {
          dispatch(setTokenCounterError(""))
          dispatch(setNowServing(null))
        }, 2000)
        return
      }
    }
  }
}

export const parkToken = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let parked = await axios.post(`${serveruri}/api/park-token`, inputData)

        if (parked.status === 200) {
          response.status = 200
          response.token = token
          response.data = parked.data
          dispatch(setServing(false))
          dispatch(setCardActive(false))
          dispatch(setNewParked(parked.data))
          dispatch(setLoading(false))
          dispatch(setNowServing(null))
        }

        return response
      } catch (err) {
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          dispatch(setLoading(false))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterError(""))
          }, 2000)
          return
        }
      }
    }
  }
}

export const unparkToken = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let unpark = await axios.post(`${serveruri}/api/return-to-counter`, inputData)

        if (unpark.status === 200) {
          response.status = 200
          response.token = token
          response.data = unpark.data

          return response
        }

      } catch (err) {
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          dispatch(setLoading(false))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterError(""))
          }, 2000)
          return
        }
      }
    }
  }
}

export const searchParked = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      if (token) {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        dispatch(setLoading(true))

        let searched = await axios.post(`${serveruri}/api/search-parked`, inputData)

        if (searched.status === 200) {
          response.status = 200
          response.token = token
          response.data = searched.data
          dispatch(setLoading(false))
          dispatch(setAllParked([searched.data]))
        }
      } else {
        dispatch(setLoading(false))
        dispatch(setAllParked([]))
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenCounterError(err.response.data.msg))
        dispatch(setLoading(false))
        TimerMixin.setTimeout(() => {
          dispatch(setTokenCounterError(""))
        }, 2000)
        return
      }
    }
  }
}

export const getParked = () => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      if (token) {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let parked = await axios.get(`${serveruri}/api/show-parked`)

        if (parked.status === 200) {
          response.status = 200
          response.token = token
          response.data = parked.data
          let filteredData = parked.data.length && counterId ? parked.data.filter(
            item => item.counterId.toString() === counterId.toString()
          ) : []
          dispatch(setAllParked(filteredData))
          response.message = 'Fetched all parked token successfully'
        }
      } else {
        dispatch(setAllParked([]))
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenCounterError(err.response.data.msg))
        TimerMixin.setTimeout(() => {
          dispatch(setTokenCounterError(""))
        }, 2000)
        return
      }
    }
  }
}

export const fetchDoneList = () => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      if (token) {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let donelist = await axios.get(`${serveruri}/api/done-list`)

        if (donelist.status === 200) {
          response.status = 200
          response.token = token
          response.data = donelist.data
          dispatch(setDoneList(donelist.data))
          dispatch(setLoading(false))
          response.message = 'Donelist fetched'
        }
      } else {
        dispatch(setDoneList([]))
        dispatch(setLoading(false))
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenCounterError(err.response.data.msg))
        TimerMixin.setTimeout(() => {
          dispatch(setTokenCounterError(""))
        }, 2000)
        return
      }
    }
  }
}

export const getReportByService = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let report = await axios.post(`${serveruri}/api/get-served-by-service`, inputData)

        if (report.status === 200) {
          response.status = 200
          response.token = token
          response.data = report.data
          dispatch(getTotalServed(report.data))
          dispatch(serviceAvgTime(report.data))
          dispatch(getWaitingTime(report.data))
          response.message = 'Report data fetched'
        }
        return response
      } catch (err) {
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterError(""))
          }, 2000)
          return
        }
      }
    }
  }
}

export const getCounterTime = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let counterTime = await axios.post(`${serveruri}/api/get-counter-time`, inputData)

        if (counterTime.status === 200) {
          response.status = 200
          response.token = token
          response.data = counterTime.data
          dispatch(setCounterTime(counterTime.data))
        }
        return response
      } catch (err) {
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterError(""))
          }, 2000)
          return
        }
      }
    }
  }
}

export const getAllReports = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let allReports = await axios.post(`${serveruri}/api/get-all-by-time`, inputData)

        if (allReports.status === 200) {
          response.status = 200
          response.token = token
          response.data = allReports.data
          dispatch(setServiceRerport(allReports.data))
          dispatch(setCounterReport(allReports.data))
        }
        return response
      } catch (err) {
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterError(""))
          }, 2000)
          return
        }
      }
    }
  }
}

export const getCheckLists = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let checklist = await axios.post(`${serveruri}/api/get-checklist`, inputData)

        if (checklist.status === 200) {
          response.status = 200
          response.token = token
          response.data = checklist.data
          dispatch(setChecklists(checklist.data))
          response.message = 'Checklist fetched'
        }
        return response
      } catch (err) {
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterError(""))
          }, 2000)
          return
        }
      }
    }
  }
}

export const addMissed = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let missed = await axios.post(`${serveruri}/api/add-missed`, inputData)

        if (missed.status === 200) {
          response.status = 200
          response.token = token
          response.data = missed.data
          dispatch(setServing(false))
          dispatch(setCardActive(false))
          dispatch(setNewMissed(missed.data))
          response.message = 'Missed token generated'
        }
        return response
      } catch (err) {
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterError(""))
          }, 2000)
          return
        }
      }
    }
  }
}

export const returnMissed = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {

      dispatch(setClicked(true))

      let returnMissed = await axios.post(`${serveruri}/api/return-missed`, inputData)

      if (returnMissed.status === 200) {
        response.status = 200
        response.token = token
        response.data = returnMissed.data

        dispatch(showEntry(true))
        dispatch(showTextFields(false))
        dispatch(setTokenError({}))
        dispatch(getExistingToken({}))

        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let allMissed = await axios.get(`${serveruri}/api/show-missed`)

        if (allMissed.status === 200) {
          dispatch(setAllMissed(allMissed.data))
          dispatch(setClicked(false))
        }

        response.message = 'Token Returned'
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setTokenCounterError(err.response.data.msg))
        dispatch(setClicked(false))
        TimerMixin.setTimeout(() => {
          dispatch(setTokenCounterError(""))
        }, 2000)
        return
      }
    }
  }
}

export const showMissed = () => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  let token = sessionStorage.getItem('userToken')
  if (token) {
    return async dispatch => {
      try {

        dispatch(setDisplayMissed(true))
        dispatch(setDisplayParked(false))
        dispatch(setDisplayMain(false))
        dispatch(setDisplayDone(false))
        dispatch(setLoading(true))

        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let allMissed = await axios.get(`${serveruri}/api/show-missed`)

        if (allMissed.status === 200) {
          response.status = 200
          response.token = token
          response.data = allMissed.data
          dispatch(setAllMissed(allMissed.data))
          dispatch(setLoading(false))
          response.message = 'All missed token fetched'
        }
        return response
      } catch (err) {
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          dispatch(setLoading(false))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterError(""))
          }, 2000)
          return
        }
      }
    }
  }
}

export const displayTokenDetails = data => {
  return dispatch => {
    dispatch(setNowServing(data))
  }
}

export const handleDisplayMain = () => {
  return dispatch => {
    dispatch(setDisplayMain(true))
    dispatch(setDisplayMissed(false))
  }
}

// export const displayToken = (data, cb) => {
//   let len = data.counter.length
//   let show = data.counter[len - 1]
//   axios
//     .get(
//       `http://192.168.0.238/?counter=${show}&queue=${data.qId}`
//     )
//     .then(res => {
//       cb(null, res.data)
//     })
//     .catch(err => {
//       showErr(err, cb)
//     })
// }

export const onSelectCounter = (id, allData) => {
  sessionStorage.setItem('counter', id)
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      try {
        dispatch(setAllData(allData))
        dispatch(setActiveCounter(id))

        let lanes = counterGen(allData)

        let final = onSelect(id, lanes)

        dispatch(setCounterList(final))
        dispatch(setActiveList(final[0]))
        dispatch(setSelected(true))
        dispatch(setTransfer(false))
        dispatch(setServing(false))
        dispatch(setCardActive(false))
        dispatch(setLoading(false))
        dispatch(setDisplayDone(false))
        dispatch(setDisplayParked(false))
        dispatch(setDisplayMissed(false))

        if (token) {
          let parked = await axios.get(`${serveruri}/api/show-parked`)

          if (parked.status === 200) {
            response.status = 200
            response.token = token
            response.data = parked.data
            let filteredData = parked.data.filter(
              item => item.counterId.toString() === id.toString()
            )
            dispatch(setAllParked(filteredData))
            response.message = 'Fetched all parked token successfully'
          }
        } else {
          dispatch(setAllParked([]))
        }

        return response
      } catch (err) {
        if (err.response) {
          dispatch(setTokenCounterError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setTokenCounterError(""))
          }, 2000)
          return
        }
      }
    }
  }
}

export const handleReturn = () => {
  return dispatch => {
    dispatch(setServing(false))
    dispatch(setCardActive(false))
    dispatch(setNowServing(null))
  }
}

export const handleTransferDisplay = () => {
  return dispatch => {
    dispatch(setServing(false))
    dispatch(setTransfer(true))
  }
}

export const handleTransferToNow = () => {
  return dispatch => {
    dispatch(setServing(true))
    dispatch(setTransfer(false))
  }
}

export const handleReportStart = (data) => {
  return dispatch => {
    dispatch(setReportStart(data))
  }
}

export const handleReportEnd = (data) => {
  return dispatch => {
    dispatch(setReportEnd(data))
  }
}

export const setAllData = allData => {
  return {
    type: GET_ALL_DATA,
    data: allData
  }
}

export const setCounterList = data => {
  return {
    type: SET_COUNTER_LIST,
    data: data
  }
}

export const setActiveList = data => {
  return {
    type: SET_ACTIVE_LIST,
    data: data
  }
}

export const setActiveCounter = id => {
  sessionStorage.setItem('counter', id)
  return {
    type: SET_ACTIVE_COUNTER,
    data: id
  }
}

export const setSelected = data => {
  return {
    type: SET_SELECTED,
    data: data
  }
}

export const setTransfer = data => {
  return {
    type: SET_TRANSFER,
    data: data
  }
}

export const setServing = data => {
  return {
    type: SET_SERVING,
    data: data
  }
}

export const setCardActive = data => {
  return {
    type: SET_CARD_ACTIVE,
    data: data
  }
}

export const setLoading = data => {
  return {
    type: SET_LOADING,
    data: data
  }
}

export const setDisplayDone = data => {
  return {
    type: SET_DISPLAY_DONE,
    data: data
  }
}

export const setDisplayParked = data => {
  return {
    type: SET_DISPLAY_PARKED,
    data: data
  }
}

export const setDisplayMissed = data => {
  return {
    type: DISPLAY_MISSED,
    data: data
  }
}

export const setDisplayMain = data => {
  return {
    type: DISPLAY_MAIN,
    data: data
  }
}

export const setCallTime = data => {
  return {
    type: SET_CALL_TIME,
    data: data
  }
}

export const setUpdated = data => {
  return {
    type: SET_UPDATED,
    data: data
  }
}

export const setTokenCounterMsg = data => {
  return {
    type: SET_TOKEN_COUNTER_MSG,
    data: data
  }
}

export const setNowServing = data => {
  return {
    type: SET_NOW_SERVING,
    data: data
  }
}

export const setAllParked = data => {
  return {
    type: SET_ALL_PARKED,
    data: data
  }
}

export const setNewParked = data => {
  return {
    type: SET_NEW_PARKED,
    data: data
  }
}

export const setDoneList = data => {
  return {
    type: SET_DONE_LIST,
    data: data
  }
}

export const setServiceRerport = data => {
  return {
    type: SET_SERVICE_REPORT,
    data: data
  }
}

export const setCounterReport = data => {
  return {
    type: SET_COUNTER_REPORT,
    data: data
  }
}

export const getTotalServed = data => {
  return {
    type: GET_TOTAL_SERVED,
    data: data
  }
}

export const serviceAvgTime = data => {
  return {
    type: GET_SERVICE_AVG_TIME,
    data: data
  }
}

export const getWaitingTime = data => {
  return {
    type: GET_WAITING_TIME,
    data: data
  }
}

export const setCounterTime = data => {
  return {
    type: GET_COUNTER_TIME,
    data: data
  }
}

export const setChecklists = data => {
  return {
    type: SET_CHECKLISTS,
    data: data
  }
}

export const setNewMissed = data => {
  return {
    type: SET_NEW_MISSED,
    data: data
  }
}

export const setAllMissed = data => {
  return {
    type: SET_ALL_MISSED,
    data: data
  }
}

export const setReportStart = data => {
  return {
    type: SET_REPORT_START,
    data: data
  }
}

export const setReportEnd = data => {
  return {
    type: SET_REPORT_END,
    data: data
  }
}

export const setTokenCounterError = errData => {
  return {
    type: SET_TOKEN_COUNTER_ERROR,
    data: errData
  }
}

export const checkCounter = () => {
  if (sessionStorage.getItem('counter')) {
    return {
      type: SET_ACTIVE_COUNTER,
      data: sessionStorage.getItem('counter')
    }
  } else {
    return {
      type: REMOVE_ACTIVE_COUNTER,
      data: null
    }
  }
}
