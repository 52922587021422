import React from 'react'

import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect
} from 'shards-react'

import { connect } from 'react-redux'

const BranchDropDown = props => {
  let { handleChange, branch } = props

  let branchList = branch ? branch.allBranches ? branch.allBranches : [] : []

  let handleBranchChange = event => {
    handleChange(event.target.value)
  }
  return (
    <div>
      <InputGroup className='mb-3'>
        <InputGroupAddon type='prepend'>
          <InputGroupText>Branches</InputGroupText>
        </InputGroupAddon>
        <FormSelect onChange={handleBranchChange}>
          <option>Select</option>
          {branchList.map((item, i) => (
            <option key={i} value={item._id} disabled={!item.status}>
              {item.branchName}
            </option>
          ))}
        </FormSelect>
      </InputGroup>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    branch: state.branch ? state.branch : null
  }
}

export default connect(
  mapStateToProps
)(BranchDropDown)
