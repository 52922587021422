import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1.5),
    minWidth: 300
  },
  cssFocused: {},
  cssLabel: {
    '&:after': {
      color: '#62BC74'
    }
  }
}))

const ServicePicker = props => {
  const classes = useStyles()
  let {
    services,
    handleChange,
    remoteService,
    serviceCounter,
    branchId
  } = props

  let serviceList = services ? services.services ? services.services : [] : []

  let branchServices = serviceCounter.filter(item => item.branchId === branchId)

  let activeServices = []

  let len = serviceList.length

  for (let i = 0; i < len; i++) {
    let find = branchServices.find(item => item.serviceId === serviceList[i]._id)
    if (find) {
      activeServices.push(serviceList[i])
    }
  }

  const [service, setService] = React.useState(
    remoteService ? remoteService : 'Select Service'
  )

  const handleServiceChange = event => {
    if (event.target.value !== 'Select Service') {
      setService(event.target.value)
      handleChange(event.target.value)
    } else {
      setService(event.target.value)
      handleChange('')
    }
  }

  // console.log('Active services', activeServices)

  return (
    <FormControl className='service-picker'>
      <InputLabel
        htmlFor='age-helper'
        formlabelclasses={{
          root: classes.cssLabel,
          focused: classes.cssFocused
        }}
      >

      </InputLabel>
      <Select
        labelid='demo-simple-select-label'
        id='demo-simple-select'
        value={service}
        variant='outlined'
        fullWidth
        onChange={handleServiceChange}
      >
        <MenuItem style={{ color: 'grey', fontSize: 14, fontWeight: 300, fontFamily: 'Poppins' }} value={'Select Service'}>Select Service</MenuItem>
        {Array.isArray(activeServices) &&
          activeServices.map((item, i) => {
            if (item._id) {
              return <MenuItem key={i} value={item._id}
                style={{ color: 'grey', fontSize: 14, fontWeight: 300, fontFamily: 'Poppins' }}>
                {item.serviceName}
              </MenuItem>
            }
          })}
      </Select>
    </FormControl>

  )
}

function mapStateToProps(state) {
  return {
    services: state.service ? state.service : null,
    serviceCounter: state.serviceCounter ?
      state.serviceCounter.serviceCounters ? state.serviceCounter.serviceCounters : []
      : []
  }
}

export default connect(
  mapStateToProps
)(ServicePicker)
