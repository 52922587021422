import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import TimerMixin from 'react-timer-mixin';

import { connect } from 'react-redux'

import { addFeedback } from '../../axiosLib/tokenActions'

import '../../assets/css/Feedback.css'

const useStyles = makeStyles(theme => ({
  note: {
    width: 250,
    borderTop: '0.5px solid #EAE5E5'
  },
  token: {
    width: 250
  }
}))

let customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

function Ratings(props) {
  const classes = useStyles()
  let { token, addFeedback } = props
  // let { feedbackSubmit, feedbackSuccess, feedbackError } = props

  let feedbackSuccess = token ?
    token.feedbackSuccess ? token.feedbackSuccess : '' : ''

  let feedbackError = token ?
    token.feedbackError ? token.feedbackError : '' : ''

  let [rating, setRating] = useState(0)
  let [tokenId, setToken] = useState('')
  let [note, setNote] = useState('')

  let handleToken = event => {
    setToken(event.target.value)
  }

  let handleRating = (event, value) => {
    setRating(value)
  }

  let handleNote = event => {
    setNote(event.target.value)
  }

  let submitFeedback = () => {
    let sendData = {
      token: tokenId,
      rating: rating,
      note: note
    }
    addFeedback(sendData)
    TimerMixin.setTimeout(() => {
      window.location.reload()
    }, 5000)
  }

  return (
    <div>
      <form
        noValidate
        autoComplete='off'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        id='rating'
      >
        <TextField
          placeholder='Token Number (Optional)'
          onChange={handleToken}
          value={tokenId}
          className={classes.token}
          underlineFocusStyle={{ borderColor: '#62BC74' }}
          margin='normal'
        />
        <Typography component="legend">Rate Our Service</Typography>
        <Rating
          name="customized-icons"
          value={rating}
          onChange={handleRating}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
        />
        <TextField
          id='standard-multiline-static'
          placeholder='Note'
          multiline
          rows='4'
          onChange={handleNote}
          value={note}
          className={classes.note}
          underlineFocusStyle={{ borderColor: '#62BC74' }}
          margin='normal'
        />
        <Button
          variant='outlined'
          color='primary'
          onClick={submitFeedback}
        >
          Submit Rating
        </Button>
        {feedbackSuccess.length ?
          <div
            style={{
              marginLeft: 8,
              color: 'green',
              fontSize: 15,
              fontWeight: 300,
            }}
          >
            {feedbackSuccess}
          </div> : ''
        }
        {feedbackError.msg ?
          <div
            style={{
              marginLeft: 8,
              color: 'red',
              fontSize: 15,
              fontWeight: 300,
            }}
          >
            {feedbackError.msg}
          </div> : ''
        }
      </form>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    token: state.token ? state.token : null
  }
}

export default connect(
  mapStateToProps,
  {
    addFeedback
  }
)(Ratings)