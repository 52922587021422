import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography'

export default function DeleteDialog(props) {
  let { open, onSubmit, deleteItem, mainItem, onClose, msg } = props

  let handleClose = () => {
    onClose(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {deleteItem} from {mainItem}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={() => onSubmit()} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
        {msg.length ? (
          <Typography
            variant='h5'
            style={{
              color: 'red',
              fontSize: 14
            }}
          >
            {msg}
          </Typography>
        ) : ''}
      </Dialog>
    </div>
  );
}
