/**  AUTH actions */
export const NEW_USER = "NEW_USER"
export const SET_USER = "SET_USER"
export const REMOVE_USER = "REMOVE_USER"
export const SET_TOKEN = "SET_TOKEN"
export const REMOVE_TOKEN = "REMOVE_TOKEN"
export const SET_ROLE = "SET_ROLE"
export const REMOVE_ROLE = "REMOVE_ROLE"
export const SET_SUCCESS_MSG = "SET_SUCCESS_MSG"
export const SET_ERR_MSG = "SET_ERR_MSG"
export const SET_PASSWORD_MSG = "SET_PASSWORD_MSG"
export const SET_ALL_OPERATORS = "SET_ALL_OPERATORS"
export const SET_ADMIN_TAB = "SET_ADMIN_TAB"
export const SET_ALL_USERS = "SET_ALL_USERS"

/** Counter actions */
export const SET_ALL_COUNTERS = "SET_ALL_COUNTERS"
export const ADD_NEW_COUNTER = "ADD_NEW_COUNTER"
export const SET_COUNTER_ERROR = "SET_COUNTER_ERROR"
export const UPDATE_COUNTER = "UPDATE_COUNTER"

/** Service Counter Actions */
export const SET_ALL_SERVICE_COUNTERS = "SET_ALL_SERVICE_COUNTERS"
export const SET_SERVICE_COUNTER_ERROR = "SET_SERVICE_COUNTER_ERROR"

/** Service actions */
export const SET_ALL_SERVICES = "SET_ALL_SERVICES"
export const ADD_NEW_SERVICE = "ADD_NEW_SERVICE"
export const UPDATE_SERVICE = "UPDATE_SERVICE"
export const SET_SERVICE_ERROR = "SET_SERVICE_ERROR"

/** TOKEN actions */
export const SET_USER_TOKEN = "SET_USER_TOKEN"
export const SET_NEW_TOKEN = "SET_NEW_TOKEN"
export const USE_OLD_TOKEN = "USE_OLD_TOKEN"
export const SET_TOKEN_ERRORS = "SET_TOKEN_ERRORS"
export const SHOW_REGISTRATION = "SHOW_REGISTRATION"
export const SHOW_TEXTFIELDS = "SHOW_TEXTFIELDS"
export const SHOW_ENTRY = "SHOW_ENTRY"
export const DROP_TOKEN = "DROP_TOKEN"
export const GET_EXISTING_TOKEN = "GET_EXISTING_TOKEN"
export const DELETE_FIELD = "DELETE_FIELD"
export const SET_MSG = "SET_MSG"
export const SET_CLICKED = "SET_CLICKED"
export const SET_REQUEUE = "SET_REQUEUE"
export const SET_REQUEUE_ID = "SET_REQUEUE_ID"
export const SET_FEEDBACK_ERROR = "SET_FEEDBACK_ERROR"
export const SET_FEEDBACK_SUCCESS = "SET_FEEDBACK_SUCCESS"
export const GET_TIME_LIST = "GET_TIME_LIST"
export const GET_SERVICE_TIME = "GET_SERVICE_TIME"

/** TokenCounter Actions */
export const SET_COUNTER_LIST = "SET_COUNTER_LIST"
export const GET_ALL_DATA = "GET_ALL_DATA"
export const SET_ACTIVE_COUNTER = "SET_ACTIVE_COUNTER"
export const REMOVE_ACTIVE_COUNTER = "REMOVE_ACTIVE_COUNTER"
export const SET_ACTIVE_LIST = "SET_ACTIVE_LIST"
export const SET_CALL_TIME = "SET_CALL_TIME"
export const SET_CARD_ACTIVE = "SET_CARD_ACTIVE"
export const SET_UPDATED = "SET_UPDATED"
export const SET_SELECTED = "SET_SELECTED"
export const SET_SERVING = "SET_SERVING"
export const SET_TRANSFER = "SET_TRANSFER"
export const SET_WALKIN = "SET_WALKIN"
export const SET_DISPLAY_DONE = "SET_DISPLAY_DONE"
export const SET_DISPLAY_PARKED = "SET_DISPLAY_PARKED"
export const SET_WALKIN_CARD = "SET_WALKIN_CARD"
export const SET_ALL_PARKED = "SET_ALL_PARKED"
export const GET_TOTAL_SERVED = "GET_TOTAL_SERVED"
export const GET_SERVICE_AVG_TIME = "GET_SERVICE_AVG_TIME"
export const GET_WAITING_TIME = "GET_WAITING_TIME"
export const GET_COUNTER_TIME = "GET_COUNTER_TIME"
export const SET_NEW_PARKED = "SET_NEW_PARKED"
export const SET_TOKEN_COUNTER_ERROR = "SET_TOKEN_COUNTER_ERROR"
export const SET_SERVICE_REPORT = "SET_SERVICE_REPORT"
export const SET_COUNTER_REPORT = "SET_COUNTER_REPORT"
export const SET_CHECKLISTS = "SET_CHECKLISTS"
export const SET_TOKEN_COUNTER_MSG = "SET_TOKEN_COUNTER_MSG"
export const SET_NEW_MISSED = "SET_NEW_MISSED"
export const SET_ALL_MISSED = "SET_ALL_MISSED"
export const DISPLAY_MISSED = "DISPLAY_MISSED"
export const SET_REPORT_START = "SET_REPORT_START"
export const SET_REPORT_END = "SET_REPORT_END"
export const SET_NOW_SERVING = "SET_NOW_SERVING"
export const SET_LOADING = "SET_LOADING"
export const SET_DONE_LIST = "SET_DONE_LIST"
export const DISPLAY_MAIN = "DISPLAY_MAIN"

/** USER COUNTER */
export const ADD_USER_COUNTER = "ADD_USER_COUNTER"
export const REMOVE_USER_COUNTER = "REMOVE_USER_COUNTER"
export const GET_ALL_USER_COUNTER = "GET_ALL_USER_COUNTER"
export const SET_USER_COUNTER_ERROR = "SET_USER_COUNTER_ERROR"

/** BRANCH ACTIONS */
export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES"
export const SET_BRANCH_ERROR = "SET_BRANCH_ERROR"
