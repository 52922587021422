import React from 'react'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import socketIOClient from 'socket.io-client'
import CallNextIcon from '@material-ui/icons/SlowMotionVideoOutlined'
import WalkInIcon from '@material-ui/icons/AssignmentReturnedOutlined'

import { connect } from 'react-redux'

import {
  getCheckLists,
  displayTokenDetails,
  callNext,
  displayToken
} from '../../axiosLib/tokenCounterActions'
import { sendSMS } from '../../axiosLib/tokenActions'

import '../../assets/css/StartServing.css'
import {serveruri} from "../../axiosLib/config";

function isEmpty(obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false
    }
  }
}

let socket = socketIOClient(serveruri)

const StartServing = props => {
  let {
    getCheckLists,
    displayTokenDetails,
    callNext,
    tokenCounter,
    sendSMS,
    user
  } = props
  let activeList = tokenCounter ? tokenCounter.activeList : null
  let activeCard = activeList ? activeList.cards ? activeList.cards[0] : null : null
  let checkInput = {
    serviceId: activeCard ? activeCard.serviceId : ''
  }

  let onNextClick = () => {
    callNext()
    let details = {
      counter: activeList.title,
      qId: activeCard ? activeCard.qId : ''
    }
    displayTokenDetails(details)

    let data = {
      activeList: activeList,
      activeCard: activeCard,
      branchId: user?.user?.branchId
    }

    socket.emit('callActiveToken', data)

    // displayToken(details, (err, data) => {
    //   if (!err) {
    //     console.log('Error', err)
    //   } else {
    //     console.log('Data', data)
    //   }
    // })
    getCheckLists(checkInput)
    if (activeCard) {
      if (activeCard.phone) {
        let smsData = {
          phone: '+88' + activeCard.phone,
          sms: `Token ${activeCard.qId} is available for service in ${activeList.title}`
        }
        sendSMS(smsData)
      }
    }
  }
  // console.log('Active Card', activeCard)
  return (
    <Card className='start-serving' style={{ height: window.innerHeight - 118 }}>
      <div>
        <header style={{ backgroundColor: '#EAE5E5', height: 68 }}>
          <Typography
            style={{ paddingTop: 20, textAlign: 'left', marginLeft: 16, color: '#000000', fontFamily: 'Poppins', fontSize: 18 }}
          >
            Start Serving
        </Typography>
        </header>
        <div>
          <div className='container' style={{ height: window.innerHeight - 235 }}>
            <p className='p1'>CLICK ON</p>
            <p className='p2'>WALK IN/CALL NEXT</p>
            <p className='p3'>TO START SERVING</p>
          </div>
          {activeCard ? (
            <div className='action-btn'>
              <div
                className='button'
                style={{ backgroundColor: '#E5E5E5', textAlign: 'center' }}
                onClick={() => alert('Walk-In is under construction')}
              >
                <WalkInIcon />
                <p style={{ marginTop: '2.3px', marginLeft: '4px', fontFamily: 'Poppins' }}>WALK IN</p>
              </div>
              <div
                className='button'
                style={{ backgroundColor: '#38F8C4', textAlign: 'center', fontFamily: 'Poppins' }}
                onClick={onNextClick}
              >
                <CallNextIcon />
                <p style={{ marginTop: '2.3px', marginLeft: '4px', fontFamily: 'Poppins' }}>CALL NEXT</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Card>
  )
}

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null,
    user: state.user ? state.user : null
  }
}

export default connect(
  mapStateToProps,
  {
    getCheckLists,
    displayTokenDetails,
    callNext,
    sendSMS
  }
)(StartServing)
