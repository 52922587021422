import React from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert,
  FormInput,
  FormGroup,
  Button
} from 'shards-react'

import ServiceList from './ServiceList'
import ServiceCheckList from './ServiceCheckList'

import { connect } from 'react-redux'
import { newServiceAdd, getAllServices } from '../../axiosLib/serviceActions'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const ServiceCreate = props => {
  let { newServiceAdd, getAllServices } = props
  let [serviceName, setName] = React.useState('')
  let [prefix, setPrefix] = React.useState('')
  let [weight, setWeight] = React.useState(1)
  let handleName = event => {
    setName(event.target.value)
  }
  let handlePrefix = event => {
    setPrefix(event.target.value)
  }
  let handleWeight = event => {
    setWeight(event.target.value)
  }
  let onSubmit = () => {
    let data = {
      serviceName: serviceName,
      servicePrefix: prefix,
      servicePriorityWeight: weight
    }
    newServiceAdd(data)
      .then(res => {
        socket.emit('addService', res.data)
        socket.emit('notify', 'New Service Added')
        getAllServices()
        setName('')
        setPrefix('')
        setWeight(1)
      })
  }
  return (
    <>
      <Row>
        <Col lg='6' className='mb-4'>
          <Card small className='mb-4'>
            <CardHeader className='border-bottom'>
              <h6 className='m-0'>Add New Service</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className='p-3'>
                <Row>
                  <Col>
                    <Form>
                      <FormGroup>
                        <label>Service Name</label>
                        <FormInput
                          value={serviceName}
                          onChange={handleName}
                          placeholder='Service Name'
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Service Prefix</label>
                        <FormInput
                          value={prefix}
                          onChange={handlePrefix}
                          placeholder='Ex. PS'
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Service Priority Weight</label>
                        <FormInput
                          value={weight}
                          onChange={handleWeight}
                          placeholder='1/2/3...'
                        />
                      </FormGroup>
                      <Button onClick={onSubmit}>Add</Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
        <ServiceCheckList />
        <ServiceList />
      </Row>
    </>
  )
}


function mapStateToProps(state) {
  return {
    services: state.service ? state.service : null
  }
}

export default connect(
  mapStateToProps,
  { newServiceAdd, getAllServices }
)(ServiceCreate)
