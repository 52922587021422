import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import LogOut from '@material-ui/icons/ExitToApp'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { sidebar } from '../Admin/sidebar/sidebarNav'
import UserProfile from '../Admin/view/UserProfile'
import EditRole from '../Admin/view/EditRole'
import CounterMain from '../CounterList/CounterMain'
import ServiceMain from '../Service/ServiceMain'
import CounterServiceMain from '../CounterService/CounterServiceMain'
import TokenMain from '../TokenLists/TokenMain'
import ReportMain from '../Reports/ReportMain'
import MainReportTab from '../FullReports/MainReportTab'
import BranchMain from '../Branch/BranchMain'
import CircularStatic from './CircularStatic'
import '../../assets/css/Admin.css'
import Alert from '@material-ui/lab/Alert';
import TimerMixin from 'react-timer-mixin'
import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const drawerWidth = 270

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}))

function AdminBoard(props) {
  let { container, user, logOut, activeTab, handleActiveTab, loading } = props
  const classes = useStyles()
  const theme = useTheme()

  const [mobileOpen, setMobileOpen] = useState(false)
  let [selected, setSelected] = useState(activeTab)
  let [notification, setNotification] = useState('')

  let handleSelected = value => {
    setSelected(value)
    handleActiveTab(value)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }


  useEffect(() => {
    socket.on('notify', data => {
      setNotification(data)
      TimerMixin.setTimeout(() => {
        setNotification('')
      }, 5000)
    })
  })

  // console.log('Loading', loading)

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      {/* <Divider /> */}
      <List className='sidebars'>
        {sidebar.map((item, index) => (
          <ListItem
            button
            key={index}
            selected={selected === index}
            onClick={() => handleSelected(index)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#ecf8f8'

          }}
        >
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap style={{ color: 'grey', fontFamily: 'Poppins' }}>
            Admin Panel
          </Typography>
          <div className='sign-out'>
            <Typography style={{ textAlign: 'center', color: 'grey', fontFamily: 'Poppins', cursor: 'pointer', marginRight: 16 }}>{user.name}</Typography>
            <Tooltip title='Log out' placement='top'>
              <LogOut onClick={logOut} />
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label='mailbox folders'>
        <Hidden smUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {notification.length ? (
          <div
            style={{
              width: '50%',
              marginLeft: '50%',
              zIndex: 100,
              position: 'fixed'
            }}
          >
            <Alert severity="success">{notification}</Alert>
          </div>
        ) : null}
        {selected === 0 ? <UserProfile user={user} /> : null}
        {selected === 1 ? <BranchMain /> : null}
        {selected === 2 ? <EditRole /> : null}
        {selected === 3 ? <ServiceMain /> : null}
        {selected === 4 ? <CounterMain /> : null}
        {selected === 5 ? <CounterServiceMain /> : null}
        {selected === 6 ? <TokenMain /> : null}
        {selected === 7 ? <ReportMain /> : null}
        {selected === 8 ? <MainReportTab /> : null}
        {loading ? <CircularStatic /> : ''}
      </main>
    </div>
  )
}

AdminBoard.propTypes = {
  container: PropTypes.instanceOf(
    typeof Element === 'undefined' ? Object : Element
  )
}

export default AdminBoard
