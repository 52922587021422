import React from 'react'

import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect
} from 'shards-react'

import { connect } from 'react-redux'

const ServiceList = props => {
  let { handleChange, services } = props
  let serviceList = services ? services.services ? services.services : [] : []
  let handleServiceChange = event => {
    handleChange(event.target.value)
  }
  return (
    <div>
      <InputGroup className='mb-3'>
        <InputGroupAddon type='prepend'>
          <InputGroupText>Services</InputGroupText>
        </InputGroupAddon>
        <FormSelect onChange={handleServiceChange}>
          <option>Select</option>
          {serviceList.map((item, i) => (
            <option key={i} value={item._id}>
              {item.serviceName}
            </option>
          ))}
        </FormSelect>
      </InputGroup>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    services: state.service ? state.service : null
  }
}

export default connect(mapStateToProps)(ServiceList)
