import React, { useState } from "react";
import { Col, Card, CardHeader, CardBody } from "shards-react";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import "../../../assets/css/ServiceList.css";

import DeleteDialog from "../../Dialog/DeleteDialog";

import TimerMixin from "react-timer-mixin";
import socketIOClient from "socket.io-client";
import { serveruri } from "../../../axiosLib/config";

let socket = socketIOClient(serveruri);

let getBranchName = (id, list) => {
  if (!list.length) {
    return "-";
  } else {
    let branch = list.find((item) => item._id === id);

    if (branch) {
      return branch.branchName;
    } else {
      return "-";
    }
  }
};

const UserCounterTable = (props) => {
  let { userCounter, removeUser, branchList } = props;

  let [open, setOpen] = useState(false);
  let [msg, setMsg] = useState("");
  let [counterId, setCounterId] = useState("");
  let [userId, setUserId] = useState("");
  let [counterName, setCounterName] = useState("");
  let [userName, setUserName] = useState("");

  let handleOpen = (item, userId, user) => {
    setCounterId(item.counterId);
    setUserId(userId);
    setCounterName(item.counterTitle);
    setUserName(user);
    setOpen(true);
  };

  let handleSubmit = () => {
    let sendData = {
      counterId: counterId,
      userId: userId,
    };
    removeUser(sendData)
      .then((res) => {
        if (res) {
          socket.emit("notify", "User removed from counter");
          setOpen(false);
        }
      })
      .catch((err) => {
        setMsg("Error occured. Try later");
        TimerMixin.setTimeout(() => {
          setMsg("");
          setOpen(false);
        }, 5000);
      });
  };

  return (
    <>
      <Col lg="7" className="mb-4">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">User-Counter List</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th
                    scope="col"
                    style={{ textAlign: "center" }}
                    className="border-0"
                  >
                    Counter Name
                  </th>
                  <th
                    scope="col"
                    style={{ textAlign: "center" }}
                    className="border-0"
                  >
                    Branch Name
                  </th>
                  <th
                    scope="col"
                    style={{ textAlign: "center" }}
                    className="border-0"
                  >
                    Operators
                  </th>
                </tr>
              </thead>
              <tbody>
                {userCounter.map((item, key) => (
                  <tr key={key}>
                    <td style={{ textAlign: "center" }}>{item.counterTitle}</td>
                    <td style={{ textAlign: "center" }}>
                      {getBranchName(item.branchId, branchList)}
                    </td>
                    <td
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {item.users.map((user, key) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginRight: 5,
                            marginBottom: 4,
                          }}
                        >
                          <div>{user.name}</div>
                          <div
                            style={{ position: "absolute", right: 24 }}
                            onClick={() => handleOpen(item, user.id, user.name)}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
      <DeleteDialog
        open={open}
        onClose={setOpen}
        onSubmit={handleSubmit}
        deleteItem={userName}
        mainItem={counterName}
        msg={msg}
      />
    </>
  );
};

export default UserCounterTable;
