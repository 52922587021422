import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button
} from 'shards-react'

import { connect } from 'react-redux'
import { onEditName, onPasswordChange } from '../../../axiosLib/authActions'

import '../../../assets/css/Admin.css'

const UserAccountDetails = (props) => {
  let {
    title,
    user,
    onEditName,
    onPasswordChange
  } = props

  let userDetails = user ? user.user ? user.user : {} : {}
  let passwordSuccess = user ? user.passwordSuccess ? user.passwordSuccess : '' : ''

  let [name, setName] = useState(userDetails.name ? userDetails.name : '')
  let [password, setPassword] = useState('')
  let [confirm, setConfirm] = useState('')

  let [passwordErr, setErr] = useState('')

  let handleName = (event) => {
    setName(event.target.value)
  }

  let handlePassword = (event) => {
    setPassword(event.target.value)
  }

  let confirmPassword = event => {
    setConfirm(event.target.value)
  }

  let handleSubmit = () => {
    if (password.length && confirm.length) {
      if (password !== confirm) {
        setErr("Passwords haven't matched")
      } else {
        let sendData = { password: password }
        onPasswordChange(sendData)
        setErr('')
      }
    }

    if (name.length) {
      let sendData = { name: name }
      onEditName(sendData)
    }

  }

  return (
    <Card small className='mb-4'>
      <CardHeader className='border-bottom'>
        <h6 className='m-0'>{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className='p-3'>
          <Row>
            <Col>
              <Form>
                <Row form>
                  {/* First Name */}
                  <Col md='9' className='form-group'>
                    <label htmlFor='feFirstName'>Name</label>
                    <FormInput
                      id='feFirstName'
                      placeholder='Name'
                      onChange={handleName}
                      value={name}
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Email */}
                  <Col md='9' className='form-group'>
                    <label htmlFor='feEmail'>Email</label>
                    <FormInput
                      type='email'
                      id='feEmail'
                      disabled
                      value={userDetails.email ? userDetails.email : ''}
                      autoComplete='email'
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md='6' className='form-group'>
                    <label htmlFor='fePassword'>New Password</label>
                    <FormInput
                      type='password'
                      id='fePassword'
                      placeholder='New Password'
                      onChange={handlePassword}
                      value={password}
                      autoComplete='current-password'
                    />
                  </Col>
                  <Col md='6' className='form-group'>
                    <label htmlFor='fePassword'>Confirm Password</label>
                    <FormInput
                      type='password'
                      id='fePassword'
                      placeholder='Confirm Password'
                      onChange={confirmPassword}
                      value={confirm}
                      autoComplete='current-password'
                    />
                  </Col>
                  {passwordErr.length ?
                    <div
                      style={{
                        marginLeft: 8,
                        color: 'red',
                        fontSize: 15,
                        fontWeight: 300,
                      }}
                    >
                      {passwordErr}
                    </div>
                    : ''
                  }
                  {passwordSuccess.length ?
                    <div
                      style={{
                        marginLeft: 8,
                        color: 'Green',
                        fontSize: 15,
                        fontWeight: 300,
                      }}
                    >
                      {passwordSuccess}
                    </div>
                    : ''
                  }
                </Row>
                <Button
                  theme='accent'
                  onClick={handleSubmit}
                >
                  Update Account
                </Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
}

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
}

UserAccountDetails.defaultProps = {
  title: 'Account Details'
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : null
  }
}

export default connect(
  mapStateToProps,
  { onEditName, onPasswordChange }
)(UserAccountDetails)
