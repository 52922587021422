import React from 'react'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import ServicePicker from './ServicePicker'
import { reAddToken } from '../../axiosLib/tokenActions'
import '../../assets/css/Kiosk.css'

import socketIOClient from 'socket.io-client'
import { serveruri, branchId } from '../../axiosLib/config'
let socket = socketIOClient(serveruri)

const OldToken = props => {
  let { token, reAddToken, user } = props

  let errors = token ? token.errors ? token.errors : {} : {}
  let msg = token ? token.msg ? token.msg : '' : ''
  let clicked = token ? token.clicked ? token.clicked : false : false

  let [tokenId, setToken] = React.useState('')
  let [service, setService] = React.useState(undefined)

  let onTokenChange = event => {
    setToken(event.target.value)
  }

  let onServiceChange = value => {
    setService(value)
  }

  let onSubmit = (event) => {
    event.preventDefault()

    let data = {
      token: tokenId,
      tokenService: service,
      branchId: branchId
    }
    reAddToken(data)
      .then(res => {
        socket.emit('reQueue', '')
      })
  }

  return (
    <form className='container' noValidate autoComplete='off' onSubmit={onSubmit}>
      <TextField
        label='Type Phone/Token Number'
        value={tokenId}
        className='text_field'
        style={{ minWidth: 300 }}
        onChange={onTokenChange}
        variant='outlined'
        margin='normal'
        required
        fullWidth
      />



      <ServicePicker handleChange={onServiceChange} />

      <Button
        fullWidth
        variant='contained'
        color='primary'
        value='Submit'
        type='submit'
        disabled={clicked}
        style={{ marginTop: 18, color: '#ffffff' }}
      >
        Submit
      </Button>
      <div style={{ marginBottom: 5 }} />
      {msg.length ?
        <Typography style={{ color: 'green' }}>
          {msg}
        </Typography>
        : errors.msg ?
          <Typography style={{ color: 'red' }}>
            {errors.msg}
          </Typography> : ''
      }
    </form>
  )
}

function mapStateToProps(state) {
  return {
    token: state.token ? state.token : null
  }
}

export default connect(
  mapStateToProps,
  { reAddToken }
)(OldToken)
