import React from 'react';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";

function Scanner(props) {
  let { setToken } = props
  return (
    <BarcodeScannerComponent
      width={200}
      height={200}
      onUpdate={(err, result) => {
        if (result) {
          setToken(result.text)
        }
        else {
          setToken('')
        }
      }}
    />
  )
}

export default Scanner;
