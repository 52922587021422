import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Shadow from "../assets/image/leadership.png";
import Grid from "@material-ui/core/Grid";
import logoShadow from "../assets/image/demo-logo.png";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ResponsiveDialog from "./ResponsiveDialog";

import { connect } from "react-redux";
import { onSignup } from "../axiosLib/authActions";

import "../assets/css/login.css";

import socketIOClient from "socket.io-client";
import { serveruri } from "../axiosLib/config";

let socket = socketIOClient(serveruri);

const useStyles = makeStyles((theme) => ({
  root: {
    width: 576,
    minWidth: 275,
    padding: 48,
    left: 200,
    top: 200,
    position: "absolute",
    backgroundColor: "rgb(250,255,255)",
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    left: 205,
    position: "absolute",
    height: 40,
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    height: 48,
    backgroundColor: "cornflowerblue",
    fontFamily: "Poppins",
  },
}));

const SignUp = (props) => {
  const classes = useStyles();
  let { onSignup, user } = props;

  let error = user ? (user.error ? user.error : null) : null;
  let success = user ? (user.success ? user.success : null) : null;

  let [name, setName] = React.useState("");
  let [email, setEmail] = React.useState("");
  let [userName, setUserName] = React.useState("");
  let [phone, setPhone] = React.useState("");
  let [password, setPass] = React.useState("");
  let [open, setOpen] = React.useState(false);

  let handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let handleName = (event) => {
    setName(event.target.value);
  };
  let handleEmail = (event) => {
    setEmail(event.target.value);
  };
  let handlePhone = (event) => {
    setPhone(event.target.value);
  };
  let handleUserName = (event) => {
    setUserName(event.target.value);
  };
  let handlePass = (event) => {
    setPass(event.target.value);
  };

  let onSubmit = () => {
    let signUpData = {
      name: name,
      phone: phone,
      email: email,
      userName: userName,
      password: password,
    };
    onSignup(signUpData)
      .then((res) => {
        if (res) {
          setOpen(true);
          socket.emit("onSignup", signUpData);
          handleClickOpen();
          setName("");
          setEmail("");
          setUserName("");
          setPhone("");
          setPass("");
        } else {
          setPass("");
        }
      })
      .catch((err) => {
        setPass("");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="body">
        <div className="top-text">
          {/* <img className='avatar' alt='Welcome To QQ app' src={logoShadow} /> */}
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className="sign-text pointer">
              Sign in
              <ArrowRightAltIcon />{" "}
            </div>
          </Link>
        </div>
        <Card className="cardview">
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>

          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="name"
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="Name"
                  autoFocus
                  value={name}
                  onChange={handleName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="role"
                  label="User Name"
                  name="role"
                  value={userName}
                  onChange={handleUserName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  type="number"
                  fullWidth
                  id="email"
                  label="Phone Number"
                  name="number"
                  autoComplete="number"
                  value={phone}
                  onChange={handlePhone}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={email}
                  onChange={handleEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={handlePass}
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmit}
            >
              Register <ArrowRightAltIcon style={{ marginLeft: 18 }} />
            </Button>
            {error ? (
              error.length ? (
                <Typography
                  style={{
                    color: "red",
                    fontSize: 16,
                    fontFamily: "Poppins",
                  }}
                >
                  *{error}
                </Typography>
              ) : (
                " "
              )
            ) : (
              ""
            )}
            {success ? (
              success.length ? (
                <Typography
                  style={{
                    color: "green",
                    fontSize: 16,
                    fontFamily: "Poppins",
                  }}
                >
                  {success}
                </Typography>
              ) : (
                " "
              )
            ) : (
              ""
            )}
          </form>
          <ResponsiveDialog isOpen={open} onClose={handleClose} />
        </Card>

        <img
          src={Shadow}
          alt="Welcome to QQ App"
          variant="square"
          className="images"
        />
      </div>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : null,
  };
}

export default connect(mapStateToProps, { onSignup })(SignUp);
