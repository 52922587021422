import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/SearchOutlined'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import moment from 'moment'
import { Row, Col, Card, CardHeader, ListGroup, ListGroupItem } from 'shards-react'
import { getCounterTime } from '../../axiosLib/tokenCounterActions'
import '../../assets/css/ServiceSearch.css'
import CounterReport from './CounterReport'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: 170,
    marginTop: 8,
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button: {
    color: '#62BC74',
    width: 52,
    height: 52,
    marginTop: 8
  }
}))

const CounterSearch = props => {
  const classes = useStyles()
  let { counter, getCounterTime } = props

  let counterDropDown = counter ? counter.counterList ? counter.counterList : [] : []

  const [counterId, setCounter] = React.useState('Select Counter')
  const [start, setStart] = React.useState(new Date())
  const [end, setEnd] = React.useState(new Date())

  const handleChange = event => {
    setCounter(event.target.value)
  }
  const handleStart = date => {
    setStart(new Date(date))
  }
  const handleEnd = date => {
    setEnd(new Date(date))
  }

  const onSubmit = () => {
    let modifiedStart = moment(start).format()
    let modifiedEnd = moment(end).format()
    let formattedStart = modifiedStart.slice(0, 10)
    let finalStart = formattedStart + "T00:00:00+06:00"
    let formattedEnd = modifiedEnd.slice(0, 10)
    let finalEnd = formattedEnd + "T23:59:59+06:00"
    let inputData = {
      counterId: counterId,
      start: finalStart,
      end: finalEnd
    }
    if (counterId.length) {
      getCounterTime(inputData)
    } else {
      alert('Please select a counter from list')
    }
    // console.log('InputData', inputData)
  }

  return (
    <Col lg='9' className='mb-4'>
      <Card small className='mb-4'>
        <CardHeader className='border-bottom'>
          <h6 className='m-0'>Get Counter Reports</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className='p-3'>
            <Row style={{ marginLeft: 6 }}>
              <Col className='mb-4'>
                <FormControl className={classes.formControl}>
                  <Select
                    style={{
                      color: '#000000',
                      fontSize: 14,
                      fontWeight: 300,
                      fontFamily: 'Poppins',
                    }}
                    labelid='demo-simple-select-label'
                    id='demo-simple-select'
                    variant='outlined'
                    fullWidth
                    value={counterId}
                    onChange={handleChange}
                  >
                    <MenuItem style={{ color: 'grey', fontSize: 14, fontWeight: 300, fontFamily: 'Poppins' }} value={'Select Counter'}>Select Counter</MenuItem>
                    {Array.isArray(counterDropDown) &&
                      counterDropDown.map((item, i) => (
                        <MenuItem key={i} value={item._id}
                          style={{ color: 'grey', fontSize: 14, fontWeight: 300, fontFamily: 'Poppins' }}
                        >
                          {item.counterTitle}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>
              <Col className='mb-4'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    style={{
                      marginTop: 8,
                      width: 170,
                      color: '#000000',
                      fontWeight: 300,
                      fontFamily: 'Poppins'
                    }}
                    inputVariant='outlined'
                    format='dd/MM/yyyy'
                    margin='normal'
                    id='date-picker-inline-dialog'
                    label='Start Date'
                    value={start}
                    onChange={handleStart}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Col>

              <Col className='mb-4'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    autoOk={true}
                    style={{
                      width: 170,
                      marginTop: 8,
                      color: '#000000',
                      fontWeight: 300,
                      fontFamily: 'Poppins'
                    }}
                    inputVariant='outlined'
                    format='dd/MM/yyyy'
                    margin='normal'
                    id='date-picker-inline-dialog'
                    label='End Date'
                    value={new Date(start) > new Date(end) ? start : end}
                    minDate={start}
                    onChange={handleEnd}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Col>
              <Col className='mb-4'>
                <Button
                  onClick={onSubmit}
                  variant='outlined'
                  className={classes.button}
                >
                  <SearchIcon />
                </Button>
              </Col>
            </Row>
            <Row>
              <CounterReport />
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    </Col>
  )
}

function mapStateToProps(state) {
  return {
    counter: state.counter ? state.counter : null
  }
}

export default connect(
  mapStateToProps,
  { getCounterTime }
)(CounterSearch)
