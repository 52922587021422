import React from 'react'
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect
} from 'shards-react'

const StatusDropDown = props => {
  let { handleStatusChange } = props
  let handleChange = event => {
    handleStatusChange(event.target.value)
  }
  // console.log(value, '====vaalue===')
  return (
    <div>
      <InputGroup className='mb-3'>
        <InputGroupAddon type='prepend'>
          <InputGroupText>Status</InputGroupText>
        </InputGroupAddon>
        <FormSelect onChange={handleChange}>
          <option>Select</option>
          <option value={true}>True</option>
          <option value={false}>False</option>
        </FormSelect>
      </InputGroup>
    </div>
  )
}

export default StatusDropDown
