import 'date-fns'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import TimePicker from './TimePicker'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

const DatePicker = props => {
  let { handleDate, handleTime, service } = props

  let [selectedDate, setSelectedDate] = React.useState(new Date())

  let handleDateChange = date => {
    setSelectedDate(date)
    handleDate(date)
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify='space-around'>
        <KeyboardDatePicker
          disableToolbar
          autoOk={true}
          fullWidth
          style={{marginTop: 8}}
          inputVariant='outlined'
          format='MM/dd/yyyy'
          margin='normal'
          id='date-picker-inline-dialog'
          label='Select Date'
          value={selectedDate}
          minDate={new Date()}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </Grid>
      <Grid container justify='space-around'>
        <TimePicker
          date={selectedDate}
          handleTime={handleTime}
          service={service}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
