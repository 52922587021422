import {
  SET_ALL_SERVICES,
  ADD_NEW_SERVICE,
  SET_SERVICE_ERROR,
  UPDATE_SERVICE
} from '../constants'

import axios from 'axios'
import { serveruri } from './config'

export const getAllServices = () => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      let allServices = await axios.get(`${serveruri}/api/all-services`)

      if (allServices.status === 200) {
        response.status = 200
        response.data = allServices.data
        dispatch(setAllServices(allServices.data))
        response.message = 'All services fetched'
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setServiceError(err.response.data.msg))
        return
      }
    }
  }
}

export const newServiceAdd = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let addNew = await axios.post(`${serveruri}/api/add-new-service`, inputData)

      if (addNew.status === 200) {
        let allServices = await axios.get(`${serveruri}/api/all-services`)

        if (allServices.status === 200) {
          response.status = 200
          response.data = allServices.data
          dispatch(setAllServices(allServices.data))
          response.message = 'All services fetched'
        }
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setServiceError(err.response.data.msg))
        return
      }
    }
  }
}

export const serviceWeightUpdate = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let update = await axios.post(`${serveruri}/api/update-service-weight`, inputData)

      if (update.status === 200) {
        response.status = 200
        response.token = token
        response.data = update.data
        dispatch(setUpdate(update.data))
        response.message = 'Service updated'
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setServiceError(err.response.data.msg))
        return
      }
    }
  }
}

export const addCheckListToService = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let checklist = await axios.post(`${serveruri}/api/add-checklist`, inputData)

      if (checklist.status === 200) {
        response.status = 200
        response.token = token
        response.data = checklist.data
        response.message = 'Checklist added'
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setServiceError(err.response.data.msg))
        return
      }
    }
  }
}

export const setServiceError = errData => {
  return {
    type: SET_SERVICE_ERROR,
    data: errData
  }
}

export const setAllServices = data => {
  return {
    type: SET_ALL_SERVICES,
    data: data
  }
}

export const setNewService = data => {
  return {
    type: ADD_NEW_SERVICE,
    data: data
  }
}

export const setUpdate = data => {
  return {
    type: UPDATE_SERVICE,
    data: data
  }
}