import axios from 'axios'
import {
  SET_USER,
  REMOVE_USER,
  SET_ROLE,
  SET_TOKEN,
  NEW_USER,
  REMOVE_ROLE,
  REMOVE_TOKEN,
  SET_SUCCESS_MSG,
  SET_ERR_MSG,
  SET_PASSWORD_MSG,
  SET_ALL_OPERATORS,
  SET_ADMIN_TAB,
  SET_ALL_USERS
} from '../constants'
import { serveruri } from './config'

import TimerMixin from 'react-timer-mixin'

export const onSignup = (signupData) => {
  return dispatch => {
    return axios
      .post(`${serveruri}/api/register`, signupData)
      .then(res => {
        dispatch(setNewUser(res.data))
        dispatch(setSuccessMsg('User is registered. Please contact to the Admin to verify.'))
      })
      .catch(err => {
        if (err.response) {
          dispatch(setError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setError(''))
          }, 5000)
        }
      })
  }
}

export const onLogin = (loginData) => {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      let userLogin = await axios.post(`${serveruri}/api/login`, loginData)

      if (userLogin.status === 200) {
        sessionStorage.setItem('userToken', userLogin.data.token)
        sessionStorage.setItem('user', JSON.stringify(userLogin.data.user))
        response.status = 200
        response.token = userLogin.data.token
        response.data = userLogin.data.user
        dispatch(setUser(userLogin.data.user))
        dispatch(setToken(userLogin.data.token))

        let query = { userId: userLogin.data.user.id }

        let userRole = await axios.post(`${serveruri}/api/get-role`, query)

        if (userRole) {
          sessionStorage.setItem('userRole', userRole.data.role)
          dispatch(setRole(userRole.data.role))
        }

        response.message = 'Logged In Successfully'

        return response
      }
    } catch (err) {
      // console.log('Error', err)
      // console.log('err.response', err.response)
      if (err.response) {
        dispatch(setError(err.response.data.msg))
        TimerMixin.setTimeout(() => {
          dispatch(setError(''))
        }, 5000)
        return
      }
    }
  }
}

export const onEditName = (data) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let updateData = await axios.post(`${serveruri}/api/update-name`, data)

      if (updateData.status === 200) {
        sessionStorage.setItem('user', JSON.stringify(updateData.data))
        response.status = 200
        response.data = updateData.data
        dispatch(setUser(updateData.data))

        response.message = 'Name Updated Successfully'
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setError(err.response.data.msg))
        TimerMixin.setTimeout(() => {
          dispatch(setError(''))
        }, 5000)
        return
      }
    }
  }
}

export const onPasswordChange = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let updated = await axios.post(`${serveruri}/api/update-password`, inputData)

        if (updated.status === 200) {
          response.status = 200
          response.token = token
          response.data = updated.data
          dispatch(setUser(updated.data))
          dispatch(setPasswordMsg('Password Updated'))
        }
      } catch (err) {
        if (err.response) {
          dispatch(setError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setError(''))
          }, 5000)
          return
        }
      }
    }
  }
}

export const getAuth = () => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let getUser = await axios.get(`${serveruri}/api/auth/getAuth`)

        if (getUser.status === 200) {
          response.status = 200
          response.token = token
          response.data = getUser.data
          dispatch(setUser(getUser.data))
        }
        return response
      } catch (err) {
        if (err.response) {
          dispatch(setError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setError(''))
          }, 5000)
          return
        }
      }
    }
  }
}

export const onEditStatus = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let updated = await axios.post(`${serveruri}/api/update-status`, inputData)

        if (updated.status === 200) {
          response.status = 200
          response.token = token
          response.data = updated.data
          response.message = 'Status updated successfully'
        }
        return response
      } catch (err) {
        if (err.response) {
          dispatch(setError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setError(''))
          }, 5000)
          return
        }
      }
    }
  }
}

export const onEditRole = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      try {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let updated = await axios.post(`${serveruri}/api/update-role`, inputData)

        if (updated.status === 200) {
          response.status = 200
          response.token = token
          response.data = updated.data
          response.message = 'Role updated successfully'
        }
        return response
      } catch (err) {
        if (err.response) {
          dispatch(setError(err.response.data.msg))
          TimerMixin.setTimeout(() => {
            dispatch(setError(''))
          }, 5000)
          return
        }
      }
    }
  }
}

export const getAllOperators = () => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      if (token) {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let users = await axios.get(`${serveruri}/api/fetch-all-users`)

        if (users.status === 200) {
          response.status = 200
          response.token = token
          response.data = users.data
          response.message = 'Role updated successfully'
          dispatch(setOperators(users.data))
        }
      } else {
        dispatch(setOperators([]))
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setError(err.response.data.msg))
        TimerMixin.setTimeout(() => {
          dispatch(setError(''))
        }, 5000)
        return
      }
    }
  }
}

export const getAllUsers = () => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      if (token) {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let users = await axios.get(`${serveruri}/api/fetch-all-executives`)

        if (users.status === 200) {
          response.status = 200
          response.token = token
          response.data = users.data
          response.message = 'Role updated successfully'
          dispatch(setAllUsers(users.data))
        }
      } else {
        dispatch(setAllUsers([]))
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setError(err.response.data.msg))
        TimerMixin.setTimeout(() => {
          dispatch(setError(''))
        }, 5000)
        return
      }
    }
  }
}

export const handleActiveTab = data => {
  sessionStorage.setItem('activeTab', data)
  return dispatch => {
    dispatch(setActiveTab(data))
  }
}

export const setActiveTab = data => {
  return {
    type: SET_ADMIN_TAB,
    data: data
  }
}

export const setNewUser = (userData) => {
  return {
    type: NEW_USER,
    data: userData
  }
}

export const setUser = userData => {
  return {
    type: SET_USER,
    data: userData
  }
}

export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    data: token
  }
}

export const setRole = role => {
  return {
    type: SET_ROLE,
    data: role
  }
}

export const setError = errData => {
  return {
    type: SET_ERR_MSG,
    data: errData
  }
}

export const checkUser = () => {
  if (sessionStorage.getItem('userToken')) {
    return {
      type: SET_TOKEN,
      data: sessionStorage.getItem('userToken')
    }
  } else {
    return {
      type: REMOVE_TOKEN,
      data: ''
    }
  }
}

export const checkActiveTab = () => {
  if (sessionStorage.getItem('activeTab')) {
    return {
      type: SET_ADMIN_TAB,
      data: sessionStorage.getItem('activeTab')
    }
  } else {
    sessionStorage.setItem('activeTab', 0)
    return {
      type: SET_ADMIN_TAB,
      data: 0
    }
  }
}

export const checkRole = () => {
  if (sessionStorage.getItem('userRole')) {
    return {
      type: SET_ROLE,
      data: sessionStorage.getItem('userRole')
    }
  } else {
    return {
      type: REMOVE_ROLE,
      data: ''
    }
  }
}

export const checkDetails = () => {
  if (sessionStorage.getItem('user')) {
    return {
      type: SET_USER,
      data: JSON.parse(sessionStorage.getItem('user'))
    }
  } else {
    return {
      type: REMOVE_USER,
      data: ''
    }
  }
}

export const setPasswordMsg = data => {
  return {
    type: SET_PASSWORD_MSG,
    data: data
  }
}

export const setSuccessMsg = data => {
  return {
    type: SET_SUCCESS_MSG,
    data: data
  }
}

export const setOperators = data => {
  return {
    type: SET_ALL_OPERATORS,
    data: data
  }
}

export const setAllUsers = data => {
  return {
    type: SET_ALL_USERS,
    data: data
  }
}

export const onLogout = () => {
  window.location.reload(false)
  // sessionStorage.removeItem('userToken')
  // sessionStorage.removeItem('user')
  // sessionStorage.removeItem('userRole')
  // sessionStorage.removeItem('counter')
  sessionStorage.clear()
  delete axios.defaults.headers.common['Authorization']
}