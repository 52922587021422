import React from 'react'
import { Container, Row, Col } from 'shards-react'

import PageTitle from '../common/PageTitle'
import UserDetails from '../profile/UserDetails'
import UserAccountDetails from '../profile/UserAccountDetails'

const UserProfileLite = () => (
  <>
    <Row noGutters className='page-header py-4'>
      <PageTitle
        title='User Profile'
        subtitle='Overview'
        md='12'
        className='ml-sm-auto mr-sm-auto'
      />
    </Row>
    <Row>
      {/* <Col lg='4'>
        <UserDetails />
      </Col> */}
      <Col lg='8'>
        <UserAccountDetails />
      </Col>
    </Row>
  </>
)

export default UserProfileLite

// import React from 'react'
// import { makeStyles } from '@material-ui/core/styles'
// import Card from '@material-ui/core/Card'
// import clsx from 'clsx'
// import CardActions from '@material-ui/core/CardActions'
// import CardContent from '@material-ui/core/CardContent'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'
// import Typography from '@material-ui/core/Typography'
// import Divider from '@material-ui/core/Divider'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import Avatar from '@material-ui/core/Avatar'
// import { typography } from '@material-ui/system'

// const useStyles = makeStyles(theme => ({
//   card1: {
//     maxWidth: 650
//   },
//   card2: {
//     width: 350,
//     marginRight: 13
//   },
//   bullet: {
//     display: 'inline-block',
//     margin: '0 2px',
//     transform: 'scale(0.8)'
//   },
//   title: {
//     fontSize: 18
//   },
//   pos: {
//     marginBottom: 12
//   },
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   margin: {
//     margin: theme.spacing(1)
//   },
//   withoutLabel: {
//     marginTop: theme.spacing(3)
//   },
//   textField: {
//     width: 300
//   }
// }))

// export default function SimpleCard (props) {
//   const classes = useStyles()

//   let { user } = props

//   return (
//     <div style={{ display: 'flex', flexDirection: 'row' }}>
//       <Card className={classes.card2}>
//         <CardContent
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center'
//           }}
//         >
//           <Avatar style={{ width: 150, height: 150 }}>Admin</Avatar>
//           <Typography
//             style={{ fontSize: 26, textAlign: 'center' }}
//             variant='h5'
//             component='h2'
//             gutterBottom
//           >
//             {user.name}
//           </Typography>
//           <Typography
//             style={{ fontSize: 18, textAlign: 'center' }}
//             variant='h5'
//             component='h2'
//             gutterBottom
//           >
//             Email: {user.email}
//           </Typography>
//           <Divider />
//           <Typography
//             style={{ fontSize: 16 }}
//             variant='h5'
//             component='h2'
//             gutterBottom
//           >
//             Description
//           </Typography>
//           <Typography
//             style={{ fontSize: 15, textAlign: 'center' }}
//             variant='h5'
//             component='h2'
//             gutterBottom
//           >
//             Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque,
//             quidem, commodi soluta qui quae minima obcaecati quod dolorum sint
//             alias, possimus illum assumenda eligendi cumque?
//           </Typography>
//         </CardContent>
//       </Card>
//       <Card className={classes.card1}>
//         <CardContent>
//           <Typography
//             className={classes.title}
//             color='textSecondary'
//             gutterBottom
//           >
//             Acount Details
//           </Typography>
//           <Divider />
//           <div style={{ display: 'flex', flexDirection: 'row', marginTop: 7 }}>
//             <TextField
//               label='First Name'
//               id='outlined-start-adornment'
//               className={clsx(classes.margin, classes.textField)}
//               InputProps={{
//                 startAdornment: <InputAdornment position='start' />
//               }}
//               variant='outlined'
//             />
//             <TextField
//               label='Last Name'
//               id='outlined-start-adornment'
//               className={clsx(classes.margin, classes.textField)}
//               InputProps={{
//                 startAdornment: <InputAdornment position='start' />
//               }}
//               variant='outlined'
//             />
//           </div>
//           <div style={{ display: 'flex', flexDirection: 'row', marginTop: 7 }}>
//             <TextField
//               label='Email'
//               id='outlined-start-adornment'
//               className={clsx(classes.margin, classes.textField)}
//               InputProps={{
//                 startAdornment: <InputAdornment position='start' />
//               }}
//               variant='outlined'
//             />
//             <TextField
//               label='Password'
//               id='outlined-start-adornment'
//               type='password'
//               className={clsx(classes.margin, classes.textField)}
//               InputProps={{
//                 startAdornment: <InputAdornment position='start' />
//               }}
//               variant='outlined'
//             />
//           </div>
//           <TextField
//             label='Address'
//             id='outlined-start-adornment'
//             className={clsx(classes.margin, classes.textField)}
//             InputProps={{
//               startAdornment: <InputAdornment position='start' />
//             }}
//             variant='outlined'
//             style={{ width: 600 }}
//           />
//           <div style={{ display: 'flex', flexDirection: 'row', marginTop: 7 }}>
//             <TextField
//               label='City'
//               id='outlined-start-adornment'
//               className={clsx(classes.margin, classes.textField)}
//               InputProps={{
//                 startAdornment: <InputAdornment position='start' />
//               }}
//               variant='outlined'
//             />
//             <TextField
//               label='State'
//               id='outlined-start-adornment'
//               className={clsx(classes.margin, classes.textField)}
//               InputProps={{
//                 startAdornment: <InputAdornment position='start' />
//               }}
//               variant='outlined'
//               style={{ width: 200 }}
//             />
//             <TextField
//               label='Zip'
//               id='outlined-start-adornment'
//               className={clsx(classes.margin, classes.textField)}
//               InputProps={{
//                 startAdornment: <InputAdornment position='start' />
//               }}
//               variant='outlined'
//               style={{ width: 100 }}
//             />
//           </div>
//           <TextField
//             id='outlined-multiline-static'
//             label='Multiline'
//             multiline
//             rows='5'
//             variant='outlined'
//             InputProps={{
//               startAdornment: <InputAdornment position='start' />
//             }}
//             className={clsx(classes.margin, classes.textField)}
//             style={{ width: 600 }}
//           />
//           <Button
//             variant='contained'
//             color='primary'
//             style={{ color: 'white' }}
//           >
//             Update Account
//           </Button>
//         </CardContent>
//       </Card>
//     </div>
//   )
// }
