import React, { Component } from 'react'
import RatingPage from './RatingPage'

let RatingMain = (props) => {
  return (
    <div style={{ textAlign: 'center', marginTop: '9%' }}>
      <RatingPage />
    </div>
  )
}


export default RatingMain