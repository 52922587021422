import React from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert,
  FormInput,
  FormGroup,
  Button
} from 'shards-react'

import BranchList from './BranchList'

import { connect } from 'react-redux'
import { addNewBranch } from '../../axiosLib/branchActions'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const ServiceCreate = props => {
  let { addNewBranch, branch } = props

  let [branchName, setName] = React.useState('')
  let [address, setAddress] = React.useState('')
  let [lat, setLat] = React.useState('')
  let [lng, setLng] = React.useState('')

  let handleName = event => {
    setName(event.target.value)
  }
  let handleAddress = event => {
    setAddress(event.target.value)
  }
  let handleLatt = event => {
    setLat(event.target.value)
  }
  let handleLng = event => {
    setLng(event.target.value)
  }

  let onSubmit = () => {
    let data = {
      branchName: branchName,
      address: address,
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    }
    addNewBranch(data).then(res => {
      if (res) {
        socket.emit('addBranch', res.data)
        socket.emit('notify', 'New Branch Added')
        setName('')
        setAddress('')
        setLat('')
        setLng('')
      }
    })
  }
  return (
    <>
      <Row>
        <Col lg='6' className='mb-4'>
          <Card small className='mb-4'>
            <CardHeader className='border-bottom'>
              <h6 className='m-0'>Add New Branch</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className='p-3'>
                <Row>
                  <Col>
                    <Form>
                      <FormGroup>
                        <label>Branch Name</label>
                        <FormInput
                          value={branchName}
                          onChange={handleName}
                          placeholder='Branch Name'
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Address</label>
                        <FormInput
                          value={address}
                          onChange={handleAddress}
                          placeholder='Address'
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Lattitude</label>
                        <FormInput
                          value={lat}
                          onChange={handleLatt}
                          placeholder='Lattitude'
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Longitude</label>
                        <FormInput
                          value={lng}
                          onChange={handleLng}
                          placeholder='Longitude'
                        />
                      </FormGroup>
                      <Button onClick={onSubmit}>Add</Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
        <BranchList />
      </Row>
    </>
  )
}

function mapStateToProps(state) {
  return {
    branch: state.branch ? state.branch : null
  }
}

export default connect(
  mapStateToProps,
  { addNewBranch }
)(ServiceCreate)
