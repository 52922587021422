import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import '../../assets/css/CounterList.css'
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles({
  title: {
    fontSize: 14,
    fontFamily: 'Poppins'
  },
  pos: {
    marginBottom: 0,
    fontFamily: 'Poppins'
  }
})

let LaneTop = props => {
  const classes = useStyles()
  let len = props.title.length
  let num = props.title[len - 1]
  let tag = num >= '1' && num <= '9' ? '0' + num : ''
  return (
    <div className='lane-top'>
      <div style={{ display: 'flex' }}>
        <div>
          <Typography className={classes.pos} color='textSecondary'>
            {props.title}
          </Typography>
          <Typography className={classes.title} color='textSecondary'>
            {props.cards.length} in Queue
        </Typography>
        </div>
        <div className='counter-number'>
          {tag} <MoreVertIcon />
        </div>
      </div>
    </div>
  )
}

export default LaneTop
