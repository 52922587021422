import React, {Component} from 'react'
import {connect} from 'react-redux'
import '../../assets/css/CounterList.css'
import {Button, Grid} from "@material-ui/core";
import socketIOClient from 'socket.io-client'
import {serveruri} from '../../axiosLib/config'
import {getActiveBranchList, getCurrentActiveTokenList} from "../../axiosLib/tokenActions";
import styled from "styled-components";
import {Howl, Howler} from 'howler';
import tokenNumberSound from "../../assets/voice/token_number.wav";
import counterNumber from "../../assets/voice/counter_number.wav";
import {SoundHelper} from "../../constants/SoundHelper";
import BranchSelect from "./BranchSelect";

let socket = socketIOClient(serveruri)

class Monitor extends Component {
    state = {
        tokenList: [],
        branchList: [],
        branchId: '',
        branchView: true,
        activeCounter: {},
        activeCard: {},
        buttonRef: React.createRef(),
        audioContextResumeRef: React.createRef(),
        monitorRef: React.createRef(),
        audioContext: new window.AudioContext(),
        serialNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    }

    requestFullScreen() {
        const element = document.getElementById('monitor');
        element.requestFullscreen();
    }

    async playSound(token, serviceCounter, timeOut) {

        console.log("token", token)
        console.log("counter", serviceCounter)

        let cNumber = serviceCounter?.title
        let numb = cNumber.match(/\d/g);
        numb = numb.join("");

        let voiceSoundList = [], counter, sound;
        counter = 1;
        sound = {};
        voiceSoundList.push(tokenNumberSound);
        voiceSoundList.push(SoundHelper(Number(token?.qId)))
        voiceSoundList.push(counterNumber)
        voiceSoundList.push(SoundHelper(Number(numb)))

        // await audioContext.resume();
        setTimeout(async () => {
            for (const audio of voiceSoundList) {
                setTimeout(() => {
                    sound = new Howl({src: [audio]});
                    sound.play();
                }, counter * 1800);
                counter++;
            }
        }, timeOut);

    }

    async resumeAudioContext() {
        await this.state.audioContext.resume();
    }

    async callCurrentRace() {
        const activeCard = this.state.activeCard
        const serviceCounter = this.state.activeCounter

        await this.playSound(activeCard, serviceCounter,  8000);
    }

    async getBranchList() {
        const branchs = await this.props.getActiveBranchList()
        if (branchs?.status === 200) {
            this.setState({
                ...this.state,
                branchList: branchs?.data
            });
        }
    }

    onSelectBranch(branch){

        this.setState({
            ...this.state,
            branchId: branch,
            branchView: false
        }, ()=>{
            this.getActiveCounter()
        })

    }

    async getActiveCounter() {
        const tokenList = await this.props.getCurrentActiveTokenList(this.state.branchId);

        if (tokenList?.status === 200) {
            this.setState({
                ...this.state,
                tokenList: tokenList?.data?.tokens
            });
        }
    }

    componentDidMount() {
        this.getBranchList()

        //this.getActiveCounter();

        socket.on('reQueue', data => {
            console.log("reQueue",data)
            this.getActiveCounter();
        })

        socket.on('parkedToken', data => {
            console.log("parkedToken",data)
            this.getActiveCounter();
        })

        socket.on('doneList', data => {
            console.log("doneList",data)
            this.getActiveCounter();
        })

        socket.on('callActiveToken',  async (data) => {
            if (data?.branchId === this.state.branchId){
                this.setState({
                    ...this.state,
                    activeCounter: data.activeList,
                    activeCard: data.activeCard
                })

                await this.state.audioContext.resume();
                this.state.buttonRef.current.click();
                this.state.audioContextResumeRef.current.click();
            }
        })

        // const monitor = document.getElementById('monitor');
        // monitor.requestFullscreen();
    }

    render() {
        return(
            <>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={this.requestFullScreen}>Enter full screen</Button>
                <button style={{opacity: "0"}}
                        ref={this.state.buttonRef}
                        onClick={this.callCurrentRace.bind(this)}>Call Token
                </button>
                <StyledMonitor id={"monitor"}>
                    {
                        this.state.branchView ?
                            <>
                                <BranchSelect branchList={this.state.branchList} onSelectBranch={this.onSelectBranch.bind(this)}/>
                            </> :
                            <>
                                <div className={"monitor-header"}>

                                    <button style={{opacity: "0", position: "fixed", bottom: "0"}}
                                            ref={this.state.audioContextResumeRef}
                                            onClick={this.resumeAudioContext.bind(this)}>Call Token
                                    </button>
                                    <marquee behavior="" direction="right">
                                        <h2>Counter: {this.state.activeCounter?.title}</h2>
                                    </marquee>
                                    <marquee behavior="" direction="left">
                                        <h2>Token number: {this.state.activeCard?.qId}</h2>
                                    </marquee>
                                </div>
                                <div className={"monitor-body"}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{height: 172}}>
                                        {
                                            this.state.tokenList ? this.state.tokenList.length > 0 && this.state.tokenList.map((token, index)=>{
                                                return(
                                                    <Grid item xs={4}>
                                                        <div className={index === 0 ? 'token-track active-track' : index === 1 ? 'upcoming-track token-track' : 'token-track'}>
                                                            <h2>
                                                                {index+1}. {token.clientId?.name !== "" ? token.clientId.name: token.token} {`(Token-${token.token})`}
                                                            </h2>
                                                        </div>
                                                    </Grid>
                                                )
                                            }) : null
                                        }
                                    </Grid>
                                </div>
                            </>
                    }
                </StyledMonitor>
            </>
        )
    }
}

const StyledMonitor = styled.div`
  h2 {
    margin: 0;
  }

  .monitor-header {
    height: 12%;

    marquee {
      padding: 5px 0;

      h2 {
        margin: 0;
        color: #ffffff;
      }

      &:nth-of-type(1) {
        background: #0f814a;
        margin-bottom: -5px;
      }

      &:nth-of-type(2) {
        background: orange;
      }
    }
  }

  .monitor-body {
    display: flex;
    flex-direction: row;
    height: 88%;
    
    .token-track {
      background: #ea5876;
      border: 1px solid #fff;
      padding: 24px;
      
      h2 {
        color: #000000;
        font-size: 32px;
      }
    }
    
    .active-track {
      background: green;
    }

    .upcoming-track {
      background: yellow !important;
    }
  }
`;

export default connect(
    null,
    {
        getCurrentActiveTokenList,
        getActiveBranchList
    }
)(Monitor)