import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import EntryBoard from './EntryBoard'
import TimerMixin from 'react-timer-mixin'
import { connect } from 'react-redux'
import Scanner from './Scanner'

import {
  checkToken,
  createToken,
  dropToken,
  clearData,
  printToken,
  backToMain,
  createAnotherToken
} from '../../axiosLib/tokenActions'

import { returnMissed } from '../../axiosLib/tokenCounterActions'

import '../../assets/css/Kiosk.css'

import socketIOClient from 'socket.io-client'
import { serveruri, branchId } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const MainDisplay = props => {
  let {
    token,
    checkToken,
    dropToken,
    printToken,
    backToMain,
    returnMissed,
    createAnotherToken,
    remoteBranch,
    remoteService
  } = props

  let tokenDrop = token ? token.dropToken ? token.dropToken : null : null
  let reQueue = token ? token.requeue ? token.requeue : null : null
  let existing = token ? token.existing ? token.existing : {} : {}
  let errors = token ? token.errors ? token.errors : '' : ''
  let clicked = token ? token.clicked ? token.clicked : null : null
  let msg = token ? token.msg ? token.msg : '' : ''
  let requeueId = token ? token.requeueId ? token.requeueId : '' : ''
  let textFields = token ? token.textFields ? token.textFields : null : null

  let [phone, setPhone] = React.useState('')

  let phoneSearch = () => {
    if (phone.length) {
      let data = {
        token: phone
      }
      checkToken(data).then(res => {
        if(res) {
          setPhone('')
        }
      })
    } 
  }
  let drop = () => {
    if (tokenDrop) {
      let drop = { token: tokenDrop }
      setPhone('')
      dropToken(drop)
        .then(res => {
          setPhone('')
          socket.emit('reQueue', '')
          TimerMixin.setTimeout(() => {
            backToMain()
          }, 5000)
        })
        .catch(err => {
          console.log('Error', err)
        })
    }
  }
  let back = () => {
    backToMain()
    setPhone('')
  }
  let returnMissedToken = () => {
    let data = { tokenId: requeueId }
    returnMissed(data)
      .then(res => {
        setPhone('')
        socket.emit('reQueue', data)
        TimerMixin.setTimeout(() => {
          backToMain()
        }, 5000)
      })
      .catch(err => {
        console.log('Error', err)
      })
  }

  useEffect(() => {
    if(phone.length) {
      phoneSearch()
    }
  }, [phone])

  return (
    <>
      {existing.msg ? (
        <form className='container' noValidate autoComplete='off'>
          <div style={{ marginBottom: 5 }} />
          <Typography style={{ color: 'red', justifyContent: 'center' }}>
            {existing.msg}
          </Typography>
          <div style={{ marginBottom: 8 }} />
          {
            reQueue ? (
              <Button
                style={{ minWidth: 220, fontFamily: 'Poppins', marginBottom: 14 }}
                variant='outlined'
                color='primary'
                onClick={returnMissedToken}
                disabled={clicked}
              >
                Re-Queue Token
              </Button>
            ) : null
          }
          <Button
            style={{ minWidth: 220, fontFamily: 'Poppins', marginBottom: 14 }}
            variant='outlined'
            color='primary'
            onClick={createAnotherToken}
            disabled={clicked}
          >
            Create Another Token
          </Button>
          <Button
            style={{ minWidth: 220, fontFamily: 'Poppins', marginBottom: 14 }}
            variant='outlined'
            color='primary'
            onClick={drop}
            disabled={clicked}
          >
            Clear Old Token
          </Button>

          <Button
            style={{ minWidth: 220, fontFamily: 'Poppins', marginBottom: 14 }}
            variant='outlined'
            color='primary'
            onClick={printToken}
            disabled={clicked}
          >
            Print Token
          </Button>
          <Button
            style={{ minWidth: 220, fontFamily: 'Poppins', marginBottom: 14 }}
            variant='outlined'
            color='primary'
            onClick={back}
            disabled={clicked}
          >
            Back To Main
          </Button>
          <div style={{ marginBottom: 8 }} />
          {msg.length ? (
            <Typography style={{ color: 'green' }}>
              {msg}
            </Typography>
          ) : errors.msg ? (
            <Typography style={{ color: 'red' }}>
              {errors.msg}
            </Typography>
          ) : (
                ''
              )}
        </form>
      ) : errors.length ? (
        <Typography style={{ color: 'red' }}>
          {errors}
        </Typography>
      ) : phone.length ? (
        <Typography>
          Searching Token...
        </Typography>
        ) : (
            <form
              className='container'
              noValidate
              autoComplete='off'
              onSubmit={phoneSearch}
            >
              <Scanner setToken={setPhone} />
              <div style={{ marginBottom: 5 }} />
              {msg.length ? (
                <Typography style={{ color: 'green' }}>
                  {msg}
                </Typography>
              ) : errors.length ? (
                <Typography style={{ color: 'red' }}>
                  {errors}
                </Typography>
              ) : (
                    ''
                  )}
            </form>
          )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    token: state.token ? state.token : null
  }
}

export default connect(
  mapStateToProps,
  {
    checkToken,
    createToken,
    dropToken,
    clearData,
    printToken,
    backToMain,
    returnMissed,
    createAnotherToken
  }
)(MainDisplay)
