import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { createMuiTheme } from '@material-ui/core/styles'
import 'bootstrap/dist/css/bootstrap.min.css'
import './components/shards-dashboard/styles/shards-dashboards.1.1.0.min.css'

import { Provider } from 'react-redux'
import { checkUser, checkRole, checkDetails, checkActiveTab } from './axiosLib/authActions'
import { checkCounter } from './axiosLib/tokenCounterActions'
import { showEntryOnLoad } from './axiosLib/tokenActions'
import Store from './store/index'

const AppStore = Store()

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#62BC74'
    }
  }
})

function onAppInit(dispatch) {
  dispatch(checkUser())
  dispatch(checkRole())
  dispatch(checkDetails())
  dispatch(checkCounter())
  dispatch(checkActiveTab())
  dispatch(showEntryOnLoad())
}

ReactDOM.render(
  <Provider store={AppStore}>
    <App onLoad={onAppInit(AppStore.dispatch)} />
  </Provider>
  ,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
