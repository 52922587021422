import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ReplayIcon from '@material-ui/icons/ReplayOutlined'
import Button from '@material-ui/core/Button'

import { connect } from 'react-redux'

import { unparkToken, onSelectCounter } from '../../axiosLib/tokenCounterActions'

import '../../assets/css/StartServing.css'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const getData = (item, data) => {
  let check = data.tokens && data.users && data.counters && data.services
  if (check) {
    let clientId = data.tokens.find(token => token._id === item.tokenId).clientId
    let token = data.tokens.find(token => token._id === item.tokenId).token
    let clientName = data.users.find(user => user._id === clientId).name
    let counter = data.counters.find(counter => counter._id === item.counterId)
      .counterTitle
    let service = data.services.find(ser => ser._id === item.serviceId).serviceName

    let res = {
      qId: token,
      name: clientName,
      service: service,
      counter: counter,
      assignedAt: item.assignTime
    }

    return res
  } else return null
}

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 5,
    borderRadius: 0,
    marginLeft: -5,
    marginRight: -5,
    marginTop: 16
  },
  root: {
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    '&:last-child': {
      paddingBottom: 16
    }
  },
  name: {
    fontSize: '1rem',
    fontFamily: 'Poppins',
    color: '#000000',
  },
  title: {
    fontSize: 13,
    fontFamily: 'Poppins'
  },
  pos: {
    marginBottom: 5,
    fontSize: 13,
    fontFamily: 'Poppins',
    color: '#000000',
  },
  pos2: {
    marginBottom: 5,
    fontSize: 13,
    fontFamily: 'Poppins',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '92%',
    borderTop: '0.5px solid #EAE5E5'
  },
  cssLabels: {
    color: '#EAE5E5'
  },
  underline: {
    '&:after': {
      borderBottom: '2px solid #62BC74'
    }
  }
}))

let userToken = sessionStorage.getItem('userToken')

let CustomCard = props => {
  const classes = useStyles()
  let { current, back, tokenId } = props
  return (
    <Card className={classes.card}>
      <CardContent className={classes.root}>
        <div style={{ width: '90%' }}>
          <Typography className={classes.name}>
            {current.qId}
          </Typography>
          <Typography className={classes.pos} color='textSecondary'>
            {current.name}
          </Typography>
          <Typography
            className={classes.title}
            color='textSecondary'
            gutterBottom
          >
            {current.service}
          </Typography>
          <Typography
            className={classes.title}
            color='textSecondary'
            gutterBottom
          >
            {current.counter}
          </Typography>
        </div>
        <div style={{ marginTop: '1.5%' }}>
          <Typography className={classes.pos2} color='textSecondary'>
            01:39
          </Typography>
          <Button onClick={() => back(tokenId)}>
            <ReplayIcon />
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

const ParkingNew = props => {
  let { tokenCounter, unparkToken, onSelectCounter } = props

  let parked = tokenCounter ? tokenCounter.parked ? tokenCounter.parked : [] : []
  let activeCounter = tokenCounter ?
    tokenCounter.activeCounter ? tokenCounter.activeCounter : '' : ''
  let allData = tokenCounter ?
    tokenCounter.allData ? tokenCounter.allData : null : null

  let onSubmit = tokenId => {
    let data = { tokenId: tokenId }
    unparkToken(data)
      .then(res => {
        socket.emit('parkedToken', userToken)
      })
  }
  return (
    <Card
      className='start-serving'
      style={{ height: window.innerHeight - 118 }}
    >
      <div>
        <header style={{ backgroundColor: '#EAE5E5', height: 68 }}>
          <Typography
            style={{ paddingTop: 20, textAlign: 'left', marginLeft: 16, color: '#000000', fontFamily: 'Poppins', fontSize: 18 }}
          >
            Parked Tokens
        </Typography>
        </header>
        <div style={{ padding: 5 }}>
          {
            parked.length && activeCounter.length && allData ? (
              parked.map(item => {
                let parsedData = getData(item, allData)
                if (item.counterId == activeCounter && parsedData) {
                  return (
                    <CustomCard
                      current={parsedData}
                      back={onSubmit}
                      tokenId={item.tokenId}
                    />
                  )
                }
              })
            ) : <div className='container'>
                <p className='p2'>There Will Be Parked Tokens</p>
              </div>
          }
        </div>
      </div>
    </Card>
  )
}

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null
  }
}

export default connect(
  mapStateToProps,
  {
    unparkToken,
    onSelectCounter
  }
)(ParkingNew)

