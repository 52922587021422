import React from 'react'

import CounterServiceAdd from './CounterServiceAdd'

const CounterServiceMain = props => {
  return (
    <CounterServiceAdd />
  )
}

export default CounterServiceMain
