import React from 'react'
import DropDown from './DropDownTop'

import '../../assets/css/CounterSelect.css'

const SelectCounter = () => {
  return (
    <div className='main'>
      <h4>Select a counter: </h4>
      <DropDown />
    </div>
  )
}

export default SelectCounter
