import React from "react";
import { connect } from "react-redux";
import SearchService from "./SearchService";
import ServiceTable from "./ServiceTable";
import moment from "moment";
import Chart from "./Chart";

const setData = (data) => {
  let servingTime = data.map((item) => {
    let endTime = moment.utc(item.departureTime).unix();
    let startTime = moment.utc(item.callTime).unix();
    return endTime - startTime;
  });

  let totalServingTime = data.length ? servingTime.reduce((a, b) => a + b) : 0;

  let avgServiceTime = data.length ? totalServingTime / data.length / 60 : 0;

  let waitingTime = data.map((item) => {
    let endTime = moment.utc(item.callTime).unix();
    let startTime = moment.utc(item.assignTime).unix();
    return endTime - startTime;
  });

  let totalWaitingTime = data.length ? waitingTime.reduce((a, b) => a + b) : 0;

  let avgWaitingTime = data.length ? totalWaitingTime / data.length / 60 : 0;

  return {
    length: data.length,
    serviceTime: avgServiceTime > 0 ? avgServiceTime.toFixed(3) : 0,
    waitingTime: avgWaitingTime > 0 ? avgWaitingTime.toFixed(3) : 0,
  };
};

const ServiceMain = (props) => {
  let { tokenCounter, service } = props;

  let services = service ? (service.services ? service.services : []) : [];
  let serviceReport = tokenCounter
    ? tokenCounter.serviceReport
      ? tokenCounter.serviceReport
      : []
    : [];
  let reportStart = tokenCounter
    ? tokenCounter.reportStart
      ? tokenCounter.reportStart
      : new Date()
    : new Date();
  let reportEnd = tokenCounter
    ? tokenCounter.reportEnd
      ? tokenCounter.reportEnd
      : new Date()
    : new Date();

  let pushResult = serviceReport.length
    ? services.map((service) => {
        let filteredList = serviceReport.filter(
          (item) => item.serviceId == service._id
        );
        let getData = setData(filteredList);
        let title = service.serviceName;
        let res = {
          title: title,
          served: getData.length,
          serviceTime: getData.serviceTime,
          waitingTime: getData.waitingTime,
        };

        return res;
      })
    : [];

  let startDay = moment(reportStart);
  let endDay = moment(reportEnd);

  let dif = endDay.diff(startDay);
  let diff = moment.duration(dif);
  let days = diff.days();

  let chartData = [];

  for (let i = 0; i < days + 1; i++) {
    let cur = moment(startDay).add(i, "d");
    let current = moment(cur._d).format("YYYY-MM-DD");
    let dateFiltered = serviceReport.filter((item) => {
      let time = moment(item.departureTime).format("YYYY-MM-DD");
      if (time == current) return item;
    });
    let data = services.map((item) => {
      let countArr = dateFiltered.filter((f) => f.serviceId == item._id);
      return { service: item.serviceName, count: countArr.length };
    });

    let retData = { name: current };
    let len = data.length;
    for (let j = 0; j < len; j++) {
      retData[`${data[j].service}`] = data[j].count;
    }

    chartData.push(retData);
  }

  // console.log("Service ChartData", chartData);

  return (
    <div>
      <SearchService />
      <div style={{ height: 20 }} />
      <Chart data={chartData} />
      <ServiceTable
        data={pushResult}
        reportStart={reportStart}
        reportEnd={reportEnd}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null,
    service: state.service ? state.service : null,
  };
}

export default connect(mapStateToProps)(ServiceMain);
