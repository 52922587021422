import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import ReactTableToExcel from 'react-html-table-to-excel'
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment'
import { CardHeader } from 'shards-react'

const StyledTableCell = withStyles(theme => ({
  head: {
    color: 'black'
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}))

function TablePaginationActions(props) {
  const classes = useStyles1()
  const theme = useTheme()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
            <KeyboardArrowLeft />
          )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
            <KeyboardArrowRight />
          )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

const useStyles2 = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    maxWidth: '100%'
  },
  table: {
    width: '100%'
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  cell: {
    textAlign: 'center',
    maxWidth: 200,
    fontFamily: 'Poppins'
  }
}))

const CounterTable = props => {
  let { data, reportStart, reportEnd } = props
  const classes = useStyles2()
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  let [open, setOpen] = React.useState(0)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper} style={{ textAlign: 'center' }}>
        <ReactTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename={`counter_${reportStart}_to_${reportEnd}`}
          sheet={`counter_${reportStart}_to_${reportEnd}`}
          buttonText="Download Table as xls"
        />
        <div style={{ marginTop: 24 }} />
        <CardHeader className='border-bottom'>
          <h4 className='m-0'>Counter Report Analysis</h4>
        </CardHeader>
        <Table
          id="table-to-xls"
          className={classes.table}
          aria-label='custom pagination table'
        >
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.cell}>
                Counter
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Branch
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Total Served
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Avg. Service Time
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Avg.Waiting Time
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data &&
              data.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
              : data
            ).map((row, key) => {
              return (
                <>
                  <TableRow
                    button
                    key={key}
                  // onClick={() => handleClick(key + 1)}
                  >
                    <TableCell className={classes.cell}>{row.title}</TableCell>
                    <TableCell className={classes.cell}>{row.branchName}</TableCell>
                    <TableCell className={classes.cell}>{row.served}</TableCell>
                    <TableCell className={classes.cell}>
                      {row.serviceTime}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.waitingTime}
                    </TableCell>
                  </TableRow>
                </>
              )
            })}
          </TableBody>
        </Table>
        <div className='pages'>
          <Pagination
                 style={{height: 48, paddingTop: 8}}
                 count={Math.ceil(data.length/10)}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                onChange={handleChangePage}
               />
          </div>
      </div>
    </Paper >
  )
}

export default CounterTable
