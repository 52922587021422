import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import Pagination from '@material-ui/lab/Pagination';

import moment from 'moment'

const StyledTableCell = withStyles(theme => ({
  head: {
    color: 'black'
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}))

function TablePaginationActions(props) {
  const classes = useStyles1()
  const theme = useTheme()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
            <KeyboardArrowLeft />
          )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
            <KeyboardArrowRight />
          )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

const useStyles2 = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 480,
    maxWidth: '100%'
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  cell: {
    textAlign: 'center',
    maxWidth: 200,
    fontFamily: 'Poppins'
  }
}))

const getData = (item, data) => {
  let clientName = data.users.find(user => user._id === item.clientId).name
  let service = data.services.find(ser => ser._id === item.tokenService)
    .serviceName

  let res = {
    name: clientName,
    service: service
  }

  return res
}

const TokenList = props => {
  let { allData } = props
  const classes = useStyles2()
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    console.log('NewPage', newPage)
  }

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(parseInt(event.target.value, 10))
  //   setPage(0)
  // }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-label='custom pagination table'>
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.cell}>
                Queue ID
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>Name</StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Service
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Appointed Date
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>Weight</StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Emergency
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Token Created
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allData ? (rowsPerPage > 0
              ? allData.tokens
                ? allData.tokens.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                : []
              : allData.tokens
            ).map((row, key) => {
              let rowData = getData(row, allData)
              return (
                <TableRow button key={key}>
                  <TableCell className={classes.cell}>{row.token}</TableCell>
                  <TableCell className={classes.cell}>{rowData.name}</TableCell>
                  <TableCell className={classes.cell}>
                    {rowData.service}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {moment(row.appointedDate).format('DD/MM/YYYY, hh:mm a')}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {row.weight.toFixed(2)}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {row.emergency ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {moment(row.createdAt).format('DD/MM/YYYY, hh:mm a')}
                  </TableCell>
                </TableRow>
              )
            }) : null}
          </TableBody>
        </Table>
        <div className='pages'>
        <Pagination
                style={{height: 48, paddingTop: 8}}
                count={allData ? allData.tokens ?
                  Math.ceil(allData.tokens.length / 10) : 0 : 0
                }
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                page={page}
                onChange={handleChangePage}
              />
        </div>
      </div>
    </Paper>
  )
}

export default TokenList
