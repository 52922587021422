import {
  GET_ALL_BRANCHES,
  SET_BRANCH_ERROR
} from '../constants'

let initialState = {}

let branchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BRANCHES:
      return {
        ...state,
        allBranches: action.data
      }
    case SET_BRANCH_ERROR:
      return {
        ...state,
        branchErrors: action.data
      }
    default:
      return state;
  }
}

export default branchReducer