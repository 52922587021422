import {
  SET_COUNTER_LIST,
  GET_ALL_DATA,
  SET_ACTIVE_COUNTER,
  REMOVE_ACTIVE_COUNTER,
  SET_ACTIVE_LIST,
  SET_CALL_TIME,
  SET_CARD_ACTIVE,
  SET_UPDATED,
  SET_SELECTED,
  SET_SERVING,
  SET_TRANSFER,
  SET_WALKIN,
  SET_DISPLAY_DONE,
  SET_DISPLAY_PARKED,
  SET_WALKIN_CARD,
  SET_ALL_PARKED,
  GET_TOTAL_SERVED,
  GET_SERVICE_AVG_TIME,
  GET_WAITING_TIME,
  GET_COUNTER_TIME,
  SET_NEW_PARKED,
  SET_TOKEN_COUNTER_ERROR,
  SET_SERVICE_REPORT,
  SET_COUNTER_REPORT,
  SET_CHECKLISTS,
  SET_TOKEN_COUNTER_MSG,
  SET_NEW_MISSED,
  SET_ALL_MISSED,
  DISPLAY_MISSED,
  SET_REPORT_START,
  SET_REPORT_END,
  SET_NOW_SERVING,
  SET_LOADING,
  SET_DONE_LIST,
  DISPLAY_MAIN
} from '../constants'

let initialState = {}

let tokenCounterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTER_LIST:
      return {
        ...state,
        counterList: { lanes: action.data }
      }
    case GET_ALL_DATA:
      return {
        ...state,
        allData: action.data
      }
    case SET_ACTIVE_COUNTER:
      return {
        ...state,
        activeCounter: action.data
      }
    case REMOVE_ACTIVE_COUNTER:
      return {
        ...state,
        activeCounter: ''
      }
    case SET_ACTIVE_LIST:
      return {
        ...state,
        activeList: action.data
      }
    case SET_CALL_TIME:
      return {
        ...state,
        callTime: action.data
      }
    case SET_CARD_ACTIVE:
      return {
        ...state,
        cardActive: action.data
      }
    case SET_UPDATED:
      return {
        ...state,
        updated: action.data
      }
    case SET_SELECTED:
      return {
        ...state,
        selected: action.data
      }
    case SET_SERVING:
      return {
        ...state,
        serving: action.data
      }
    case SET_TRANSFER:
      return {
        ...state,
        transfer: action.data
      }
    case SET_WALKIN:
      return {
        ...state,
        walkIn: action.data
      }
    case SET_DISPLAY_DONE:
      return {
        ...state,
        displayDone: action.data
      }
    case SET_DISPLAY_PARKED:
      return {
        ...state,
        displayParked: action.data
      }
    case SET_WALKIN_CARD:
      return {
        ...state,
        walkInCard: action.data
      }
    case SET_ALL_PARKED:
      return {
        ...state,
        parked: action.data
      }
    case GET_TOTAL_SERVED:
      return {
        ...state,
        getTotalServed: action.data
      }
    case GET_SERVICE_AVG_TIME:
      return {
        ...state,
        serviceAvgTime: action.data
      }
    case GET_WAITING_TIME:
      return {
        ...state,
        waitingTime: action.data
      }
    case GET_COUNTER_TIME:
      return {
        ...state,
        counterTime: action.data
      }
    case SET_NEW_PARKED:
      return {
        ...state,
        newParked: action.data
      }
    case SET_TOKEN_COUNTER_ERROR:
      return {
        ...state,
        errors: action.data
      }
    case SET_SERVICE_REPORT:
      return {
        ...state,
        serviceReport: action.data
      }
    case SET_COUNTER_REPORT:
      return {
        ...state,
        counterReport: action.data
      }
    case SET_CHECKLISTS:
      return {
        ...state,
        checkLists: action.data
      }
    case SET_TOKEN_COUNTER_MSG:
      return {
        ...state,
        msg: action.data
      }
    case SET_NEW_MISSED:
      return {
        ...state,
        newMissed: action.data
      }
    case SET_ALL_MISSED:
      return {
        ...state,
        missed: action.data
      }
    case DISPLAY_MISSED:
      return {
        ...state,
        displayMissed: action.data
      }
    case SET_REPORT_START:
      return {
        ...state,
        reportStart: action.data
      }
    case SET_REPORT_END:
      return {
        ...state,
        reportEnd: action.data
      }
    case SET_NOW_SERVING:
      return {
        ...state,
        nowServing: action.data
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.data
      }
    case SET_DONE_LIST:
      return {
        ...state,
        doneList: action.data
      }
    case DISPLAY_MAIN:
      return {
        ...state,
        displayMain: action.data
      }
    default:
      return state
  }
}

export default tokenCounterReducer