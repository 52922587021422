import {
  SET_USER_TOKEN,
  SET_NEW_TOKEN,
  USE_OLD_TOKEN,
  SET_TOKEN_ERRORS,
  SHOW_REGISTRATION,
  SHOW_TEXTFIELDS,
  SHOW_ENTRY,
  DROP_TOKEN,
  GET_EXISTING_TOKEN,
  DELETE_FIELD,
  SET_MSG,
  SET_CLICKED,
  SET_REQUEUE,
  SET_REQUEUE_ID,
  SET_FEEDBACK_ERROR,
  SET_FEEDBACK_SUCCESS,
  GET_TIME_LIST,
  GET_SERVICE_TIME
} from '../constants'

let initialState = {}

let tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TOKEN:
      return {
        ...state,
        user: action.data
      }
    case SET_NEW_TOKEN:
      return {
        ...state,
        newToken: action.data
      }
    case USE_OLD_TOKEN:
      return {
        ...state,
        oldToken: action.data
      }
    case SET_TOKEN_ERRORS:
      return {
        ...state,
        errors: action.data
      }
    case SHOW_REGISTRATION:
      return {
        ...state,
        showReg: action.data
      }
    case SHOW_TEXTFIELDS:
      return {
        ...state,
        textFields: action.data
      }
    case SHOW_ENTRY:
      return {
        ...state,
        showEntry: action.data
      }
    case DROP_TOKEN:
      return {
        ...state,
        dropToken: action.data
      }
    case GET_EXISTING_TOKEN:
      return {
        ...state,
        existing: action.data
      }
    case DELETE_FIELD:
      return {
        ...state,
        deleteField: action.data
      }
    case SET_MSG:
      return {
        ...state,
        msg: action.data
      }
    case SET_CLICKED:
      return {
        ...state,
        clicked: action.data
      }
    case SET_REQUEUE:
      return {
        ...state,
        requeue: action.data
      }
    case SET_REQUEUE_ID:
      return {
        ...state,
        requeueId: action.data
      }
    case SET_FEEDBACK_ERROR:
      return {
        ...state,
        feedbackError: action.data
      }
    case SET_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackSuccess: action.data
      }
    case GET_TIME_LIST:
      return {
        ...state,
        timeList: action.data
      }
    case GET_SERVICE_TIME:
      return {
        ...state,
        serviceTimes: action.data
      }

    default:
      return state
  }
}

export default tokenReducer