import {
  ADD_USER_COUNTER,
  REMOVE_USER_COUNTER,
  GET_ALL_USER_COUNTER,
  SET_USER_COUNTER_ERROR
} from '../constants'

const initialState = {}

let userCounterReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_COUNTER:
      return {
        ...state,
        newUserCounter: action.data
      }
    case REMOVE_USER_COUNTER:
      return {
        ...state,
        removedUserCounter: action.data
      }
    case GET_ALL_USER_COUNTER:
      return {
        ...state,
        allUserCounter: action.data
      }
    case SET_USER_COUNTER_ERROR:
      return {
        ...state,
        errors: action.data
      }
    default:
      return state
  }
}

export default userCounterReducer