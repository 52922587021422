import {
  SET_ALL_SERVICE_COUNTERS,
  SET_SERVICE_COUNTER_ERROR
} from '../constants'

import axios from 'axios'
import { serveruri } from './config'

export const addServiceToCounter = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let addNew = await axios.post(`${serveruri}/api/add-service-counter`, inputData)

      if (addNew.status === 200) {
        response.status = 200
        response.token = token
        response.data = addNew.data
        response.message = 'New Service Counter Added'
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setServiceCounterError(err.response.data.msg))
        return
      }
    }
  }
}

export const removeServiceCounter = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let removeData = await axios.post(`${serveruri}/api/remove-service-counter`, inputData)

      if (removeData.status === 200) {

        response.status = 200
        response.token = token
        response.data = removeData.data
        response.message = 'Service Conters removed'

        let allData = await axios.get(`${serveruri}/api/get-all-service-counters`)

        if (allData.status === 200) {
          dispatch(setAllServiceCounter(allData.data))
        }
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setServiceCounterError(err.response.data.msg))
        return
      }
    }
  }
}

export const getAllServiceCounters = () => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let allData = await axios.get(`${serveruri}/api/get-all-service-counters`)

      if (allData.status === 200) {
        response.status = 200
        response.token = token
        response.data = allData.data
        dispatch(setAllServiceCounter(allData.data))
        response.message = 'Service Conters fetched'
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setServiceCounterError(err.response.data.msg))
        return
      }
    }
  }
}

export const setServiceCounterError = errData => {
  return {
    type: SET_SERVICE_COUNTER_ERROR,
    data: errData
  }
}

export const setAllServiceCounter = allData => {
  return {
    type: SET_ALL_SERVICE_COUNTERS,
    data: allData
  }
}