import voiceSound0 from "../assets/voice/0.wav";
import voiceSound1 from "../assets/voice/1.wav";
import voiceSound2 from "../assets/voice/2.wav";
import voiceSound3 from "../assets/voice/3.wav";
import voiceSound4 from "../assets/voice/4.wav";
import voiceSound5 from "../assets/voice/5.wav";
import voiceSound6 from "../assets/voice/6.wav";
import voiceSound7 from "../assets/voice/7.wav";
import voiceSound8 from "../assets/voice/8.wav";
import voiceSound9 from "../assets/voice/9.wav";
import voiceSound10 from "../assets/voice/10.wav";
import voiceSound11 from "../assets/voice/11.wav";
import voiceSound12 from "../assets/voice/12.wav";
import voiceSound13 from "../assets/voice/13.wav";
import voiceSound14 from "../assets/voice/14.wav";
import voiceSound15 from "../assets/voice/15.wav";
import voiceSound16 from "../assets/voice/16.wav";
import voiceSound17 from "../assets/voice/17.wav";
import voiceSound18 from "../assets/voice/18.wav";
import voiceSound19 from "../assets/voice/19.wav";
import voiceSound20 from "../assets/voice/20.wav";

import voiceSound21 from "../assets/voice/21.wav";
import voiceSound22 from "../assets/voice/22.wav";
import voiceSound23 from "../assets/voice/23.wav";
import voiceSound24 from "../assets/voice/24.wav";
import voiceSound25 from "../assets/voice/25.wav";
import voiceSound26 from "../assets/voice/26.wav";
import voiceSound27 from "../assets/voice/27.wav";
import voiceSound28 from "../assets/voice/28.wav";
import voiceSound29 from "../assets/voice/29.wav";
import voiceSound30 from "../assets/voice/30.wav";


export const SoundHelper = (number) => {
    switch (number) {
        case 0:
            return voiceSound0;
            break;
        case 1:
            return voiceSound1;
            break;
        case 2:
            return voiceSound2;
            break;
        case 3:
            return voiceSound3;
            break;
        case 4:
            return voiceSound4;
            break;
        case 5:
            return voiceSound5;
            break;
        case 6:
            return voiceSound6;
            break;
        case 7:
            return voiceSound7;
            break;
        case 8:
            return voiceSound8;
            break;
        case 9:
            return voiceSound9;
            break;
        case 10:
            return voiceSound10;
            break;
        case 11:
            return voiceSound11;
            break;
        case 12:
            return voiceSound12;
            break;
        case 13:
            return voiceSound13;
            break;
        case 14:
            return voiceSound14;
            break;
        case 15:
            return voiceSound15;
            break;
        case 16:
            return voiceSound16;
            break;
        case 17:
            return voiceSound17;
            break;
        case 18:
            return voiceSound18;
            break;
        case 19:
            return voiceSound19;
            break;
        case 20:
            return voiceSound20;
            break;
        case 21:
            return voiceSound21;
            break;
        case 22:
            return voiceSound22;
            break;
        case 23:
            return voiceSound23;
            break;
        case 24:
            return voiceSound24;
            break;
        case 25:
            return voiceSound25;
            break;
        case 26:
            return voiceSound26;
            break;
        case 27:
            return voiceSound27;
            break;
        case 28:
            return voiceSound28;
            break;
        case 29:
            return voiceSound29;
            break;
        case 30:
            return voiceSound30;
            break;
        default:
            return;
            break;
    }
}