import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { connect } from 'react-redux'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  formControl: {

  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const TimePicker = props => {
  const classes = useStyles()
  let { token, service, handleTime, date } = props

  let [time, setTime] = useState('Select Time')

  let serviceTimes = token ? token.serviceTimes ? token.serviceTimes : [] : []
  let timeList = token ? token.timeList ? token.timeList : [] : []
  let modifiedTime = moment(date).format()
  let formattedDate = modifiedTime.slice(0, 10)
  let filteredTimes = serviceTimes.length ?
    serviceTimes.filter(item => item.dateString === formattedDate && item.serviceId === service) : []

  let timeDropDown = timeList.length ?
    timeList.map(item => {
      let checkOpen = filteredTimes.length ?
        filteredTimes.find(f => f.serviceTime === item.time)
        : null

      let sendData = {
        time: item.time,
        hour: item.hour,
        disable: checkOpen ? checkOpen.status : false
      }

      return sendData
    }) : []

  let handleChange = event => {
    setTime(event.target.value)
    handleTime(event.target.value)
  }

  return (
    <div>
      <FormControl className='service-picker'>
        <InputLabel id="demo-simple-select-label"
        >

        </InputLabel>
        <Select
          style={{
            color: '#000000',
            fontSize: 14,
            fontWeight: 300,
            fontFamily: 'Poppins',
          }}
          labelid='demo-simple-select-label'
          id='demo-simple-select'
          value={time}
          variant='outlined'
          fullWidth
          onChange={handleChange}
        >
          <MenuItem
            style={{
              color: 'grey',
              fontSize: 14,
              fontWeight: 300,
              fontFamily: 'Poppins'
            }}
            value={'Select Time'}>Select Time</MenuItem>
          {Array.isArray(timeDropDown) &&
            timeDropDown.map((item, i) => (
              <MenuItem
                key={i}
                disabled={item.disable}
                value={item.time + ' ' + item.hour}
                style={{
                  color: 'grey',
                  fontSize: 14,
                  fontWeight: 300,
                  fontFamily: 'Poppins'
                }}
              >
                {item.time + ' ' + item.hour}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    token: state.token ? state.token : null
  }
}

export default connect(mapStateToProps)(TimePicker)