import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import authReducer from '../reducers/authReducers'
import counterReducer from '../reducers/counterReducers'
import serviceCounterReducer from '../reducers/serviceCounterReducer'
import serviceReducer from '../reducers/serviceReducers'
import tokenReducer from '../reducers/tokenReducers'
import tokenCounterReducer from '../reducers/tokenCounterReducers'
import userCounterReducer from '../reducers/userCounterReducers'
import branchReducer from '../reducers/branchReducers'
import { composeWithDevTools } from 'redux-devtools-extension'

const rootReducer = combineReducers({
  user: authReducer,
  counter: counterReducer,
  serviceCounter: serviceCounterReducer,
  userCounter: userCounterReducer,
  service: serviceReducer,
  token: tokenReducer,
  tokenCounter: tokenCounterReducer,
  branch: branchReducer
});

export default function Store() {
  return createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(thunkMiddleware),
    )
  )
}
