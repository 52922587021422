import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import ReplayIcon from '@material-ui/icons/ReplayOutlined'
import Button from '@material-ui/core/Button'
import Pagination from '@material-ui/lab/Pagination'

import { connect } from 'react-redux'
import moment from 'moment'

import { returnMissed } from '../../axiosLib/tokenCounterActions'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#E0FFFF',
    color: 'black'
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}))

function TablePaginationActions(props) {
  const classes = useStyles1()
  const theme = useTheme()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
            <KeyboardArrowLeft />
          )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
            <KeyboardArrowRight />
          )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

const useStyles2 = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 480,
    maxWidth: 1100
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  cell: {
    textAlign: 'center',
    maxWidth: 200,
    fontFamily: 'Poppins'
  }
}))

const getData = (item, data) => {
  let clientId = data.tokens.find(token => token._id == item.tokenId).clientId
  let token = data.tokens.find(token => token._id == item.tokenId).token
  let clientName = data.users.find(user => user._id == clientId).name
  let counter = data.counters.find(counter => counter._id == item.counterId)
    .counterTitle
  let service = data.services.find(ser => ser._id == item.serviceId).serviceName

  let res = {
    qId: token,
    name: clientName,
    service: service,
    counter: counter,
    assignedAt: item.assignTime
  }

  return res
}

let userToken = sessionStorage.getItem('userToken')

const ParkedList = props => {
  let { tokenCounter, returnMissed } = props

  let missed = tokenCounter ?
    tokenCounter.missed ? tokenCounter.missed : [] : []
  let activeCounter = tokenCounter ?
    tokenCounter.activeCounter ? tokenCounter.activeCounter : '' : ''
  let allData = tokenCounter ?
    tokenCounter.allData ? tokenCounter.allData : null : null

  missed = missed.filter(token => token.counterId === activeCounter)

  const classes = useStyles2()
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  let onSubmit = tokenId => {
    let data = { tokenId: tokenId }
    returnMissed(data)
      .then(res => {
        socket.emit('reQueue', userToken)
      })
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-label='custom pagination table'>
          <TableHead>
            <TableRow>
              <StyledTableCell className={classes.cell}>
                Queue ID
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>Name</StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Service
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Counter
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Counter Call Time
              </StyledTableCell>
              <StyledTableCell className={classes.cell}>
                Send to Counter
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? missed.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              : missed
            ).map((row, key) => {
              let rowData = getData(row, allData)
              return (
                <>
                  <TableRow button key={key}>
                    <TableCell className={classes.cell}>
                      {rowData.qId}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {rowData.name}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {rowData.service}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {rowData.counter}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {moment(rowData.callTime).format('DD/MM/YYYY, hh:mm a')}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Button onClick={() => onSubmit(row.tokenId)}>
                        <ReplayIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <Pagination
                colSpan={3}
                count={Math.ceil(missed.length / 10)}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                onChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  )
}

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null
  }
}

export default connect(
  mapStateToProps,
  {
    returnMissed
  }
)(ParkedList)
