import React from 'react'
import ServiceSearch from './ServiceSearch'
import CounterSearch from './CounterSearch'
import PageTitle from '../Admin/common/PageTitle'
import UserReport from './UserReport'

import { Container, Row } from 'shards-react'

const ReportMain = props => {
  return (
    <>
      <Row>
        <PageTitle title='Report' md='12' className='ml-sm-auto mr-sm-auto' />
      </Row>
      <Row>
        <ServiceSearch />
      </Row>
  
      <Row>
        <CounterSearch />
      </Row>
     
      <Row>
        <UserReport />
      </Row>
    </>
  )
}

export default ReportMain
