import React from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const Chart = (props) => {
  let { data, type } = props
  let height = type ? 400 : 300
  let keyList = data.length ? Object.keys(data[0]) : []
  return (
    <LineChart
      width={800}
      height={height}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      {
        keyList.map(item => {
          if (item !== 'name') return <Line type="linear" dataKey={item} stroke={getRandomColor()} />
        })
      }
    </LineChart>
  )
}

export default Chart
