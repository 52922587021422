import React from 'react'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert,
  FormInput,
  FormGroup,
  Button
} from 'shards-react'

import { connect } from 'react-redux'
import {
  addServiceToCounter,
  getAllServiceCounters,
  removeServiceCounter
} from '../../axiosLib/serviceCounterActions'

import CounterDropDown from './CounterDropDown'
import ServiceList from './ServiceList'
import ServiceCounterTable from './ServiceCounterTable'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const getData = (list, data) => {
  let len = list.length

  let serviceNames = []

  if (data) {
    for (let i = 0; i < len; i++) {
      let service = data.services.find(ser => ser._id == list[i].serviceId)
      let send = {
        serviceName: service.serviceName,
        serviceId: service._id
      }
      serviceNames.push(send)
    }
  }

  return serviceNames
}

const CounterServiceAdd = props => {
  let {
    addServiceToCounter,
    getAllServiceCounters,
    serviceCounter,
    tokenCounter,
    removeServiceCounter,
    branch
  } = props

  let serviceCounters = serviceCounter ?
    serviceCounter.serviceCounters ? serviceCounter.serviceCounters : [] : []
  let allData = tokenCounter ?
    tokenCounter.allData ? tokenCounter.allData : {} : {}
  let branchList = branch ? branch.allBranches ? branch.allBranches : [] : []

  let [counter, setCounter] = React.useState('')
  let [service, setService] = React.useState('')
  let [branchId, setBranch] = React.useState('')

  let handleCounter = (value, branch) => {
    setCounter(value)
    setBranch(branch)
  }
  let handleService = value => {
    setService(value)
  }
  let onSubmit = () => {
    let pushData = {
      serviceId: service,
      counterId: counter,
      branchId: branchId
    }
    addServiceToCounter(pushData)
      .then(res => {
        getAllServiceCounters()
        if (res) {
          socket.emit('notify', 'Service Added To Counter')
        }
      })
  }

  let serviceCounterList = []

  let counters = allData.counters ? allData.counters : []

  let len = counters.length

  for (let i = 0; i < len; i++) {
    let services = serviceCounters.filter(item => item.counterId === counters[i]._id)
    let counterName = counters[i].counterTitle
    let counterId = counters[i]._id
    let branchId = counters[i].branchId
    let serviceList = getData(services, allData)

    let pushData = {
      counter: counterName,
      counterId: counterId,
      services: serviceList,
      branchId: branchId
    }

    serviceCounterList.push(pushData)
  }

  return (
    <>
      <Row>
        <Col lg='6' className='mb-4'>
          <Card small className='mb-4'>
            <CardHeader className='border-bottom'>
              <h6 className='m-0'>Add New Service</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className='p-3'>
                <Row>
                  <Col>
                    <Form>
                      <FormGroup>
                        <label>Counter Name</label>
                        <CounterDropDown handleChange={handleCounter} />
                      </FormGroup>
                      <FormGroup>
                        <label>Service Name</label>
                        <ServiceList handleChange={handleService} />
                      </FormGroup>
                      <Button onClick={onSubmit}>Add</Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Row>
        <ServiceCounterTable
          removeServiceCounter={removeServiceCounter}
          serviceCounterList={serviceCounterList}
          branchList={branchList}
        />
      </Row>
    </>
  )
}

function mapStateToProps(state) {
  return {
    serviceCounter: state.serviceCounter ? state.serviceCounter : null,
    tokenCounter: state.tokenCounter ? state.tokenCounter : null,
    branch: state.branch ? state.branch : null
  }
}

export default connect(
  mapStateToProps,
  { addServiceToCounter, getAllServiceCounters, removeServiceCounter }
)(CounterServiceAdd)
