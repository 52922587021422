import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Col,Card, CardBody } from 'shards-react'

const ServiceReport = props => {
  let { tokenCounter } = props

  let getTotalServed = tokenCounter ?
    tokenCounter.getTotalServed ? tokenCounter.getTotalServed : [] : []

  let servingTime = getTotalServed.map(item => {
    let endTime = moment.utc(item.departureTime).unix()
    let startTime = moment.utc(item.callTime).unix()
    return endTime - startTime
  })

  let totalServingTime = getTotalServed.length
    ? servingTime.reduce((a, b) => a + b)
    : 0

  let avgServiceTime = getTotalServed.length
    ? totalServingTime / getTotalServed.length / 60
    : 0

  let waitingTime = getTotalServed.map(item => {
    let endTime = moment.utc(item.callTime).unix()
    let startTime = moment.utc(item.assignTime).unix()
    return endTime - startTime
  })

  let totalWaitingTime = getTotalServed.length
    ? waitingTime.reduce((a, b) => a + b)
    : 0

  let avgWaitingTime = getTotalServed.length
    ? totalWaitingTime / getTotalServed.length / 60
    : 0

  return (
    <Col lg='8' className='mb-4'>
      <div
        className='p-0 pb-3'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          marginLeft: 24,
          fontFamily: 'Poppins'
        }}
      >
        <p>Total served: {getTotalServed.length}</p>
        <p>
          Average Serving Time: {avgServiceTime ? avgServiceTime.toFixed(3) : 0}{' '}
          mins
        </p>
        <p>
          Average Waiting Time: {avgWaitingTime ? avgWaitingTime.toFixed(3) : 0}{' '}
          mins
        </p>
      </div>
    </Col>
      
  )
}

function mapStateToProps(state) {
  return {
    tokenCounter: state.tokenCounter ? state.tokenCounter : null
  }
}

export default connect(
  mapStateToProps
)(ServiceReport)
