import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import EntryBoard from './RemoteEntry'
import ServicePicker from './ServicePickerRemote'
import BranchPicker from './BranchPicker'

import { connect } from 'react-redux'

import {
  checkUser,
  createToken,
  dropToken,
  clearData,
  printToken,
  backToMain,
  createAnotherToken
} from '../../axiosLib/tokenActions'

import { returnMissed } from '../../axiosLib/tokenCounterActions'

import '../../assets/css/Kiosk.css'

import socketIOClient from 'socket.io-client'
import { serveruri, branchId } from '../../axiosLib/config'

let socket = socketIOClient(serveruri)

const MainDisplay = props => {
  let {
    token,
    checkUser,
    dropToken,
    printToken,
    backToMain,
    returnMissed,
    createAnotherToken,
    remoteService,
    handleService,
    searchBranches,
    activeBranch,
    handleBranch
  } = props

  let tokenDrop = token ? token.dropToken ? token.dropToken : null : null
  let reQueue = token ? token.requeue ? token.requeue : null : null
  let existing = token ? token.existing ? token.existing : {} : {}
  let errors = token ? token.errors ? token.errors : {} : {}
  let clicked = token ? token.clicked ? token.clicked : null : null
  let msg = token ? token.msg ? token.msg : '' : ''
  let requeueId = token ? token.requeueId ? token.requeueId : '' : ''
  let textFields = token ? token.textFields ? token.textFields : null : null

  let [phone, setPhone] = React.useState('')
  let [phoneMsg, setMsg] = React.useState('')

  let onPhoneChange = event => {
    setPhone(event.target.value)
    setMsg('')
    // console.log(event.target.value)
  }
  let phoneSearch = event => {
    event.preventDefault()

    if (phone.length) {
      let data = {
        phone: phone,
        branchId: activeBranch ? activeBranch : branchId
      }
      checkUser(data)
    } else {
      setMsg('Please Enter your Phone Number')
    }
  }
  let drop = () => {
    if (tokenDrop) {
      let drop = { token: tokenDrop }
      setPhone('')
      dropToken(drop)
        .then(res => {
          setPhone('')
          socket.emit('reQueue', '')
        })
        .catch(err => {
          console.log('Error', err)
        })
    }
  }
  let back = () => {
    backToMain()
    setPhone('')
  }
  let returnMissedToken = () => {
    let data = { tokenId: requeueId }
    returnMissed(data)
      .then(res => {
        setPhone('')
        socket.emit('reQueue', data)
      })
      .catch(err => {
        console.log('Error', err)
      })
  }

  return (
    <>
      {existing.msg ? (
        <form className='container' noValidate autoComplete='off'>
          <div style={{ marginBottom: 5 }} />
          <Typography style={{ color: 'red', justifyContent: 'center' }}>
            {existing.msg}
          </Typography>
          <div style={{ marginBottom: 8 }} />
          {
            reQueue ? (
              <Button
                style={{ minWidth: 220, fontFamily: 'Poppins', marginBottom: 14 }}
                variant='outlined'
                color='primary'
                onClick={returnMissedToken}
                disabled={clicked}
              >
                Re-Queue Token
              </Button>
            ) : null
          }
          <Button
            style={{ minWidth: 220, fontFamily: 'Poppins', marginBottom: 14 }}
            variant='outlined'
            color='primary'
            onClick={createAnotherToken}
            disabled={clicked}
          >
            Create Another Token
          </Button>
          <Button
            style={{ minWidth: 220, fontFamily: 'Poppins', marginBottom: 14 }}
            variant='outlined'
            color='primary'
            onClick={drop}
            disabled={clicked}
          >
            Clear Old Token
          </Button>

          <Button
            style={{ minWidth: 220, fontFamily: 'Poppins', marginBottom: 14 }}
            variant='outlined'
            color='primary'
            onClick={printToken}
            disabled={clicked}
          >
            Print Token
          </Button>
          <Button
            style={{ minWidth: 220, fontFamily: 'Poppins', marginBottom: 14 }}
            variant='outlined'
            color='primary'
            onClick={back}
            disabled={clicked}
          >
            Back To Main
          </Button>
          <div style={{ marginBottom: 8 }} />
          {msg.length ? (
            <Typography style={{ color: 'green' }}>
              {msg}
            </Typography>
          ) : errors.msg ? (
            <Typography style={{ color: 'red' }}>
              {errors.msg}
            </Typography>
          ) : (
                ''
              )}
        </form>
      ) : textFields && searchBranches.length ? (
        <EntryBoard
          phoneKey={phone}
          resetPhone={setPhone}
          remoteBranch={activeBranch}
          remoteService={remoteService}
          handleServiceChange={handleService}
        />
      ) : (
            <form
              className='container'
              noValidate
              autoComplete='off'
              onSubmit={phoneSearch}
            >
              <ServicePicker handleChange={handleService} />
              {activeBranch.length && remoteService.length ?
                <>
                  <BranchPicker
                    branches={searchBranches}
                    handleChange={handleBranch}
                    branch={activeBranch}
                  />
                  <div style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <TextField
                      label='Type your Phone Number'
                      value={phone}
                      className='text_field'
                      style={{ minWidth: 300 }}
                      onChange={onPhoneChange}
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                    />

                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      value='Submit'
                      type='submit'
                      disabled={clicked}
                      style={{ marginTop: 18, color: '#ffffff' }}
                    >
                      Submit
                  </Button>
                  </div>

                  <Typography style={{ color: 'red', marginTop: 4 }}>
                    {phoneMsg}
                  </Typography>
                  <div style={{ marginBottom: 5 }} />
                  {msg.length ? (
                    <Typography style={{ color: 'green' }}>
                      {msg}
                    </Typography>
                  ) : errors.msg ? (
                    <Typography style={{ color: 'red' }}>
                      {errors.msg}
                    </Typography>
                  ) : (
                        ''
                      )}
                </> :
                null
              }
            </form>
          )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    token: state.token ? state.token : null
  }
}

export default connect(
  mapStateToProps,
  {
    checkUser,
    createToken,
    dropToken,
    clearData,
    printToken,
    backToMain,
    returnMissed,
    createAnotherToken
  }
)(MainDisplay)
