import React, { useImperativeHandle } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Form,
  Alert,
  FormInput,
  FormGroup,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect
} from 'shards-react'

import StatusDropDown from './StatusDropDown'
import RoleList from './RoleList'
import UserCounterTable from './UserCounterTable'
import BranchDropDown from '../../Branch/BranchDropDown'
import UserCounterDropDown from './UserCounterDropDown'
import AllUserTable from './AllUserList'

import { onEditRole, onEditStatus, setOperators } from '../../../axiosLib/authActions'
import { addNewUserCounter, removeUserCounter } from '../../../axiosLib/userCounterAction'

import { connect } from 'react-redux'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../../../axiosLib/config'

let socket = socketIOClient(serveruri)

const EditRole = props => {
  let {
    onEditRole,
    onEditStatus,
    addNewUserCounter,
    user,
    counter,
    userCounter,
    removeUserCounter,
    branch
  } = props

  let activeUser = user ? user.user ? user.user.id ? user.user.id : '' : '' : ''
  let operators = user ? user.operators ? user.operators : [] : []
  let allUsers = user ? user.allUsers ? user.allUsers : [] : []
  let filteredUsers = allUsers.length ? allUsers.filter(user => user.id !== activeUser) : []
  let counterList = counter ? counter.counterList ? counter.counterList : [] : []
  let userCounterList = userCounter ?
    userCounter.allUserCounter ? userCounter.allUserCounter : [] : []
  let branchList = branch ? branch.allBranches ? branch.allBranches : [] : []

  let userCounterTable = counterList.length ? userCounterList.length ?
    counterList.map(counter => {
      let filteredList = userCounterList.filter(user => user.counterId === counter._id)
      let len = filteredList.length
      let users = []
      for (let i = 0; i < len; i++) {
        let user = operators.find(operator => operator.id === filteredList[i].userId)
        if (user) users.push(user)
      }
      let retData = {
        counterId: counter._id,
        counterTitle: counter.counterTitle,
        branchId: counter.branchId,
        users: users
      }

      return retData
    }) : [] : []

  let [userId, setId] = React.useState('')
  let [operator, setOperator] = React.useState('')
  let [counterId, setCounter] = React.useState('')
  let [status, setStatus] = React.useState('')
  let [role, setRole] = React.useState(undefined)
  let [branchId, setBranch] = React.useState('')

  let handleId = event => {
    setId(event.target.value)
  }
  let handleRole = value => {
    setRole(value)
  }
  let handleStatus = value => {
    setStatus(value)
  }
  let handleOperator = event => {
    setOperator(event.target.value)
  }
  let handleCounter = value => {
    setCounter(value)
  }
  let handleBranch = value => {
    setBranch(value)
  }

  let onSubmit = () => {
    let sendData = {
      userName: userId,
      status: status,
      role: role,
      branchId: branchId
    }
    onEditStatus(sendData)
    onEditRole(sendData)
      .then(res => {
        socket.emit('addOperator', sendData)
        socket.emit('notify', 'User Role Updated')
        setId('')
        setRole('')
      })
    console.log('SendData', sendData)
  }

  let onCounterSubmit = () => {
    let sendData = {
      userId: operator,
      counterId: counterId
    }
    addNewUserCounter(sendData)
      .then(res => {
        socket.emit('notify', 'New User assigned to Counter')
      })
  }

  // console.log('UserCounterTable', userCounterTable)

  return (
    <>
      <Row>
        <Col lg='6' className='mb-4'>
          <Card small className='mb-4'>
            <CardHeader className='border-bottom'>
              <h6 className='m-0'>Update Role and Status</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className='p-3'>
                <Row>
                  <Col>
                    <Form>
                      <strong className='text-muted d-block mb-2'>
                        Select User
                      </strong>
                      <InputGroup className='mb-3'>
                        <InputGroupAddon type='prepend'>
                          <InputGroupText>Users</InputGroupText>
                        </InputGroupAddon>
                        <FormSelect onChange={handleId}>
                          <option>Select</option>
                          {filteredUsers.length ? filteredUsers.map((user, key) => (
                            <option
                              key={key}
                              value={user.userName}
                            >
                              {user.name + ' (' + user.userName + ')'}
                            </option>
                          )) : null}
                        </FormSelect>
                      </InputGroup>
                      <label>Role</label>
                      <RoleList handleRoleChange={handleRole} />
                      <strong className='text-muted d-block mb-2'>
                        Select Status
                      </strong>
                      <StatusDropDown handleStatusChange={handleStatus} />
                      <strong className='text-muted d-block mb-2'>
                        Select Branch
                      </strong>
                      <BranchDropDown handleChange={handleBranch} />
                      <Button onClick={onSubmit}>Update</Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
        <Col lg='6' className='mb-4'>
          <Card small className='mb-4'>
            <CardHeader className='border-bottom'>
              <h6 className='m-0'>Insert To counter</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className='p-3'>
                <Row>
                  <Col>
                    <Form>
                      <strong className='text-muted d-block mb-2'>
                        Select User
                      </strong>
                      <InputGroup className='mb-3'>
                        <InputGroupAddon type='prepend'>
                          <InputGroupText>Users</InputGroupText>
                        </InputGroupAddon>
                        <FormSelect onChange={handleOperator}>
                          <option>Select</option>
                          {operators.length ? operators.map((user, key) => (
                            <option
                              key={key}
                              value={user.id}
                            >
                              {user.name}
                            </option>
                          )) : null}
                        </FormSelect>
                      </InputGroup>
                      <strong className='text-muted d-block mb-2'>
                        Select Counter
                      </strong>
                      <UserCounterDropDown
                        users={operators}
                        handleCounterChange={handleCounter}
                        operator={operator}
                        counterList={counterList}
                      />
                      <Button onClick={onCounterSubmit}>Submit</Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Row>
        <AllUserTable userList={allUsers} />
        <UserCounterTable
          removeUser={removeUserCounter}
          userCounter={userCounterTable}
          branchList={branchList}
        />
      </Row>
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : null,
    counter: state.counter ? state.counter : null,
    userCounter: state.userCounter ? state.userCounter : null,
    branch: state.branch ? state.branch : null
  }
}

export default connect(
  mapStateToProps,
  { onEditRole, onEditStatus, addNewUserCounter, removeUserCounter }
)(EditRole)
