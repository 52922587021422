import React from 'react'
import Auth from './Auth/Auth'
import CountersMain from './TrelloBoard/CountersMain'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { onLogout, handleActiveTab } from '../axiosLib/authActions'

import './App.css'

let userToken = sessionStorage.getItem('userToken')
let userRole = sessionStorage.getItem('userRole')

const Counter = props => {
  let { user, onLogout, tokenCounter } = props
  let loading = tokenCounter ? tokenCounter.loading ? tokenCounter.loading : false : false
  let isLoggedIn = user ? user.token : userToken ? userToken : null
  let role = user ? user.role : userRole ? userRole : ''
  let activeTab = user ? user.activeTab ? parseInt(user.activeTab) : 0 : 0

  return (
    <>
      {isLoggedIn ? role.length ? (
        role == 'operator' ? (
          <Redirect to='/counter' />
        ) : (
            <CountersMain
              onLogout={onLogout}
              user={user.user} activeTab={activeTab}
              handleActiveTab={handleActiveTab}
              loading={loading}
            />
          )
      ) : null : (
          <Auth />
        )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : null,
    tokenCounter: state.tokenCounter ? state.tokenCounter : null
  }
}

export default connect(mapStateToProps, { onLogout })(Counter)
