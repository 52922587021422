import {
  SET_USER,
  REMOVE_USER,
  SET_ROLE,
  SET_TOKEN,
  NEW_USER,
  REMOVE_ROLE,
  REMOVE_TOKEN,
  SET_SUCCESS_MSG,
  SET_ERR_MSG,
  SET_PASSWORD_MSG,
  SET_ALL_OPERATORS,
  SET_ADMIN_TAB,
  SET_ALL_USERS
} from '../constants'

let initialState = {}

let authReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_USER:
      return {
        ...state,
        newUser: action.data
      }
    case SET_USER:
      return {
        ...state,
        user: action.data
      }
    case SET_TOKEN:
      return {
        ...state,
        token: action.data
      }
    case REMOVE_TOKEN:
      return {
        ...state,
        token: ''
      }
    case SET_ROLE:
      return {
        ...state,
        role: action.data
      }
    case REMOVE_ROLE:
      return {
        ...state,
        role: ''
      }
    case SET_SUCCESS_MSG:
      return {
        ...state,
        success: action.data
      }
    case SET_ERR_MSG:
      return {
        ...state,
        error: action.data
      }
    case SET_PASSWORD_MSG:
      return {
        ...state,
        passwordSuccess: action.data
      }
    case REMOVE_USER:
      return {
        ...state,
        user: {}
      }
    case SET_ALL_OPERATORS:
      return {
        ...state,
        operators: action.data
      }
    case SET_ADMIN_TAB:
      return {
        ...state,
        activeTab: action.data
      }
    case SET_ALL_USERS:
      return {
        ...state,
        allUsers: action.data
      }

    default:
      return state
  }
}

export default authReducer