import {
  SET_ALL_SERVICE_COUNTERS,
  SET_SERVICE_COUNTER_ERROR
} from '../constants'

let initialState = {}

let serviceCounterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_SERVICE_COUNTERS:
      return {
        ...state,
        serviceCounters: action.data
      }
    case SET_SERVICE_COUNTER_ERROR:
      return {
        ...state,
        errors: action.data
      }

    default:
      return state
  }
}

export default serviceCounterReducer