import React, { useEffect } from 'react'
import '../index.css'

import TokenMain from '../components/TokenMain'
import MainSignUp from '../components/MainSignUp'
import Counter from '../components/Counter'
import Board from '../components/Board'
import Rating from '../components/Feedback/RatingMain'
import RemotePage from '../components/Remote/RemotePage'
import ForgetPass from '../components/Auth/ForgetPass'
import Monitor from "../components/Monitor/Monitor";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../components/shards-dashboard/styles/shards-dashboards.1.1.0.min.css'

import { getAllOperators, getAllUsers } from '../axiosLib/authActions'
import { getAllServices } from '../axiosLib/serviceActions'
import { getAllCounters } from '../axiosLib/counterActions'
import {
  getAllTokenCounters,
  getParked,
  fetchDoneList
} from '../axiosLib/tokenCounterActions'
import { getTimeList, getServiceTimes } from '../axiosLib/tokenActions'
import { getAllServiceCounters } from '../axiosLib/serviceCounterActions'
import { getAllUserCounter } from '../axiosLib/userCounterAction'
import { getAllBranches } from '../axiosLib/branchActions'

import { connect } from 'react-redux'

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import socketIOClient from 'socket.io-client'
import { serveruri } from '../axiosLib/config'

let socket = socketIOClient(serveruri)

const userToken = sessionStorage.getItem('userToken')
const userRole = sessionStorage.getItem('userRole')

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#62BC74'
    }
  }
})

let RouterMain = props => {
  let {
    user,
    getAllCounters,
    getAllServices,
    getAllTokenCounters,
    getParked,
    fetchDoneList,
    getAllServiceCounters,
    getAllUserCounter,
    getAllOperators,
    getTimeList,
    getServiceTimes,
    getAllBranches,
    getAllUsers
  } = props

  useEffect(() => {
    getAllTokenCounters(true)
    getAllBranches()
    getAllOperators()
    getAllUsers()
    getAllCounters()
    getTimeList()
    getServiceTimes()
    getAllServices()
    getAllServiceCounters()
    getParked()
    fetchDoneList()
    getAllUserCounter()
    socket.on('onSignup', data => {
      getAllUsers()
    })
    socket.on('addOperator', data => {
      getAllOperators()
    })
    socket.on('reQueue', data => {
      if (userToken !== data || data === '') {
        getAllTokenCounters(false)
      } else {
        getAllTokenCounters(true)
      }
    })
    socket.on('transferCounter', data => {
      if (data !== userToken) {
        getAllTokenCounters(false)
      } else {
        getAllTokenCounters(false)
      }
    })
    socket.on('parkedToken', data => {
      getParked()
        .then(res => {
          if (userToken !== data) {
            getAllTokenCounters(false)
          } else {
            getAllTokenCounters(true)
          }
        })
    })
    socket.on('doneList', data => {
      fetchDoneList()
      if (userToken !== data) {
        getAllTokenCounters(false)
      } else {
        getAllTokenCounters(true)
      }
    })
  }, [])

  let isLoggedIn = user ? user.token : userToken ? userToken : null
  let role = user ? user.role : userRole ? userRole : ''

  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route exact path='/' component={Counter} />
          <Route path='/monitor' component={Monitor} />
          <Route path='/feedback' component={Rating} />
          <Route path='/remote' component={RemotePage} />
          <Route path='/token' component={TokenMain} />
          <Route path='/signup' component={MainSignUp} />
          <Route path='/forget-password' component={ForgetPass} />
          {isLoggedIn ? role === 'operator' ? (
            <Route path='/counter' component={Board} />
          ) : <Redirect to='/' /> : <Redirect to='/' />}
        </Switch>
      </MuiThemeProvider>
    </Router>
  )
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : null
  }
}

export default connect(
  mapStateToProps,
  {
    getAllCounters,
    getAllServices,
    getAllTokenCounters,
    getParked,
    fetchDoneList,
    getAllServiceCounters,
    getAllUserCounter,
    getAllOperators,
    getTimeList,
    getServiceTimes,
    getAllBranches,
    getAllUsers
  }
)(RouterMain)
