import {
  ADD_USER_COUNTER,
  REMOVE_USER_COUNTER,
  GET_ALL_USER_COUNTER,
  SET_USER_COUNTER_ERROR
} from '../constants'

import axios from 'axios'

import { serveruri } from './config'

export const addNewUserCounter = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let addNew = await axios.post(`${serveruri}/api/add-user-counter`, inputData)

      if (addNew.status === 200) {
        response.status = 200
        response.token = token
        response.data = addNew.data
        dispatch(setNewUserCounter(addNew.data))

        let all = await axios.get(`${serveruri}/api/get-all-user-counter`)

        if (all.status === 200) {
          dispatch(setAllUserCounter(all.data))
        }

        response.message = 'Counter assigned to user successfully'
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setUserCounterError(err.response.data.msg))
        return
      }
    }
  }
}

export const removeUserCounter = (inputData) => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let remove = await axios.post(`${serveruri}/api/remove-user-counter`, inputData)

      if (remove.status === 200) {
        response.status = 200
        response.token = token
        response.data = remove.data
        dispatch(setRemovedUser(remove.data))

        let all = await axios.get(`${serveruri}/api/get-all-user-counter`)

        if (all.status === 200) {
          dispatch(setAllUserCounter(all.data))
        }
        response.message = 'Counter removed from user successfully'
      }
      return response
    } catch (err) {
      if (err.response) {
        dispatch(setUserCounterError(err.response.data.msg))
        return
      }
    }
  }
}

export const getAllUserCounter = () => {
  let token = sessionStorage.getItem('userToken')
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      if (token) {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let all = await axios.get(`${serveruri}/api/get-all-user-counter`)

        if (all.status === 200) {
          response.status = 200
          response.token = token
          response.data = all.data
          dispatch(setAllUserCounter(all.data))
          response.message = 'User Counter fetched successfully'
        }
      } else {
        dispatch(setAllUserCounter([]))
      }

      return response
    } catch (err) {
      if (err.response) {
        dispatch(setUserCounterError(err.response.data.msg))
        return
      }
    }
  }
}

export const setNewUserCounter = data => {
  return {
    type: ADD_USER_COUNTER,
    data: data
  }
}

export const setRemovedUser = data => {
  return {
    type: REMOVE_USER_COUNTER,
    data: data
  }
}

export const setAllUserCounter = data => {
  return {
    type: GET_ALL_USER_COUNTER,
    data: data
  }
}

export const setUserCounterError = data => {
  return {
    type: SET_USER_COUNTER_ERROR,
    data: data
  }
}